import { AudioManager } from './audio';
import { Canvas } from './canvas';
import { vec2 } from './types';
export enum KEYS {
  SpaceBar,
  Left,
  Right,
  Up,
  Down,
  Key_W,
  Key_A,
  Key_S,
  Key_D,
  Ctrl,
  Alt,
  Shift,
  Enter,
  Backspace,
  Escape,
  Unknown,
}

export class InputManager {
  public static keyStates: boolean[] = [];
  public static lastKey = '';
  public static mouseX = 0;
  public static mouseY = 0;
  public static oldMouseX = 0;
  public static oldMouseY = 0;
  public static _movementX = 0;
  public static _movementY = 0;
  public static mouseDownX = 0;
  public static mouseDownY = 0;
  public static mouseButtons: boolean[] = [];
  public static preventDefaults = false;
  public static isTouch = false;

  static get MovementX() {
    const t = InputManager._movementX;
    InputManager._movementX = 0;
    return t;
  }

  static get MovementY() {
    const t = InputManager._movementY;
    InputManager._movementY = 0;
    return t;
  }

  public static lookupKey(inKey: string): number {
    switch (inKey) {
      case 'ArrowDown':
        return KEYS.Down;
      case 'ArrowUp':
        return KEYS.Up;
      case 'ArrowLeft':
        return KEYS.Left;
      case 'ArrowRight':
        return KEYS.Right;

      case ' ':
        return KEYS.SpaceBar;
      case 'Spacebar':
        return KEYS.SpaceBar;

      case 'Enter':
        return KEYS.Enter;
      case 'Shift':
        return KEYS.Shift;
      case 'Control':
        return KEYS.Ctrl;
      case 'Alt':
        return KEYS.Alt;
      case 'Backspace':
        return KEYS.Backspace;
      case 'Escape':
        return KEYS.Escape;

      case 'W':
        return KEYS.Key_W;
      case 'w':
        return KEYS.Key_W;

      case 'A':
        return KEYS.Key_A;
      case 'a':
        return KEYS.Key_A;

      case 'S':
        return KEYS.Key_S;
      case 's':
        return KEYS.Key_S;

      case 'D':
        return KEYS.Key_D;
      case 'd':
        return KEYS.Key_D;
      default:
        return KEYS.Unknown;
    }
  }

  public static getCoordsInCanvasSpace(c: Canvas): vec2 {
    const result = new vec2(0, 0);
    // internally, mouseX/Y are stored as in true pixels.
    result.x = InputManager.mouseX * c.scaleX;
    result.y = InputManager.mouseY * c.scaleY;
    return result;
  }

  public static getDownCoordsInCanvasSpace(c: Canvas): vec2 {
    const result = new vec2(0, 0);
    // internally, mouseX/Y are stored as in true pixels.
    result.x = InputManager.mouseDownX * c.scaleX;
    result.y = InputManager.mouseDownY * c.scaleY;
    return result;
  }

  public static initialize(pvtDef: boolean) {
    this.preventDefaults = pvtDef;
    this.isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    document.addEventListener(
      'keydown',
      (event: KeyboardEvent) => {
        AudioManager.resume();
        this.keyStates[InputManager.lookupKey(event.key)] = true;
        this.lastKey = event.key;
        if (this.preventDefaults) {
          event.preventDefault();
        }
        return false;
      },
      false
    );
    document.addEventListener(
      'keyup',
      (event: KeyboardEvent) => {
        this.keyStates[InputManager.lookupKey(event.key)] = false;
        if (this.preventDefaults) {
          event.preventDefault();
        }
        return false;
      },
      false
    );
    document.addEventListener(
      'mousemove',
      (event: MouseEvent) => {
        this.mouseX = event.clientX * window.devicePixelRatio;
        this.mouseY = event.clientY * window.devicePixelRatio;
        this._movementX = event.movementX;
        this._movementY = event.movementY;
      },
      false
    );
    document.addEventListener(
      'mousedown',
      (event: MouseEvent) => {
        AudioManager.resume();
        this.mouseDownX = event.clientX * window.devicePixelRatio;
        this.mouseDownY = event.clientY * window.devicePixelRatio;
        this.mouseX = event.clientX * window.devicePixelRatio;
        this.mouseY = event.clientY * window.devicePixelRatio;
        this.mouseButtons[event.button] = true;
      },
      false
    );
    document.addEventListener(
      'mouseup',
      (event: MouseEvent) => {
        this.mouseButtons[event.button] = false;
      },
      false
    );
    document.addEventListener(
      'touchstart',
      (event: TouchEvent) => {
        AudioManager.resume();
        this.mouseDownX = event.touches[0].clientX * window.devicePixelRatio;
        this.mouseDownY = event.touches[0].clientY * window.devicePixelRatio;
        this.mouseX = event.touches[0].clientX * window.devicePixelRatio;
        this.mouseY = event.touches[0].clientY * window.devicePixelRatio;
        this.oldMouseX = this.mouseX;
        this.oldMouseY = this.mouseY;
        this.mouseButtons[0] = true;
        if (this.preventDefaults) {
          event.preventDefault();
        }
        return false;
      },
      false
    );
    document.addEventListener(
      'touchend',
      (event: TouchEvent) => {
        this.mouseButtons[0] = false;
        if (this.preventDefaults) {
          event.preventDefault();
        }
        return false;
      },
      false
    );
    document.addEventListener(
      'touchmove',
      (event: TouchEvent) => {
        this.mouseX = event.touches[0].clientX * window.devicePixelRatio;
        this.mouseY = event.touches[0].clientY * window.devicePixelRatio;
        this._movementX = (this.mouseX - this.oldMouseX) * 10;
        this._movementY = (this.mouseY - this.oldMouseY) * 10;
        this.oldMouseX = this.mouseX;
        this.oldMouseY = this.mouseY;
        if (this.preventDefaults) {
          event.preventDefault();
        }
        return false;
      },
      false
    );
  }
}
