import '../util/number.extensions';
import { MemoryPool, VARTYPE, Variable } from './mallocator';

export enum CANVAS_SIZE {
  FIXED,
  MAXIMIZE,
}

export enum CANVAS_TYPE {
  TWOD,
  THREED,
}

export enum ORIENTATION {
  LANDSCAPE,
  PORTRAIT,
}

export type RenderFunc = (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, c: Canvas) => void;
export type ResizeFunc = () => void;

export class Canvas extends HTMLElement {
  public _canvas!: HTMLCanvasElement;
  public _ctx?: CanvasRenderingContext2D | WebGLRenderingContext;
  private _renderFunc?: RenderFunc;
  private _resizeFunc?: ResizeFunc;
  private _frameStart = 0;
  private _frameEnd = 0;
  private _frameTime = 0;
  private container?: HTMLElement;

  public pointerLocked = false;
  public orientation!: ORIENTATION;

  private imageData?: ImageData;
  public data?: Uint8ClampedArray;
  private buf8?: Uint8ClampedArray;
  public data32?: Uint32Array;
  public pixelData?: Uint32Array;

  public canvasVar!: Variable;
  public memory!: MemoryPool;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private wasmFuncs?: any; //WebAssembly.Exports;

  public width!: number;
  public height!: number;
  public aspect!: number;
  public sizeMode!: CANVAS_SIZE;
  public ctxMode!: CANVAS_TYPE;
  public animated!: boolean;
  public showStats!: boolean;
  public swoptimized!: boolean;
  //public scale!: number;
  public scaleX!: number;
  public scaleY!: number;

  public pointerLockRequested = false;

  // We support both a sharable/multi-thread safe canvas buffer (good for when you need to
  // directly write to the canvas buffer from web-workers/wasm) OR a single-threaded mode
  // which is good when the rendering is handled elsewhere, and you need a more efficient direct
  // transfer to the canvas buffer without needing a data-array copy/set for the clamped uint8 data.
  public useMTBuffer = true;

  private _shadow!: ShadowRoot;

  constructor() {
    super();
    this._shadow = this.attachShadow({ mode: 'open' });
  }

  connectedCallback(): void {
    this.animated = this.getAttribute('animated')! === 'true' ? true : false;
    this.showStats = this.getAttribute('showstats')! === 'true' ? true : false;
    this.swoptimized = this.getAttribute('swoptimized')! === 'true' ? true : false;
    this.sizeMode = this.getAttribute('sizing')! === 'maximize' ? CANVAS_SIZE.MAXIMIZE : CANVAS_SIZE.FIXED;
    this.ctxMode = this.getAttribute('mode')! === '2d' ? CANVAS_TYPE.TWOD : CANVAS_TYPE.THREED;

    this._canvas = document.createElement('canvas') as HTMLCanvasElement;

    if (this.getAttribute('cursor') === 'false') {
      this.style.cursor = 'none';
      this._canvas.style.cursor = 'none';
    }

    this.style.display = 'inline-flex';
    this.style.overflow = 'hidden';

    this._canvas.width = this.width;
    this._canvas.height = this.height;
    this._canvas.style.width = +this.getAttribute('width')! + 'px';
    this._canvas.style.height = +this.getAttribute('height')! + 'px';
    this._canvas.style.padding = '0';
    this._canvas.style.margin = '0';
    this._canvas.style.border = '0';
    this._canvas.style.display = 'inline-block';

    if (this._canvas) {
      if (this.ctxMode === CANVAS_TYPE.TWOD) {
        if (this.swoptimized) {
          this._ctx = this._canvas.getContext('2d', {
            //alpha: false,
            willReadFrequently: true,
            desynchronized: true,
            antialias: true,
            premultipliedAlpha: true,
          }) as CanvasRenderingContext2D;
        } else {
          this._ctx = this._canvas.getContext('2d', {
            //alpha: false,
            desynchronized: true,
            antialias: true,
            premultipliedAlpha: true,
          }) as CanvasRenderingContext2D;
        }

        if (this.width > 0 && this.height > 0) {
          this.imageData = (<CanvasRenderingContext2D>this._ctx).getImageData(0, 0, this.width, this.height);
          this.data = this.imageData.data;
        }
      } else {
        this._ctx = this._canvas.getContext('webgl') as WebGLRenderingContext;
      }
    } else {
      throw new Error('Unable to create a new Canvas element!');
    }
    this.attach(this.parentElement!);
  }

  setMemory(memory: MemoryPool): void {
    this.memory = memory;
    this.canvasVar = this.memory.addVariable('CanvasData', VARTYPE.UINT32, 1920 * 1080);

    const importObject = {
      imports: {
        mem: this.memory.memory,
      },
    };

    WebAssembly.instantiateStreaming(fetch('wasm/canvas.wasm'), importObject).then((obj) => {
      this.wasmFuncs = obj.instance.exports;
    });

    this.bufferResize();
  }

  baseRenderer(): void {
    if (this._ctx && this._ctx instanceof CanvasRenderingContext2D) {
      this._frameStart = performance.now();
      if (this._renderFunc) {
        this._renderFunc(this._ctx, this._canvas, this);
      }
    }
  }

  nextFrame(): void {
    this._frameEnd = performance.now();
    this._frameTime = this._frameEnd - this._frameStart;

    if (this.showStats && this._ctx) {
      if (this._ctx instanceof CanvasRenderingContext2D) {
        this._ctx.fillStyle = 'rgba(0,150,0,1.0)';
        this._ctx.font = 'bold 20px arial';
        this._ctx.fillText(
          `Frame Time: ${this._frameTime.toFixed(2)} (${this.width},${this.height}) ${window.devicePixelRatio} - ${window.innerWidth}`,
          10,
          20
        );
      }
    }
    requestAnimationFrame(() => {
      this.baseRenderer();
    });
  }

  public async GetPointerLock(): Promise<void> {
    try {
      this.pointerLockRequested = true;
      await this._canvas.requestPointerLock();
    } catch (error) {
      console.log(error);
    }
  }

  attach(htmlContainer: HTMLElement): void {
    if (this._canvas) {
      this.container = htmlContainer;

      document.addEventListener(
        'pointerlockchange',
        () => {
          this.pointerLockRequested = false;
          if (document.pointerLockElement !== null) {
            this.pointerLocked = true;
          } else {
            this.pointerLocked = false;
            if (this.getAttribute('cursor') === 'false') {
              this.style.cursor = 'none';
              this._canvas.style.cursor = 'none';
            }
          }
        },
        false
      );

      this._shadow.appendChild(this._canvas);

      if (this.width > 0 && this.height > 0) {
        this.imageData = (<CanvasRenderingContext2D>this._ctx).getImageData(0, 0, this.width, this.height);
        this.data = this.imageData.data;
      }

      if (this.sizeMode === CANVAS_SIZE.MAXIMIZE) {
        this.calculateDimensions(htmlContainer);
      }

      if (this.sizeMode !== CANVAS_SIZE.FIXED) {
        this._canvas.addEventListener('fullscreenchange', () => {
          this.calculateDimensions(this.container!);
          this.bufferResize();
          if (this._resizeFunc) {
            this._resizeFunc();
          }
        });
        window.addEventListener('resize', () => {
          this.calculateDimensions(this.container!);
          this.bufferResize();
          if (this._resizeFunc) {
            this._resizeFunc();
          }
          /* window.setTimeout(() => {
            this.calculateDimensions(this.container!);
            this.bufferResize();
          }, 1000);*/
        });
        window.addEventListener('orientationchange', () => {
          this.calculateDimensions(this.container!);
          this.bufferResize();
          if (this._resizeFunc) {
            this._resizeFunc();
          }
        });
      } // end of != FIXED setup
    }
  }

  private calculateDimensions(htmlContainer: HTMLElement): void {
    let dpr = window.devicePixelRatio;
    if (dpr > 2) dpr = 2;

    const nativeW = htmlContainer.clientWidth * dpr;
    const nativeH = htmlContainer.clientHeight * dpr;
    const nativeAspect = nativeH / nativeW;
    let useW = 0;
    let useH = 0;
    if (dpr > 1 && (nativeW >= 1280 || nativeH >= 1000)) {
      useW = (nativeW / dpr) | 0;
      useH = (nativeH / dpr) | 0;
      useW = useW.roundUp(8);
      useH = useH.roundUp(8);
    } else {
      useW = nativeW.roundUp(8);
      useH = nativeH.roundUp(8);
    }

    // If the final resolution is still greater than fullHD, clamp it at fullHD.
    if (useW >= useH) {
      this.orientation = ORIENTATION.LANDSCAPE;
      if (useW > 1920) {
        useW = 1920;
        useH = Math.min(1080, (useW * nativeAspect) | 0);
      }
    } else if (useH > useW) {
      this.orientation = ORIENTATION.PORTRAIT;
      if (useH > 1080) {
        useH = 1080;
        useW = Math.min(1920, (useH / nativeAspect) | 0);
      }
    }

    this.scaleX = useW / (htmlContainer.clientWidth * window.devicePixelRatio);
    this.scaleY = useH / (htmlContainer.clientHeight * window.devicePixelRatio);

    this._canvas.width = useW;
    this._canvas.height = useH;
    // Set the canvas CSS style to fill the entire container (probably the screen)
    this._canvas.style.width = this.container!.clientWidth + 'px';
    this._canvas.style.height = this.container!.clientHeight + 'px';
    // Store our dimension.
    this.width = useW;
    this.height = useH;
    this.aspect = this.height / this.width;
  }

  public async openFullscreen(): Promise<void> {
    const elem = this._canvas;
    if (elem.requestFullscreen) {
      try {
        await elem.requestFullscreen();
      } catch (err) {
        //
      }
    }
  }

  public async lockOrientation(): Promise<void> {
    try {
      await screen.orientation.lock('landscape');
      this.calculateDimensions(this.container!);
    } catch (err) {
      //
    }
  }

  public updatePixels() {
    if (this.useMTBuffer) {
      if (this.data && this.buf8) {
        this.data!.set(this.buf8!);
        (<CanvasRenderingContext2D>this._ctx)!.putImageData(this.imageData!, 0, 0);
      }
    } else {
      if (this.data && this.pixelData) {
        (<CanvasRenderingContext2D>this._ctx)!.putImageData(this.imageData!, 0, 0);
      }
    }
  }

  clearBuffer() {
    if (this.wasmFuncs) {
      const cores = 1;
      const core = 0;
      const numPixels = this.width * this.height;
      const totalSize = numPixels.roundUp(cores * 8); // The total size in pixels of the buffer must be divisible by pixels_per_iteration * no_cores
      const size = totalSize / (cores * 8); // The size in pixels each core must process.
      const ofs = (totalSize / cores) * core * 4; // The offset in bytes = aligned_size / cores .. * this core no * 4;
      this.wasmFuncs.clear(size, ofs);
    }
  }

  private bufferResize(): void {
    if (this._ctx && this.width > 0 && this.height > 0) {
      this.imageData = (<CanvasRenderingContext2D>this._ctx).getImageData(0, 0, this.width, this.height);
      this.data = this.imageData.data;
      this.buf8 = new Uint8ClampedArray(this.memory.memory.buffer, this.canvasVar.ptr, this.data.length);
      this.data32 = new Uint32Array(this.memory.memory.buffer, this.canvasVar.ptr, this.data.length / 4); // The multthreaded accessible pixel data buffer
      this.pixelData = new Uint32Array(this.data.buffer, 0, this.data.length / 4); // The single threaded (but copy free) version
    }
  }

  synchw2sw(): void {
    if (this._ctx && this.width > 0 && this.height > 0) {
      this.imageData = (<CanvasRenderingContext2D>this._ctx).getImageData(0, 0, this.width, this.height);
      this.data = this.imageData.data;
      for (let i = 0; i < this.data.length; i++) {
        this.data32![i] = this.data[i];
      }
    }
  }

  setResize(fnc: ResizeFunc): void {
    this._resizeFunc = fnc;
  }

  setRenderer(fnc: RenderFunc): void {
    this._renderFunc = fnc;
    this.baseRenderer();
  }
}

export function registerEnjinCanvas(): void {
  try {
    customElements.define('enjin-canvas', Canvas);
  } catch (err) {
    console.log('Enjin-Canvas already registered');
  }
}
