import { vec2, vec4 } from './types';
import { VectorMath } from './math3d';
import { Material } from './material';
import { FACE_SIZE, GeometryBuffer } from './geometrybuffer';
import { CopyF32Elements } from '../util/number.extensions';
import { Light } from './light';

export class Face {
  public vertexIdx: number[] = [];
  public normalIdx: number[] = [];
  public uvIdx: number[] = [];
  public material?: Material;
  public normal: vec4;
  public geometryBuffer?: GeometryBuffer;
  public clipCode = 0;
  public auxCount = 0;

  constructor(vidx: number[], nidx: number[], uvidx: number[], vb: GeometryBuffer) {
    this.vertexIdx[0] = vidx[0];
    this.vertexIdx[1] = vidx[1];
    this.vertexIdx[2] = vidx[2];
    this.normalIdx[0] = nidx[0];
    this.normalIdx[1] = nidx[1];
    this.normalIdx[2] = nidx[2];
    this.uvIdx[0] = uvidx[0];
    this.uvIdx[1] = uvidx[1];
    this.uvIdx[2] = uvidx[2];
    this.normal = new vec4(0, 0, 0, 0);
    this.setGeometryBuffer(vb);
    this.makeNormal();
  }

  public setGeometryBuffer(vb: GeometryBuffer): void {
    this.geometryBuffer = vb;
  }

  public copy(): Face {
    const f = new Face(
      [this.vertexIdx[0], this.vertexIdx[1], this.vertexIdx[2]],
      [this.normalIdx[0], this.normalIdx[1], this.normalIdx[2]],
      [this.uvIdx[0], this.uvIdx[1], this.uvIdx[2]],
      this.geometryBuffer!
    );
    f.material = this.material; // NB: Material is still by reference.
    f.normal.copyFrom(this.normal);
    return f;
  }

  public copyFrom(f: Face): void {
    this.normal.copyFrom(f.normal);
    this.material = f.material;
    this.geometryBuffer = f.geometryBuffer;
    this.vertexIdx[0] = f.vertexIdx[0];
    this.vertexIdx[1] = f.vertexIdx[1];
    this.vertexIdx[2] = f.vertexIdx[2];
    this.normalIdx[0] = f.normalIdx[0];
    this.normalIdx[1] = f.normalIdx[1];
    this.normalIdx[2] = f.normalIdx[2];
    this.uvIdx[0] = f.uvIdx[0];
    this.uvIdx[1] = f.uvIdx[1];
    this.uvIdx[2] = f.uvIdx[2];
    this.clipCode = 0;
  }

  public makeNormal(): void {
    if (this.geometryBuffer) {
      const v1 = this.geometryBuffer.vertices[this.vertexIdx[0]];
      const v2 = this.geometryBuffer.vertices[this.vertexIdx[1]];
      const v3 = this.geometryBuffer.vertices[this.vertexIdx[2]];
      const edge1 = new vec4(v2.x - v1.x, v2.y - v1.y, v2.z - v1.z, 0);
      const edge2 = new vec4(v1.x - v3.x, v1.y - v3.y, v1.z - v3.z, 0);
      this.normal = VectorMath.crossProduct(edge1, edge2);
      this.normal.normalize();
    }
  }

  public static hsr2d(geoBuffer: GeometryBuffer, i: number): void {
    const faceDataI32 = geoBuffer.facesDataI32;
    const faceDataF32 = geoBuffer.facesDataF32;
    faceDataI32[i * FACE_SIZE + 12] = 1;
    const v1x = faceDataF32[i * FACE_SIZE + 60];
    const v1y = faceDataF32[i * FACE_SIZE + 61];
    const v2x = faceDataF32[i * FACE_SIZE + 62];
    const v2y = faceDataF32[i * FACE_SIZE + 63];
    const v3x = faceDataF32[i * FACE_SIZE + 64];
    const v3y = faceDataF32[i * FACE_SIZE + 65];
    //| x1-x0 y1-y0 |
    //| x2-x0 y2-y0 |
    const d1x = v3x - v1x;
    const d1y = v3y - v1y;
    const d2x = v3x - v2x;
    const d2y = v3y - v2y;
    if (d1x * d2y - d1y * d2x <= 0) {
      faceDataI32[i * FACE_SIZE + 12] = 0;
    }
  }

  public hsrObjectSpace(i: number, camPos: vec4): void {
    const faceDataI32 = this.geometryBuffer!.facesDataI32;
    const vertex = this.geometryBuffer!.vertices[this.vertexIdx[0]];
    const dp =
      this.normal.x * (camPos.x - vertex.x) +
      this.normal.y * (camPos.y - vertex.y) +
      this.normal.z * (camPos.z - vertex.z);
    if (dp <= 0) {
      faceDataI32[i * FACE_SIZE + 12] = 0;
    }
  }

  public static project(geoBuffer: GeometryBuffer, i: number, halfWidth: number, halfHeight: number): void {
    const faceDataF32 = geoBuffer.facesDataF32;
    const ofs = i * FACE_SIZE + 60;
    const ofs1 = ofs + 6;
    const ofs2 = ofs1 + 4;
    const ofs3 = ofs2 + 4;
    const invW1 = 1.0 / faceDataF32[ofs1 + 3];
    const invW2 = 1.0 / faceDataF32[ofs2 + 3];
    const invW3 = 1.0 / faceDataF32[ofs3 + 3];
    faceDataF32[ofs + 0] = faceDataF32[ofs1] * invW1 * halfWidth + halfWidth;
    faceDataF32[ofs + 1] = faceDataF32[ofs1 + 1] * invW1 * halfHeight + halfHeight;
    faceDataF32[ofs + 2] = faceDataF32[ofs2] * invW2 * halfWidth + halfWidth;
    faceDataF32[ofs + 3] = faceDataF32[ofs2 + 1] * invW2 * halfHeight + halfHeight;
    faceDataF32[ofs + 4] = faceDataF32[ofs3] * invW3 * halfWidth + halfWidth;
    faceDataF32[ofs + 5] = faceDataF32[ofs3 + 1] * invW3 * halfHeight + halfHeight;
  }

  public static projectRev(geoBuffer: GeometryBuffer, i: number, halfWidth: number, halfHeight: number): void {
    const faceDataF32 = geoBuffer.facesDataF32;
    const ofs = i * FACE_SIZE + 60;
    const ofs1 = ofs + 6;
    const ofs2 = ofs1 + 4;
    const ofs3 = ofs2 + 4;
    const invW1 = 1.0 / faceDataF32[ofs1 + 3];
    const invW2 = 1.0 / faceDataF32[ofs2 + 3];
    const invW3 = 1.0 / faceDataF32[ofs3 + 3];
    faceDataF32[ofs + 0] = faceDataF32[ofs1] * invW1 * halfWidth + halfWidth;
    faceDataF32[ofs + 1] = faceDataF32[ofs1 + 1] * invW1 * halfHeight + halfHeight;
    faceDataF32[ofs + 4] = faceDataF32[ofs2] * invW2 * halfWidth + halfWidth;
    faceDataF32[ofs + 5] = faceDataF32[ofs2 + 1] * invW2 * halfHeight + halfHeight;
    faceDataF32[ofs + 2] = faceDataF32[ofs3] * invW3 * halfWidth + halfWidth;
    faceDataF32[ofs + 3] = faceDataF32[ofs3 + 1] * invW3 * halfHeight + halfHeight;
  }

  static lightVec = new vec4(0, 0, 0, 0);

  public light(i: number, ambient: vec4, lights: Light[]): void {
    const gb = this.geometryBuffer!;
    const faceDataI32 = gb.facesDataI32;
    const faceDataF32 = gb.facesDataF32;
    if (faceDataI32[i * FACE_SIZE + 12] === 1) {
      let diffuseAR = 0;
      let diffuseAG = 0;
      let diffuseAB = 0;
      const diffuseR = faceDataF32[i * FACE_SIZE + 17];
      const diffuseG = faceDataF32[i * FACE_SIZE + 18];
      const diffuseB = faceDataF32[i * FACE_SIZE + 19];
      const diffuseA = this.material!.diffuseColor.asARGB() & 0xff000000;
      const vidx = this.vertexIdx[0];
      for (let i = 0; i < lights.length; i++) {
        // Get a light to face vertex vector in object space.
        Face.lightVec.x = lights[i].positionObjSpace.x - gb.vertices[vidx].x;
        Face.lightVec.y = lights[i].positionObjSpace.y - gb.vertices[vidx].y;
        Face.lightVec.z = lights[i].positionObjSpace.z - gb.vertices[vidx].z;
        Face.lightVec.normalize();
        // Calculate dot product between light and face normal.
        const dp = VectorMath.dotProduct(Face.lightVec, this.normal).clampf(0, 1);
        diffuseAR += dp * lights[i].color.x;
        diffuseAG += dp * lights[i].color.y;
        diffuseAB += dp * lights[i].color.z;
      }
      const finalR = ((diffuseR * (ambient.x + diffuseAR)).clampf(0, 1) * 255) | 0;
      const finalG = ((diffuseG * (ambient.y + diffuseAG)).clampf(0, 1) * 255) | 0;
      const finalB = ((diffuseB * (ambient.z + diffuseAB)).clampf(0, 1) * 255) | 0;
      faceDataI32[i * FACE_SIZE + 13] = diffuseA | (finalB << 16) | (finalG << 8) | finalR;
    }
  }

  public clipNear(faceIdx: number): void {
    //
    // Reset face as not clipped.
    this.clipCode = 0;
    this.auxCount = 0;
    //
    // Get triangle attribute indices.
    const vidx1 = this.vertexIdx[0] * 4;
    const vidx2 = this.vertexIdx[1] * 4;
    const vidx3 = this.vertexIdx[2] * 4;
    const nidx1 = this.normalIdx[0] * 4;
    const nidx2 = this.normalIdx[1] * 4;
    const nidx3 = this.normalIdx[2] * 4;
    const uvidx1 = this.uvIdx[0] * 2;
    const uvidx2 = this.uvIdx[1] * 2;
    const uvidx3 = this.uvIdx[2] * 2;
    //
    // Get native data buffer references.
    const vertexData = this.geometryBuffer!.vertextData;
    const uvData = this.geometryBuffer!.uvData;
    const normalData = this.geometryBuffer!.normalData;
    const faceDataF32 = this.geometryBuffer!.facesDataF32;
    faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
    //
    // Copy attributes to the triangle data structure (transformed vertices, normals and uvs)
    // We need these here for this point forward, regardless of clipping outcome.
    //faceDataF32[60->65] = 0; // 2d coordinates
    const faceAddr = this.geometryBuffer!.facesVar.ptr + faceIdx * (FACE_SIZE * 4) + 66 * 4;
    const v1addr = this.geometryBuffer!.verticestVar.ptr + vidx1 * 4;
    const v2addr = this.geometryBuffer!.verticestVar.ptr + vidx2 * 4;
    const v3addr = this.geometryBuffer!.verticestVar.ptr + vidx3 * 4;
    const n1addr = this.geometryBuffer!.normalsVar.ptr + nidx1 * 4;
    const n2addr = this.geometryBuffer!.normalsVar.ptr + nidx2 * 4;
    const n3addr = this.geometryBuffer!.normalsVar.ptr + nidx3 * 4;
    const uv1addr = this.geometryBuffer!.uvVar.ptr + uvidx1 * 4;
    const uv2addr = this.geometryBuffer!.uvVar.ptr + uvidx2 * 4;
    const uv3addr = this.geometryBuffer!.uvVar.ptr + uvidx3 * 4;
    GeometryBuffer.wasmFuncs.prepareTriangle(
      faceAddr,
      v1addr,
      v2addr,
      v3addr,
      n1addr,
      n2addr,
      n3addr,
      uv1addr,
      uv2addr,
      uv3addr
    );

    /*CopyF32Elements(vertexData, vidx1, faceDataF32, faceIdx * FACE_SIZE + 66, 4); // 3d-transformed-vertex1
    CopyF32Elements(vertexData, vidx2, faceDataF32, faceIdx * FACE_SIZE + 70, 4); // 3d-transformed-vertex2
    CopyF32Elements(vertexData, vidx3, faceDataF32, faceIdx * FACE_SIZE + 74, 4); // 3d-transformed-vertex3
    CopyF32Elements(normalData, nidx1, faceDataF32, faceIdx * FACE_SIZE + 78, 4); // normal1
    CopyF32Elements(normalData, nidx2, faceDataF32, faceIdx * FACE_SIZE + 82, 4); // normal2
    CopyF32Elements(normalData, nidx3, faceDataF32, faceIdx * FACE_SIZE + 86, 4); // normal3
    CopyF32Elements(uvData, uvidx1, faceDataF32, faceIdx * FACE_SIZE + 90, 2); // uv1
    CopyF32Elements(uvData, uvidx2, faceDataF32, faceIdx * FACE_SIZE + 92, 2); // uv2
    CopyF32Elements(uvData, uvidx3, faceDataF32, faceIdx * FACE_SIZE + 94, 2); // uv3
    */
    //
    // Determine which of the 3 vertices is clipped on the near plane.
    // This uses the homogenous clip space vertices, for whom the near plane is Z=0.
    const v1out = this.geometryBuffer!.vertextData[vidx1 + 2] <= 0 ? true : false;
    const v2out = this.geometryBuffer!.vertextData[vidx2 + 2] <= 0 ? true : false;
    const v3out = this.geometryBuffer!.vertextData[vidx3 + 2] <= 0 ? true : false;
    //
    // No clipping required.
    if (!v1out && !v2out && !v3out) {
      return;
    }
    //
    // All vertices out, trivial rejection.
    if (v1out && v2out && v3out) {
      this.clipCode = 3;
      faceDataF32[faceIdx * FACE_SIZE + 12] = 0;
      faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
      return;
    }
    //
    // Create new indexes to where the attributes are in the face data.
    const face_v1_idx = faceIdx * FACE_SIZE + 66;
    const face_v2_idx = faceIdx * FACE_SIZE + 70;
    const face_v3_idx = faceIdx * FACE_SIZE + 74;
    const face_n1_idx = faceIdx * FACE_SIZE + 78;
    const face_n2_idx = faceIdx * FACE_SIZE + 82;
    const face_n3_idx = faceIdx * FACE_SIZE + 86;
    const face_uv1_idx = faceIdx * FACE_SIZE + 90;
    const face_uv2_idx = faceIdx * FACE_SIZE + 92;
    const face_uv3_idx = faceIdx * FACE_SIZE + 94;
    //
    // There are two main cases:
    // 1. two vertices of the triangle are clipped, we just update the original triangle.
    if (v1out && v2out) {
      const t1 = -vertexData[vidx3 + 2] / (vertexData[vidx1 + 2] - vertexData[vidx3 + 2]);
      const t2 = -vertexData[vidx3 + 2] / (vertexData[vidx2 + 2] - vertexData[vidx3 + 2]);
      vec4.lerpNative(vertexData, faceDataF32, vidx3, vidx1, face_v1_idx, t1);
      vec4.lerpNative(vertexData, faceDataF32, vidx3, vidx2, face_v2_idx, t2);
      vec4.lerpNative(normalData, faceDataF32, nidx3, nidx1, face_n1_idx, t1);
      vec4.lerpNative(normalData, faceDataF32, nidx3, nidx2, face_n2_idx, t2);
      vec2.lerpNative(uvData, faceDataF32, uvidx3, uvidx1, face_uv1_idx, t1);
      vec2.lerpNative(uvData, faceDataF32, uvidx3, uvidx2, face_uv2_idx, t2);
      faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
      this.clipCode = 1;
      return;
    } else if (v2out && v3out) {
      const t1 = -vertexData[vidx1 + 2] / (vertexData[vidx2 + 2] - vertexData[vidx1 + 2]);
      const t2 = -vertexData[vidx1 + 2] / (vertexData[vidx3 + 2] - vertexData[vidx1 + 2]);
      vec4.lerpNative(vertexData, faceDataF32, vidx1, vidx2, face_v2_idx, t1);
      vec4.lerpNative(vertexData, faceDataF32, vidx1, vidx3, face_v3_idx, t2);
      vec4.lerpNative(normalData, faceDataF32, nidx1, nidx2, face_n2_idx, t1);
      vec4.lerpNative(normalData, faceDataF32, nidx1, nidx3, face_n3_idx, t2);
      vec2.lerpNative(uvData, faceDataF32, uvidx1, uvidx2, face_uv2_idx, t1);
      vec2.lerpNative(uvData, faceDataF32, uvidx1, uvidx3, face_uv3_idx, t2);
      faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
      this.clipCode = 1;
      return;
    } else if (v1out && v3out) {
      const t1 = -vertexData[vidx2 + 2] / (vertexData[vidx1 + 2] - vertexData[vidx2 + 2]);
      const t2 = -vertexData[vidx2 + 2] / (vertexData[vidx3 + 2] - vertexData[vidx2 + 2]);
      vec4.lerpNative(vertexData, faceDataF32, vidx2, vidx1, face_v1_idx, t1);
      vec4.lerpNative(vertexData, faceDataF32, vidx2, vidx3, face_v3_idx, t2);
      vec4.lerpNative(normalData, faceDataF32, nidx2, nidx1, face_n1_idx, t1);
      vec4.lerpNative(normalData, faceDataF32, nidx2, nidx3, face_n3_idx, t2);
      vec2.lerpNative(uvData, faceDataF32, uvidx2, uvidx1, face_uv1_idx, t1);
      vec2.lerpNative(uvData, faceDataF32, uvidx2, uvidx3, face_uv3_idx, t2);
      faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
      this.clipCode = 1;
      return;
    }
    //
    // We're going to need an extra face now, get it's element indices.
    const fidx = this.geometryBuffer!.faceCount + this.geometryBuffer!.auxCount;
    this.geometryBuffer!.copyFace(faceIdx, fidx);
    const nface_v1_idx = fidx * FACE_SIZE + 66;
    const nface_v2_idx = fidx * FACE_SIZE + 70;
    const nface_v3_idx = fidx * FACE_SIZE + 74;
    const nface_n1_idx = fidx * FACE_SIZE + 78;
    const nface_n2_idx = fidx * FACE_SIZE + 82;
    const nface_n3_idx = fidx * FACE_SIZE + 86;
    const nface_uv1_idx = fidx * FACE_SIZE + 90;
    const nface_uv2_idx = fidx * FACE_SIZE + 92;
    const nface_uv3_idx = fidx * FACE_SIZE + 94;
    CopyF32Elements(vertexData, vidx1, faceDataF32, nface_v1_idx, 4); // 3d-transformed-vertex1
    CopyF32Elements(vertexData, vidx2, faceDataF32, nface_v2_idx, 4); // 3d-transformed-vertex2
    CopyF32Elements(vertexData, vidx3, faceDataF32, nface_v3_idx, 4); // 3d-transformed-vertex3
    CopyF32Elements(normalData, nidx1, faceDataF32, nface_n1_idx, 4); // normal1
    CopyF32Elements(normalData, nidx2, faceDataF32, nface_n2_idx, 4); // normal2
    CopyF32Elements(normalData, nidx3, faceDataF32, nface_n3_idx, 4); // normal3
    CopyF32Elements(uvData, uvidx1, faceDataF32, nface_uv1_idx, 2); // uv1
    CopyF32Elements(uvData, uvidx2, faceDataF32, nface_uv2_idx, 2); // uv2
    CopyF32Elements(uvData, uvidx3, faceDataF32, nface_uv3_idx, 2); // uv3
    faceDataF32[fidx * FACE_SIZE + 13] = faceDataF32[faceIdx * FACE_SIZE + 13];

    //
    // 2. Only one of the vertices are out, resulting in a new triangle
    if (v1out) {
      const t1 = -vertexData[vidx2 + 2] / (vertexData[vidx1 + 2] - vertexData[vidx2 + 2]);
      const t2 = -vertexData[vidx3 + 2] / (vertexData[vidx1 + 2] - vertexData[vidx3 + 2]);
      vec4.lerpNative(vertexData, faceDataF32, vidx2, vidx1, face_v1_idx, t1);
      vec4.lerpNative(normalData, faceDataF32, nidx2, nidx1, face_n1_idx, t1);
      vec2.lerpNative(uvData, faceDataF32, uvidx2, uvidx1, face_uv1_idx, t1);
      vec4.lerpNative(faceDataF32, faceDataF32, nface_v3_idx, nface_v1_idx, nface_v1_idx, t2);
      vec4.lerpNative(faceDataF32, faceDataF32, nface_n3_idx, nface_n1_idx, nface_n1_idx, t2);
      vec2.lerpNative(faceDataF32, faceDataF32, nface_uv3_idx, nface_uv1_idx, nface_uv1_idx, t2);
      CopyF32Elements(faceDataF32, face_v1_idx, faceDataF32, nface_v2_idx, 4);
      CopyF32Elements(faceDataF32, face_n1_idx, faceDataF32, nface_n2_idx, 4);
      CopyF32Elements(faceDataF32, face_uv1_idx, faceDataF32, nface_uv2_idx, 2);
      this.clipCode = 2;
      faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
      this.geometryBuffer!.auxCount++;
      this.auxCount++;
    } else if (v2out) {
      const t1 = -vertexData[vidx1 + 2] / (vertexData[vidx2 + 2] - vertexData[vidx1 + 2]);
      const t2 = -vertexData[vidx3 + 2] / (vertexData[vidx2 + 2] - vertexData[vidx3 + 2]);
      vec4.lerpNative(vertexData, faceDataF32, vidx1, vidx2, face_v2_idx, t1);
      vec4.lerpNative(normalData, faceDataF32, nidx1, nidx2, face_n2_idx, t1);
      vec2.lerpNative(uvData, faceDataF32, uvidx1, uvidx2, face_uv2_idx, t1);
      vec4.lerpNative(faceDataF32, faceDataF32, nface_v3_idx, nface_v2_idx, nface_v2_idx, t2);
      vec4.lerpNative(faceDataF32, faceDataF32, nface_n3_idx, nface_n2_idx, nface_n2_idx, t2);
      vec2.lerpNative(faceDataF32, faceDataF32, nface_uv3_idx, nface_uv2_idx, nface_uv2_idx, t2);
      CopyF32Elements(faceDataF32, face_v2_idx, faceDataF32, nface_v1_idx, 4);
      CopyF32Elements(faceDataF32, face_n2_idx, faceDataF32, nface_n1_idx, 4);
      CopyF32Elements(faceDataF32, face_uv2_idx, faceDataF32, nface_uv1_idx, 2);
      this.clipCode = 2;
      faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
      this.geometryBuffer!.auxCount++;
      this.auxCount++;
    } else if (v3out) {
      const t1 = -vertexData[vidx1 + 2] / (vertexData[vidx3 + 2] - vertexData[vidx1 + 2]);
      const t2 = -vertexData[vidx2 + 2] / (vertexData[vidx3 + 2] - vertexData[vidx2 + 2]);
      vec4.lerpNative(vertexData, faceDataF32, vidx1, vidx3, face_v3_idx, t1);
      vec4.lerpNative(normalData, faceDataF32, nidx1, nidx3, face_n3_idx, t1);
      vec2.lerpNative(uvData, faceDataF32, uvidx1, uvidx3, face_uv3_idx, t1);
      vec4.lerpNative(faceDataF32, faceDataF32, nface_v2_idx, nface_v3_idx, nface_v3_idx, t2);
      vec4.lerpNative(faceDataF32, faceDataF32, nface_n2_idx, nface_n3_idx, nface_n3_idx, t2);
      vec2.lerpNative(faceDataF32, faceDataF32, nface_uv2_idx, nface_uv3_idx, nface_uv3_idx, t2);
      CopyF32Elements(faceDataF32, face_v3_idx, faceDataF32, nface_v1_idx, 4);
      CopyF32Elements(faceDataF32, face_n3_idx, faceDataF32, nface_n1_idx, 4);
      CopyF32Elements(faceDataF32, face_uv3_idx, faceDataF32, nface_uv1_idx, 2);
      this.clipCode = 2;
      faceDataF32[faceIdx * FACE_SIZE + 96] = this.clipCode;
      this.geometryBuffer!.auxCount++;
      this.auxCount++;
    }
  }
}
