import { MatrixMath } from './math3d';

export class vec2 {
  constructor(public x: number, public y: number) {}

  normalize(): void {
    let rLen = 1.0 / this.length();
    if (isNaN(rLen)) {
      rLen = 0;
    }
    this.x *= rLen;
    this.y *= rLen;
  }

  lengthSqr(): number {
    return this.x * this.x + this.y * this.y;
  }

  length(): number {
    return Math.sqrt(this.lengthSqr());
  }

  public copyFrom(v: vec2): void {
    this.x = v.x;
    this.y = v.y;
  }

  public set(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  public dotProduct(v: vec2): number {
    return this.x * v.x + this.y * v.y;
  }

  public scale(s: number): void {
    this.x *= s;
    this.y *= s;
  }

  public add(v: vec2): void {
    this.x += v.x;
    this.y += v.y;
  }

  public sub(v: vec2): void {
    this.x -= v.x;
    this.y -= v.y;
  }

  public div(s: number): void {
    if (s === 0) s = 0.0001;
    this.x /= s;
    this.y /= s;
  }

  public static copyFromNative(vData: Float32Array, vSrc: number, vDst: number): void {
    vData[vDst + 0] = vData[vSrc + 0];
    vData[vDst + 1] = vData[vSrc + 1];
  }

  public static lerp(vStart: vec2, vEnd: vec2, vDst: vec2, t: number): void {
    vDst.x = vStart.x + t * (vEnd.x - vStart.x);
    vDst.y = vStart.y + t * (vEnd.y - vStart.y);
  }

  public static lerpNative(
    vSrcArr: Float32Array,
    vDstArr: Float32Array,
    vStart: number,
    vEnd: number,
    vDst: number,
    t: number
  ): void {
    vDstArr[vDst + 0] = vSrcArr[vStart + 0] + t * (vSrcArr[vEnd + 0] - vSrcArr[vStart + 0]);
    vDstArr[vDst + 1] = vSrcArr[vStart + 1] + t * (vSrcArr[vEnd + 1] - vSrcArr[vStart + 1]);
  }
}

export class vec4 {
  constructor(public x: number, public y: number, public z: number, public w: number) {}

  normalize(): void {
    const rLen = 1.0 / Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    this.x *= rLen;
    this.y *= rLen;
    this.z *= rLen;
  }

  lengthSqr(): number {
    return this.x * this.x + this.y * this.y + this.z * this.z;
  }

  length(): number {
    return Math.sqrt(this.lengthSqr());
  }

  scale(s: number): void {
    this.x *= s;
    this.y *= s;
    this.z *= s;
  }

  scaleNew(s: number): vec4 {
    return new vec4(this.x * s, this.y * s, this.z * s, this.w);
  }

  set(x: number, y: number, z: number, w: number): void {
    this.x = x;
    this.y = y;
    this.z = z;
    this.w = w;
  }

  // Treat the vec4 as if it's an ARGB tuple and covert it back to UINT32.
  asARGB(): number {
    const r = (this.x * 255) | 0;
    const g = (this.y * 255) | 0;
    const b = (this.z * 255) | 0;
    const a = (this.w * 255) | 0;
    return (a << 24) | (b << 16) | (g << 8) | r;
  }

  public static sub(v1: vec4, v2: vec4): vec4 {
    return new vec4(v1.x - v2.x, v1.y - v2.y, v1.z - v2.z, v1.w - v2.w);
  }

  public static add(v1: vec4, v2: vec4): vec4 {
    return new vec4(v1.x + v2.x, v1.y + v2.y, v1.z + v2.z, v1.w + v2.w);
  }

  public copy(): vec4 {
    return new vec4(this.x, this.y, this.z, this.w);
  }

  public static lerp(vStart: vec4, vEnd: vec4, vDst: vec4, t: number): void {
    vDst.x = vStart.x + t * (vEnd.x - vStart.x);
    vDst.y = vStart.y + t * (vEnd.y - vStart.y);
    vDst.z = vStart.z + t * (vEnd.z - vStart.z);
    vDst.w = vStart.w + t * (vEnd.w - vStart.w);
  }

  public static lerpNative(
    vSrcArr: Float32Array,
    vDstArr: Float32Array,
    vStart: number,
    vEnd: number,
    vDst: number,
    t: number
  ): void {
    vDstArr[vDst + 0] = vSrcArr[vStart + 0] + t * (vSrcArr[vEnd + 0] - vSrcArr[vStart + 0]);
    vDstArr[vDst + 1] = vSrcArr[vStart + 1] + t * (vSrcArr[vEnd + 1] - vSrcArr[vStart + 1]);
    vDstArr[vDst + 2] = vSrcArr[vStart + 2] + t * (vSrcArr[vEnd + 2] - vSrcArr[vStart + 2]);
    vDstArr[vDst + 3] = vSrcArr[vStart + 3] + t * (vSrcArr[vEnd + 3] - vSrcArr[vStart + 3]);
  }

  public copyFrom(v: vec4): void {
    this.x = v.x;
    this.y = v.y;
    this.z = v.z;
    this.w = v.w;
  }

  public static copyFromNative(vData: Float32Array, vSrc: number, vDst: number): void {
    vData[vDst + 0] = vData[vSrc + 0];
    vData[vDst + 1] = vData[vSrc + 1];
    vData[vDst + 2] = vData[vSrc + 2];
    vData[vDst + 3] = vData[vSrc + 3];
  }
}

export class mat4 {
  public elements: number[] = [];

  constructor() {
    for (let i = 0; i < 16; i++) {
      this.elements[i] = 0.0;
    }
  }

  public static makeIdentity(): mat4 {
    const result = new mat4();
    result.elements[0] = 1.0;
    result.elements[5] = 1.0;
    result.elements[10] = 1.0;
    result.elements[15] = 1.0;
    return result;
  }

  public static makeTranslation(x: number, y: number, z: number): mat4 {
    const result = mat4.makeIdentity();
    result.elements[3] = x;
    result.elements[7] = y;
    result.elements[11] = z;
    return result;
  }

  public static makeRotationX(theta: number): mat4 {
    const result = mat4.makeIdentity();
    result.elements[5] = Math.cos(theta);
    result.elements[6] = -Math.sin(theta);
    result.elements[9] = Math.sin(theta);
    result.elements[10] = Math.cos(theta);
    return result;
  }

  public static makeRotationY(theta: number): mat4 {
    const result = mat4.makeIdentity();
    result.elements[0] = Math.cos(theta);
    result.elements[2] = Math.sin(theta);
    result.elements[8] = -Math.sin(theta);
    result.elements[10] = Math.cos(theta);
    return result;
  }

  public static makeRotationZ(theta: number): mat4 {
    const result = mat4.makeIdentity();
    result.elements[0] = Math.cos(theta);
    result.elements[1] = -Math.sin(theta);
    result.elements[4] = Math.sin(theta);
    result.elements[5] = Math.cos(theta);
    return result;
  }

  public static makeScale(x: number, y: number, z: number): mat4 {
    const result = mat4.makeIdentity();
    result.elements[0] = x;
    result.elements[5] = y;
    result.elements[10] = z;
    return result;
  }

  public static makeHermite(): mat4 {
    const result = new mat4();
    result.elements[0] = 2.0;
    result.elements[4] = -2.0;
    result.elements[8] = 1.0;
    result.elements[12] = 1.0;

    result.elements[1] = -3.0;
    result.elements[5] = 3.0;
    result.elements[9] = -2.0;
    result.elements[13] = -1.0;

    result.elements[2] = 0.0;
    result.elements[6] = 0.0;
    result.elements[10] = 1.0;
    result.elements[14] = 0.0;

    result.elements[3] = 1.0;
    result.elements[7] = 0.0;
    result.elements[11] = 0.0;
    result.elements[15] = 0.0;

    return result;
  }

  public static makePerspective(fov_horiz: number, fov_vert: number, near_plane: number, far_plane: number): mat4 {
    const result = new mat4();

    const w = 1 / Math.tan(fov_horiz * 0.5); // 1/tan(x) == cot(x)
    const h = 1 / Math.tan(fov_vert * 0.5); // 1/tan(x) == cot(x)
    const Q = far_plane / (far_plane - near_plane);

    result.elements[0] = w;
    result.elements[5] = h;
    result.elements[10] = Q;
    result.elements[14] = 1;
    result.elements[11] = -Q * near_plane;

    return result;
  }

  public static makeLookAt(fwd: vec4, up: vec4, right: vec4, position: vec4): mat4 {
    let result = mat4.makeIdentity();

    const translationMatrix = mat4.makeTranslation(position.x, position.y, position.z);
    const orientationMatrix = mat4.makeIdentity();
    orientationMatrix.elements[0] = right.x;
    orientationMatrix.elements[4] = right.y;
    orientationMatrix.elements[8] = right.z;

    orientationMatrix.elements[1] = up.x;
    orientationMatrix.elements[5] = up.y;
    orientationMatrix.elements[9] = up.z;

    orientationMatrix.elements[2] = fwd.x;
    orientationMatrix.elements[6] = fwd.y;
    orientationMatrix.elements[10] = fwd.z;

    result = MatrixMath.Multiply(orientationMatrix, translationMatrix);
    result = MatrixMath.Inverse(result);

    return result;
  }

  public static makeAxisAngle(axis: vec4, angle: number): mat4 {
    const result = mat4.makeIdentity();
    // Based on http://www.gamedev.net/reference/articles/article1199.asp
    const c = Math.cos(angle);
    const s = Math.sin(angle);
    const t = 1 - c;
    const x = axis.x;
    const y = axis.y;
    const z = axis.z;
    const tx = t * x;
    const ty = t * y;

    result.elements[0] = tx * x + c;
    result.elements[1] = tx * y - s * z;
    result.elements[2] = tx * z + s * y;
    result.elements[3] = 0;

    result.elements[4] = tx * y + s * z;
    result.elements[5] = ty * y + c;
    result.elements[6] = ty * z - s * x;
    result.elements[7] = 0;

    result.elements[8] = tx * z - s * y;
    result.elements[9] = ty * z + s * x;
    result.elements[10] = t * z * z + c;
    result.elements[11] = 0;

    result.elements[12] = 0;
    result.elements[13] = 0;
    result.elements[14] = 0;
    result.elements[15] = 1;

    return result;
  }

  public static makeBasis(xAxis: vec4, yAxis: vec4, zAxis: vec4): mat4 {
    const result = mat4.makeIdentity();
    result.elements[0] = xAxis.x;
    result.elements[1] = yAxis.x;
    result.elements[2] = zAxis.x;

    result.elements[4] = xAxis.y;
    result.elements[5] = yAxis.y;
    result.elements[6] = zAxis.y;

    result.elements[8] = xAxis.z;
    result.elements[9] = yAxis.z;
    result.elements[10] = zAxis.z;
    return result;
  }

  public static makeFromQuarternion(q: quarternion): mat4 {
    const result = mat4.makeIdentity();
    const te = result.elements;

    const x = q.x,
      y = q.y,
      z = q.z,
      w = q.w;
    const x2 = x + x,
      y2 = y + y,
      z2 = z + z;
    const xx = x * x2,
      xy = x * y2,
      xz = x * z2;
    const yy = y * y2,
      yz = y * z2,
      zz = z * z2;
    const wx = w * x2,
      wy = w * y2,
      wz = w * z2;

    te[0] = 1 - (yy + zz);
    te[4] = xy - wz;
    te[8] = xz + wy;

    te[1] = xy + wz;
    te[5] = 1 - (xx + zz);
    te[9] = yz - wx;

    te[2] = xz - wy;
    te[6] = yz + wx;
    te[10] = 1 - (xx + yy);

    // last column
    te[3] = 0;
    te[7] = 0;
    te[11] = 0;

    // bottom row
    te[12] = 0;
    te[13] = 0;
    te[14] = 0;
    te[15] = 1;

    return result;
  }
}

export class quarternion {
  constructor(public x: number, public y: number, public z: number, public w: number) {}

  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z + this.w * this.w);
  }

  normalize(): void {
    const l = this.length();
    this.x /= l;
    this.y /= l;
    this.z /= l;
    this.w /= l;
  }

  conjugate(): quarternion {
    return new quarternion(-this.x, -this.y, -this.z, 0);
  }

  public static fromAxisAngle(axis: vec4, angle: number): quarternion {
    // http://www.euclideanspace.com/maths/geometry/rotations/conversions/angleToQuaternion/index.htm
    // assumes axis is normalized
    const q = new quarternion(0, 0, 0, 0);
    const halfAngle = angle / 2;
    const s = Math.sin(halfAngle);
    q.x = axis.x * s;
    q.y = axis.y * s;
    q.z = axis.z * s;
    q.w = Math.cos(halfAngle);
    return q;
  }
}
