import { Canvas } from '../enjin/canvas';
import { EnjinImage } from '../enjin/image';
import { InputManager } from '../enjin/input';
import { vec2 } from '../enjin/types';

type ClickFunc = (n: GraphNode) => Promise<boolean>;

export class GraphNode {
  position: vec2 = new vec2(0, 0);
  positionT: vec2 = new vec2(0, 0);
  force: vec2 = new vec2(0, 0);
  mass = 1;
  dragging = false;
  screenRadius = 1;
  open = false;
  hover = false;
  clicked = false;
  time = 0;
  click?: ClickFunc;

  constructor(
    public text: string,
    public size: number,
    public locked: boolean,
    public depth: number,
    public visible: boolean,
    clickHandler?: ClickFunc
  ) {
    this.mass = (2 * Math.PI * size) / 1.5;
    if (clickHandler) {
      this.click = clickHandler;
    }
  }

  public inBounds(x: number, y: number): boolean {
    const dx = x - this.positionT!.x;
    const dy = y - this.positionT!.y;
    const d = Math.sqrt(dx * dx + dy * dy);
    if (d <= this.screenRadius) {
      return true;
    }
    return false;
  }

  public project(w: number, h: number): void {
    // Map graph space to screen space, on the basis that
    // [-1,-1] and [1,1] map to the top left and bottom right screen corners.
    const halfWidth = w / 2.0;
    const halfHeight = h / 2.0;
    //const s = Math.sqrt(halfWidth * halfWidth + halfHeight * halfHeight);
    this.positionT.set(this.position.x * halfWidth + w / 2, this.position.y * halfHeight + h / 2);
  }
}

export interface Edge {
  n1: GraphNode;
  n2: GraphNode;
}

export class NavControl {
  public nodes: GraphNode[] = [];
  public edges: Edge[] = [];
  public visible = true;
  public nodeImage!: EnjinImage;
  public nodeOpenImage!: EnjinImage;
  public nodeCloseImage!: EnjinImage;
  public state = 0;
  public gravityForce = 0.008;
  public forceConstantR = 0.0004;
  public forceConstantA = 0.003;
  public draggedId = -1;
  public rootFlash = true;

  public static async create(url: string[]): Promise<NavControl> {
    const n = new NavControl();
    n.nodeImage = await EnjinImage.fromURL(url[0]);
    n.nodeOpenImage = await EnjinImage.fromURL(url[1]);
    n.nodeCloseImage = await EnjinImage.fromURL(url[2]);
    return n;
  }

  public activate(): void {
    this.state = 1;
    for (let i = 1; i < this.nodes.length; i++) {
      this.nodes[i].position.x = Math.random() * 0.1 - 0.05;
      this.nodes[i].position.y = Math.random() * 0.1 - 0.05;
    }
  }

  public deactivate(): void {
    this.state = 0;
  }

  public getConnectedNodes(n: GraphNode): GraphNode[] {
    const nodes: GraphNode[] = [];
    for (let i = 0; i < this.edges.length; i++) {
      if (this.edges[i].n1 === n) {
        nodes.push(this.edges[i].n2);
      }
    }
    return nodes;
  }

  public getNodeChildren(n: GraphNode): GraphNode[] {
    const nodes: GraphNode[] = [];
    const cNodes = this.getConnectedNodes(n);
    for (let i = 0; i < cNodes.length; i++) {
      if (cNodes[i].depth > n.depth) {
        nodes.push(cNodes[i]);
      }
    }
    return nodes;
  }

  public expandNode(n: GraphNode): void {
    n.open = true;
    const children = this.getNodeChildren(n);
    for (let i = 0; i < children.length; i++) {
      children[i].visible = true;
    }
  }

  public collapseNode(n: GraphNode): void {
    n.open = false;
    const children = this.getNodeChildren(n);
    for (let i = 0; i < children.length; i++) {
      children[i].visible = false;
      this.collapseNode(children[i]);
    }
  }

  public addNode(n: GraphNode): GraphNode {
    this.nodes.push(n);
    return n;
  }

  public addEdge(e: Edge): void {
    this.edges.push(e);
  }

  public async update(w: number, h: number, dT: number, maxY: number, minY: number, c: Canvas): Promise<void> {
    const nodes = this.nodes;
    const edges = this.edges;
    //
    // Test for user control
    const coords = InputManager.getCoordsInCanvasSpace(c);
    const downCoords = InputManager.getDownCoordsInCanvasSpace(c);
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].time += dT;
      const result = nodes[i].inBounds(coords.x, coords.y);
      if (result) {
        nodes[i].hover = true;
      } else {
        nodes[i].hover = false;
      }
    }
    if (InputManager.mouseButtons[0] && this.draggedId === -1) {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].visible) {
          const result = nodes[i].inBounds(coords.x, coords.y);
          if (result) {
            nodes[i].dragging = true;
            this.draggedId = i;
            break;
          }
        }
      }
    } else if (!InputManager.mouseButtons[0]) {
      const dx = coords.x - downCoords.x;
      const dy = coords.y - downCoords.y;
      const d = Math.sqrt(dx * dx + dy * dy);
      if (d < 10 && this.draggedId !== -1) {
        let result;
        if (nodes[this.draggedId].click) {
          result = await nodes[this.draggedId].click!(nodes[this.draggedId]);
          if (result) {
            nodes[this.draggedId].clicked = true;
          }
        } else {
          result = true;
        }
        if (result) {
          if (nodes[this.draggedId].open) {
            this.collapseNode(nodes[this.draggedId]);
          } else if (!nodes[this.draggedId].open) {
            this.expandNode(nodes[this.draggedId]);
          }
        }
      }
      this.draggedId = -1;
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].dragging = false;
      }
    }
    if (this.draggedId > 0) {
      const halfWidth = w / 2.0;
      const halfHeight = h / 2.0;
      //      const s = Math.sqrt(halfWidth * halfWidth + halfHeight * halfHeight);
      const sx = (coords.x - w / 2) / halfWidth;
      const sy = (coords.y - h / 2) / halfHeight;
      nodes[this.draggedId].position.x = sx;
      nodes[this.draggedId].position.y = sy;
    }
    //
    // Apply a gravitation force to all nodes towards the root node.
    nodes[0].force.set(0, 0);
    for (let i = 1; i < nodes.length; i++) {
      nodes[i].force.set(nodes[i].position!.x - nodes[0].position!.x, nodes[i].position!.y - nodes[0].position!.y);
      nodes[i].force.normalize();
      nodes[i].force.scale(-this.gravityForce);
    }
    //
    // Apply repulsive forces to nodes.
    const dir = new vec2(0, 0);
    const force = new vec2(0, 0);
    const iforce = new vec2(0, 0);
    for (let i = 0; i < nodes.length; i++) {
      if (!nodes[i].visible) continue;
      for (let j = 0; j < nodes.length; j++) {
        if (j === i) continue;
        if (!nodes[j].visible) continue;
        dir.copyFrom(nodes[j].position);
        dir.sub(nodes[i].position);
        const magSqr = dir.lengthSqr();
        force.copyFrom(dir);
        force.div(magSqr);
        force.scale(this.forceConstantR);
        iforce.copyFrom(force);
        iforce.scale(-1);
        nodes[j].force.add(force);
        nodes[i].force.add(iforce);
      }
    }
    //
    // Apply attractive force implied by connections.
    const dis = new vec2(0, 0);
    for (let i = 0; i < edges.length; i++) {
      dis.copyFrom(edges[i].n1.position);
      dis.sub(edges[i].n2.position);
      const diff = 1.0 / (0.2 / dis.length());
      dis.normalize();
      dis.scale(this.forceConstantA * diff);
      if (edges[i].n1.visible && edges[i].n2.visible) {
        edges[i].n1.force.sub(dis);
        edges[i].n2.force.add(dis);
      }
    }
    //
    // Apply the calculated force to each node.
    // -> Force is scaled by the node's mass.
    for (let i = 0; i < nodes.length; i++) {
      if (!nodes[i].locked && !nodes[i].dragging && nodes[i].visible) {
        nodes[i].force.div(nodes[i].mass);
        nodes[i].position.add(nodes[i].force);
        nodes[i].position.y = Math.max(nodes[i].position.y, -0.8);
        nodes[i].position.y = Math.min(nodes[i].position.y, 0.8);
      }
    }
    //
    // Project graph space positions to screen.
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].project(w, h);
    }

    // Prevent the root node from moving past max y.
    if (nodes[0].positionT.y > maxY) {
      nodes[0].positionT.y = maxY;
    }
    if (nodes[0].positionT.y < minY) {
      nodes[0].positionT.y = minY;
    }
  }

  public render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
    //
    // Projective scaling.
    const halfWidth = width / 2.0;
    const halfHeight = height / 2.0;
    const s = Math.sqrt(halfWidth * halfWidth + halfHeight * halfHeight);
    //
    // Draw graph edges
    ctx.beginPath();
    ctx.strokeStyle = 'rgba(255,255,255,0.7)';
    ctx.lineWidth = 3;
    for (let i = 0; i < this.edges.length; i++) {
      const n1: GraphNode = this.edges[i].n1;
      const n2: GraphNode = this.edges[i].n2;
      // Only draw the edge if both nodes are valid and visible.
      const w1 = n1.size * s;
      const w2 = n2.size * s;
      const r1 = w1 / 2;
      const r2 = w2 / 2;
      n1.screenRadius = r1;
      n2.screenRadius = r2;
      if (n1 && n2 && n1.visible && n2.visible) {
        const dir = new vec2(n2.positionT.x - n1.positionT.x, n2.positionT.y - n1.positionT.y);
        dir.normalize();
        const dir1 = new vec2(0, 0);
        dir1.copyFrom(dir);
        const dir2 = new vec2(0, 0);
        dir2.copyFrom(dir);
        dir1.scale(r1);
        dir2.scale(r2);
        ctx.moveTo(n1.positionT.x + dir1.x, n1.positionT.y + dir1.y);
        ctx.lineTo(n2.positionT.x - dir2.x, n2.positionT.y - dir2.y);
      }
    }
    ctx.stroke();
    //
    // Draw the node images, based on their center point.
    ctx.fillStyle = 'rgba(90,120,150,1.0)';
    for (let i = 0; i < this.nodes.length; i++) {
      if (this.nodes[i].visible) {
        const fontSize = Math.max((width / 80 - this.nodes[i].depth * 2) | 0, 15);
        ctx.font = `${fontSize}px Verdana`;
        const w = this.nodes[i].size * s;
        const h = w * this.nodeImage.aspect;
        const nodeX = this.nodes[i].positionT.x - w / 2;
        const nodeY = this.nodes[i].positionT.y - h / 2;
        const children = this.getNodeChildren(this.nodes[i]);
        if (children.length === 0) {
          ctx.drawImage(this.nodeImage.image, nodeX, nodeY, w, h);
        } else {
          if (this.nodes[i].open) {
            ctx.drawImage(this.nodeOpenImage.image, nodeX, nodeY, w, h);
          } else {
            ctx.drawImage(this.nodeCloseImage.image, nodeX, nodeY, w, h);
          }
        }
        if (this.nodes[i].hover) {
          ctx.beginPath();
          ctx.strokeStyle = 'rgba(255,0,0,1.0)';
          ctx.arc(nodeX + w / 2, nodeY + h / 2, this.nodes[i].screenRadius, 0, Math.PI * 2);
          ctx.stroke();
        }
        if (i === 0 && this.rootFlash) {
          const a = (Math.sin(this.nodes[i].time / 200) + 1) / 2;
          ctx.beginPath();
          ctx.strokeStyle = `rgba(255,0,0,${a})`;
          ctx.arc(nodeX + w / 2, nodeY + h / 2, this.nodes[i].screenRadius, 0, Math.PI * 2);
          ctx.stroke();
        }
        const metrics = ctx.measureText(this.nodes[i].text);
        ctx.fillText(this.nodes[i].text, nodeX + w / 2 - metrics.width / 2, nodeY - h / 4);
      }
    }
  }
}
