import { mat4, vec4 } from './types';
import { VectorMath, deg2rad } from './math3d';

export class Camera {
  public position: vec4;
  public lookAt: vec4;
  public camMatrix: mat4;
  public forward: vec4;
  public up: vec4;
  public right: vec4;
  public roll: number;
  public yaw: number;
  public pitch: number;
  public positionObjSpace: vec4;

  constructor(public name: string) {
    this.position = new vec4(0, 0, 0, 1);
    this.positionObjSpace = new vec4(0, 0, 0, 1);
    this.lookAt = new vec4(0, 0, 1, 1);
    this.forward = new vec4(0, 0, 1, 0);
    this.up = new vec4(0, -1, 0, 0);
    this.right = new vec4(1, 0, 0, 0);
    this.camMatrix = mat4.makeIdentity();
    this.roll = 0.0;
    this.yaw = 0.0;
    this.pitch = 0.0;
  }

  setPosition(x: number, y: number, z: number): void {
    this.position.x = x;
    this.position.y = y;
    this.position.z = z;
    //this.buildMatrix(false);
  }

  setLookAt(x: number, y: number, z: number): void {
    this.lookAt.x = x;
    this.lookAt.y = y;
    this.lookAt.z = z;
    //this.buildMatrix(false);
  }

  moveForward(scale: number): void {
    this.position.x += this.forward.x * scale;
    this.position.y += this.forward.y * scale;
    this.position.z += this.forward.z * scale;
  }

  strafe(scale: number): void {
    this.position.x += this.right.x * scale;
    this.position.y += this.right.y * scale;
    this.position.z += this.right.z * scale;
  }

  setLookAtFromEuler(): void {
    this.forward.x = Math.cos(deg2rad(90 - this.yaw)) * Math.cos(deg2rad(this.pitch));
    this.forward.y = Math.sin(deg2rad(this.pitch));
    this.forward.z = Math.sin(deg2rad(90 - this.yaw)) * Math.cos(deg2rad(this.pitch));
    this.forward.normalize();
    this.buildMatrix(true);
  }

  buildMatrix(noFwd: boolean): void {
    if (!noFwd) {
      this.forward.x = this.lookAt.x - this.position.x;
      this.forward.y = this.lookAt.y - this.position.y;
      this.forward.z = this.lookAt.z - this.position.z;
      this.forward.normalize();
    }

    const worldUp = new vec4(0, 1, 0, 0);
    this.right = VectorMath.crossProduct(worldUp, this.forward);
    this.right.normalize();

    this.up = VectorMath.crossProduct(this.forward, this.right);
    this.up.normalize();

    this.camMatrix = mat4.makeLookAt(this.forward, this.up, this.right, this.position);
  }
}
