import { boundingSphere } from './boundingSphere';
import { Camera } from './camera';
import { Face } from './face';
import { FACE_SIZE, GeometryBuffer } from './geometrybuffer';
import { EnjinImage } from './image';
import { Light } from './light';
import { Material } from './material';
import { MatrixMath, extractPlanesToArray, transform } from './math3d';
import { plane } from './plane';
import { RenderTarget } from './renderTarget';
import { Texture } from './texture';
import { mat4, vec2, vec4 } from './types';

export class Terrain {
  public heightMap!: EnjinImage;
  public tiles: GeometryBuffer[] = [];
  public tileWidth!: number;
  public dimension = 0;
  public scale = 0;
  public texture!: Texture;
  public material = new Material('terrain-mat');

  public bounds: boundingSphere[] = [];
  private planes: plane[] = [];
  private culled: boolean[] = [];

  public modelToWorldMatrix: mat4 = mat4.makeIdentity();
  public inverseModelMatrix: mat4 = mat4.makeIdentity();

  constructor(d: number, hs: number) {
    this.dimension = d;
    this.scale = hs;
    for (let i = 0; i < 6; i++) {
      this.planes[i] = new plane(new vec4(0, 0, 0, 0), 0);
    }
  }

  public static async createFromHeightMap(
    url: string,
    splits: number,
    dimension: number,
    heightScale: number,
    r: RenderTarget
  ): Promise<Terrain> {
    const t = new Terrain(dimension, heightScale);
    t.tileWidth = splits;
    t.heightMap = await EnjinImage.fromURL(url);
    if (t.heightMap.width !== t.heightMap.height) {
      console.error('Height map image should be square!');
    } else {
      t.material.diffuseColor.set(0.8, 1.0, 1.0, 1);
      t.texture = await Texture.fromURL('terrain-texture', 'images/resume/terrain.png', false);
      r.bindTexture(t.texture);
      t.material.setTexture(t.texture);
      t.createBuffers();
      t.createVertices();
      t.createFaces();
      t.createBounds();
      t.finalizeBuffers();
    }
    return t;
  }

  public createBuffers(): void {
    for (let y = 0; y < this.tileWidth; y++) {
      for (let x = 0; x < this.tileWidth; x++) {
        const t = new GeometryBuffer(`terrain-tile-${x}-${y}`);
        t.useHSR = true;
        t.hasLight = false;
        this.tiles.push(t);
      }
    }
  }

  public finalizeBuffers(): void {
    for (let i = 0; i < this.tiles.length; i++) {
      this.tiles[i].buildNativeBuffers();
    }
  }

  public createVertices(): void {
    const pixelsPerTile = this.heightMap!.width / this.tileWidth;
    const dx = this.dimension / this.heightMap!.width;
    const dz = this.dimension / this.heightMap!.width;
    let ttx = -(this.dimension / 2);
    let ttz = -(this.dimension / 2);

    let ix = 0;
    let iy = 0;
    for (let z = 0; z < this.tileWidth; z++) {
      ttx = -(this.dimension / 2) + dx * 3.5;
      ix = 0;
      for (let x = 0; x < this.tileWidth; x++) {
        let tz = ttz;
        const tileOfs = x + z * this.tileWidth;
        for (let i = 0; i < pixelsPerTile; i++) {
          let tx = ttx;
          for (let j = 0; j < pixelsPerTile; j++) {
            const pixOfs = ix + iy * this.heightMap!.width;
            const height = ((this.heightMap!.data![pixOfs] & 0xff) / 255.0) * this.scale;
            this.tiles[tileOfs].addVertex(new vec4(tx, -height, tz, 1));
            tx += dx;
            ix++;
          }
          ix -= pixelsPerTile;
          iy++;
          tz += dz;
        }
        iy -= pixelsPerTile;
        ix += pixelsPerTile - 1;
        ttx += dx * (pixelsPerTile - 1);
      }
      iy += pixelsPerTile - 1;
      ttz += dz * (pixelsPerTile - 1);
    }
  }

  public createFaces(): void {
    const pixelsPerTile = this.heightMap!.width / this.tileWidth;
    for (let z = 0; z < this.tileWidth; z++) {
      for (let x = 0; x < this.tileWidth; x++) {
        const tileOfs = x + z * this.tileWidth;
        this.tiles[tileOfs].addNormal(new vec4(0, -1, 0, 0)); // dummy vertex normal, as we're not going to need it.
        this.tiles[tileOfs].addUV(new vec2(0, 0));
        this.tiles[tileOfs].addUV(new vec2(1, 0));
        this.tiles[tileOfs].addUV(new vec2(0, 1));
        this.tiles[tileOfs].addUV(new vec2(1, 1));

        for (let i = 0; i < pixelsPerTile - 1; i++) {
          for (let j = 0; j < pixelsPerTile - 1; j++) {
            const v1 = j + i * pixelsPerTile;
            const f1 = new Face([v1, v1 + pixelsPerTile, v1 + 1], [0, 0, 0], [0, 2, 1], this.tiles[tileOfs]);
            this.tiles[tileOfs].addFace(f1, this.material);
            const f2 = new Face(
              [v1 + 1, v1 + pixelsPerTile, v1 + pixelsPerTile + 1],
              [0, 0, 0],
              [1, 2, 3],
              this.tiles[tileOfs]
            );
            this.tiles[tileOfs].addFace(f2, this.material);
          }
        }
      }
    }
  }

  public createBounds(): void {
    for (let z = 0; z < this.tileWidth; z++) {
      for (let x = 0; x < this.tileWidth; x++) {
        const tileOfs = x + z * this.tileWidth;
        this.bounds[tileOfs] = boundingSphere.createFromVertices_Ritter(this.tiles[tileOfs].vertices);
      }
    }
  }

  public update(viewMatrix: mat4, persMatrix: mat4, viewWidth: number, viewHeight: number, camera: Camera): void {
    //
    // Finalize transform matrices.
    const modelToCameraMatrix = MatrixMath.Multiply(this.modelToWorldMatrix, viewMatrix);
    const mvMatrix = MatrixMath.Multiply(modelToCameraMatrix, persMatrix);
    MatrixMath.Inverse2(this.modelToWorldMatrix, this.inverseModelMatrix);
    //
    // Extract the frustum planes in object space to cull the terrain tiles against.
    const planes = this.planes;
    extractPlanesToArray(mvMatrix, planes);
    //
    // Mark all terrain tiles as not culled.
    for (let i = 0; i < this.tiles.length; i++) {
      this.culled[i] = false;
      for (let j = 0; j < planes.length; j++) {
        const dist = planes[j].signedDistanceToPoint(this.bounds[i]!.position);
        if (dist < -this.bounds[i]!.radius) {
          this.culled[i] = true;
        }
      }
      if (!this.culled[i]) {
        this.updateTile(mvMatrix, viewWidth, viewHeight, camera, i);
      }
      //
      // Transform the bounding spheres position into world space for collision tests.
      transform(this.bounds[i]!.position, this.bounds[i]!.positionT, this.modelToWorldMatrix);
    }
  }

  public updateTile(mvMatrix: mat4, viewWidth: number, viewHeight: number, camera: Camera, idx: number): void {
    //
    // Transform all the vertices into homogenous clip space.
    this.tiles[idx].setMatrix(mvMatrix);
    this.tiles[idx].transformVerticesWASM(0, this.tiles[idx].vertexCount);
    //
    // Perform Object Space Back face Culling and Triangle Culling and 3d clipping.
    const faceData = this.tiles[idx].facesDataI32;
    const halfWidth = viewWidth / 2;
    const halfHeight = viewHeight / 2;
    this.tiles[idx].resetClipping();
    transform(camera.position, camera.positionObjSpace, this.inverseModelMatrix);
    for (let i = 0; i < this.tiles[idx].faceCount; i++) {
      if (this.tiles[idx].triangleOut(i, false)) {
        faceData[i * FACE_SIZE + 12] = 0;
        continue;
      } else {
        faceData[i * FACE_SIZE + 12] = 1;
        this.tiles[idx].faces[i].hsrObjectSpace(i, camera.positionObjSpace);
      }
      if (faceData[i * FACE_SIZE + 12] === 1) {
        this.tiles[idx].faces[i].clipNear(i);
      }
    }
    //
    // Perform 2d projection and HSR (including additional aux faces created during clipping)
    for (let i = 0; i < this.tiles[idx].faceCount + this.tiles[idx].auxCount; i++) {
      // Only bother with triangles that are NOT culled.
      if (faceData[i * FACE_SIZE + 12] === 1) {
        Face.project(this.tiles[idx], i, halfWidth, halfHeight);
      }
    }
  }

  public render(r: RenderTarget): void {
    for (let tileIdx = 0; tileIdx < this.tiles.length; tileIdx++) {
      if (!this.culled[tileIdx]) {
        const gb = this.tiles[tileIdx];
        const faceCount = gb.faceCount;
        const faces = gb.faces;
        let numAux = 0;
        for (let i = 0; i < faceCount; i++) {
          const face = faces[i];
          if (gb.facesDataI32[i * FACE_SIZE + 12] === 1) {
            if (face.clipCode <= 2) {
              r.binTriangle(gb, i);
            }
            if (face.clipCode === 2) {
              r.binTriangle(gb, faceCount + numAux++);
            }
          } else if (face.clipCode === 2) {
            numAux++;
          }
        }
      }
    }
  }
}
