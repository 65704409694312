export class AudioSample {
  public buffer: AudioBuffer | null = null;
  public gain: GainNode | null = null;
  public pan: StereoPannerNode | null = null;
  public url = '';

  public playing = false;
  public current = 0;

  public panning = 0;
  public volume = 1;
  public rate = 1;
  public limit = 2;
  public loop = false;
  public retrigger = false;

  private source: AudioBufferSourceNode | null = null;

  set Retrigger(value: boolean) {
    this.limit = 1;
    this.retrigger = value;
  }

  public static async load(url: string, ctx: AudioContext): Promise<AudioSample> {
    const sample = new AudioSample();
    sample.url = url;
    sample.buffer = await AudioManager.loadSampleAsAudioBuffer(url);
    sample.gain = new GainNode(ctx);
    sample.gain.connect(ctx.destination);
    sample.pan = new StereoPannerNode(ctx);
    sample.pan.connect(sample.gain);
    return sample;
  }

  public stop(): void {
    this.source?.stop();
  }

  public playWithDefaults(ctx: AudioContext): void {
    if (this.current < this.limit) {
      this.source = new AudioBufferSourceNode(ctx, {
        buffer: this.buffer,
        playbackRate: this.rate,
      });
      this.source.loop = this.loop;
      this.source.connect(this.pan!);

      this.pan!.pan.setValueAtTime(this.panning, ctx.currentTime);
      this.gain!.gain.setValueAtTime(this.volume, ctx.currentTime);

      this.source.start(0);
      this.current++;
      this.source.addEventListener('ended', () => {
        this.current--;
      });
    }
  }

  public play(rate: number, ctx: AudioContext, volume: number, panning: number): void {
    if (this.retrigger) {
      this.source?.stop(ctx.currentTime);
      this.current = 0;
    }

    if (this.current < this.limit) {
      this.panning = panning;
      this.volume = volume;
      this.rate = rate;

      this.source = new AudioBufferSourceNode(ctx, {
        buffer: this.buffer,
        playbackRate: rate,
      });
      this.source.loop = this.loop;
      this.source.connect(this.pan!);

      this.pan!.pan.setValueAtTime(panning, ctx.currentTime);
      this.gain!.gain.setValueAtTime(volume, ctx.currentTime);

      this.source.start(0);
      this.current++;
      this.source.addEventListener('ended', () => {
        this.current--;
      });
    }
  }
}

export class AudioManager {
  public static audioContext: AudioContext;
  public static samples: AudioSample[] = [];
  public static isInitialized = false;

  public static initialize(): void {
    if (!AudioManager.isInitialized) {
      AudioManager.audioContext = new AudioContext();
      AudioManager.isInitialized = true;
    }
  }

  public static async resume(): Promise<void> {
    if (AudioManager.audioContext.state === 'suspended') {
      await AudioManager.audioContext.resume();
      AudioManager.flushQueue();
    }
  }

  public static async loadSampleAsAudioBuffer(url: string): Promise<AudioBuffer | null> {
    let audioBuffer: AudioBuffer | null = null;
    if (AudioManager.audioContext) {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      audioBuffer = await AudioManager.audioContext.decodeAudioData(arrayBuffer);
    }
    return audioBuffer;
  }

  public static addSample(sample: AudioSample): void {
    AudioManager.samples.push(sample);
  }

  public static playQueued(sample: AudioSample, rate: number, volume: number, panning: number): void {
    AudioManager.samples.push(sample);
    sample.volume = volume;
    sample.panning = panning;
    sample.rate = rate;
  }

  public static flushQueue(): void {
    for (let i = 0; i < this.samples.length; i++) {
      this.samples[i].playWithDefaults(this.audioContext);
      this.samples.splice(i, 1);
      i--;
    }
  }
}
