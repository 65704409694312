import { plane } from './plane';
import { mat4, vec2, vec4, quarternion } from './types';

export const ANG2RAD = 3.1415 / 180.0;
export const RAD2ANG = 180.0 / 3.1415;

export class VectorMath {
  public static dotProduct(v1: vec4, v2: vec4): number {
    return v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
  }

  public static crossProduct(v1: vec4, v2: vec4): vec4 {
    const result = new vec4(v1.y * v2.z - v1.z * v2.y, v1.z * v2.x - v1.x * v2.z, v1.x * v2.y - v1.y * v2.x, 0);
    return result;
  }

  public static add(v1: vec4, v2: vec4) {
    v1.x += v2.x;
    v1.y += v2.y;
    v1.z += v2.z;
    v1.w += v2.w;
  }

  public static sub(v1: vec4, v2: vec4) {
    v1.x -= v2.x;
    v1.y -= v2.y;
    v1.z -= v2.z;
    v1.w -= v2.w;
  }
}

export class MatrixMath {
  public static Multiply(m1: mat4, m2: mat4): mat4 {
    const result = new mat4();

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        result.elements[i + j * 4] = 0;
        for (let k = 0; k < 4; k++) {
          result.elements[i + j * 4] += m1.elements[i + k * 4] * m2.elements[k + j * 4];
        }
      }
    }

    return result;
  }

  public static Multiply2(m1: mat4, m2: mat4, result: mat4): void {
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        result.elements[i + j * 4] = 0;
        for (let k = 0; k < 4; k++) {
          result.elements[i + j * 4] += m1.elements[i + k * 4] * m2.elements[k + j * 4];
        }
      }
    }
  }

  public static Transpose(m1: mat4, m2: mat4) {
    for (let j = 0; j < 4; j++) {
      for (let i = 0; i < 4; i++) {
        m2.elements[i + j * 4] = m1.elements[j + i * 4];
      }
    }
  }

  public static Inverse(m: mat4): mat4 {
    const res = mat4.makeIdentity();

    for (let i = 0; i < 3; ++i) {
      for (let j = 0; j < 3; ++j) {
        res.elements[i + j * 4] = m.elements[j + i * 4];
      }
      res.elements[i + 3 * 4] = 0.0;
    }
    for (let i = 0; i < 3; ++i) {
      res.elements[3 + i * 4] = 0.0;
      for (let j = 0; j < 3; ++j) {
        res.elements[3 + i * 4] += -res.elements[j + i * 4] * m.elements[3 + j * 4];
      }
    }
    res.elements[3 + 3 * 4] = 1.0;
    return res;
  }

  public static Inverse2(m: mat4, res: mat4): void {
    for (let i = 0; i < 3; ++i) {
      for (let j = 0; j < 3; ++j) {
        res.elements[i + j * 4] = m.elements[j + i * 4];
      }
      res.elements[i + 3 * 4] = 0.0;
    }
    for (let i = 0; i < 3; ++i) {
      res.elements[3 + i * 4] = 0.0;
      for (let j = 0; j < 3; ++j) {
        res.elements[3 + i * 4] += -res.elements[j + i * 4] * m.elements[3 + j * 4];
      }
    }
    res.elements[3 + 3 * 4] = 1.0;
  }
}

export class QuartMath {
  public static Multiply(A: quarternion, B: quarternion): quarternion {
    const q = new quarternion(0, 0, 0, 0);
    q.x = A.w * B.x + A.x * B.w + A.y * B.z - A.z * B.y;
    q.y = A.w * B.y - A.x * B.z + A.y * B.w + A.z * B.x;
    q.z = A.w * B.z + A.x * B.y - A.y * B.x + A.z * B.w;
    q.w = A.w * B.w - A.x * B.x - A.y * B.y - A.z * B.z;
    return q;
  }
}

export function transform(vIn: vec4, vOut: vec4, m: mat4) {
  const melements = m.elements;
  vOut.x = vIn.x * melements[0] + vIn.y * melements[1] + vIn.z * melements[2] + vIn.w * melements[3];
  vOut.y = vIn.x * melements[4] + vIn.y * melements[5] + vIn.z * melements[6] + vIn.w * melements[7];
  vOut.z = vIn.x * melements[8] + vIn.y * melements[9] + vIn.z * melements[10] + vIn.w * melements[11];
  vOut.w = vIn.x * melements[12] + vIn.y * melements[13] + vIn.z * melements[14] + vIn.w * melements[15];
}

export function deg2rad(deg: number): number {
  return (deg * Math.PI) / 180.0;
}

export function rad2deg(rad: number): number {
  return (rad * 180.0) / Math.PI;
}

// Imprecise method, which does not guarantee v = v1 when t = 1, due to floating-point arithmetic error.
// This method is monotonic. This form may be used when the hardware has a native fused multiply-add instruction.
export function lerp(v0: number, v1: number, t: number): number {
  return v0 + t * (v1 - v0);
}

// Precise method, which guarantees v = v1 when t = 1. This method is monotonic only when v0 * v1 < 0.
// Lerping between same values might not produce the same value
export function accurateLerp(v0: number, v1: number, t: number): number {
  return (1 - t) * v0 + t * v1;
}

// Easing functions: see -> https://easings.net/
export function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function extractPlanes(m: mat4): plane[] {
  const planes: plane[] = [];

  const leftPlane = new plane(
    new vec4(
      m.elements[0 + 3 * 4] + m.elements[0 + 0 * 4],
      m.elements[1 + 3 * 4] + m.elements[1 + 0 * 4],
      m.elements[2 + 3 * 4] + m.elements[2 + 0 * 4],
      0
    ),
    m.elements[3 + 3 * 4] + m.elements[3 + 0 * 4]
  );
  planes[0] = leftPlane;

  const rightPlane = new plane(
    new vec4(
      m.elements[0 + 3 * 4] - m.elements[0 + 0 * 4],
      m.elements[1 + 3 * 4] - m.elements[1 + 0 * 4],
      m.elements[2 + 3 * 4] - m.elements[2 + 0 * 4],
      0
    ),
    m.elements[3 + 3 * 4] - m.elements[3 + 0 * 4]
  );
  planes[1] = rightPlane;

  const topPlane = new plane(
    new vec4(
      m.elements[0 + 3 * 4] - m.elements[0 + 1 * 4],
      m.elements[1 + 3 * 4] - m.elements[1 + 1 * 4],
      m.elements[2 + 3 * 4] - m.elements[2 + 1 * 4],
      0
    ),
    m.elements[3 + 3 * 4] - m.elements[3 + 1 * 4]
  );
  planes[2] = topPlane;

  const bottomPlane = new plane(
    new vec4(
      m.elements[0 + 3 * 4] + m.elements[0 + 1 * 4],
      m.elements[1 + 3 * 4] + m.elements[1 + 1 * 4],
      m.elements[2 + 3 * 4] + m.elements[2 + 1 * 4],
      0
    ),
    m.elements[3 + 3 * 4] + m.elements[3 + 1 * 4]
  );
  planes[3] = bottomPlane;

  const nearPlane = new plane(
    new vec4(m.elements[0 + 2 * 4], m.elements[1 + 2 * 4], m.elements[2 + 2 * 4], 0),
    m.elements[3 + 2 * 4]
  );
  planes[4] = nearPlane;

  const farPlane = new plane(
    new vec4(
      m.elements[0 + 3 * 4] - m.elements[0 + 2 * 4],
      m.elements[1 + 3 * 4] - m.elements[1 + 2 * 4],
      m.elements[2 + 3 * 4] - m.elements[2 + 2 * 4],
      0
    ),
    m.elements[3 + 3 * 4] - m.elements[3 + 2 * 4]
  );
  planes[5] = farPlane;

  return planes;
}

export function extractPlanesToArray(m: mat4, planes: plane[]): void {
  const leftPlane = planes[0];
  const rightPlane = planes[1];
  const topPlane = planes[2];
  const bottomPlane = planes[3];
  const nearPlane = planes[4];
  const farPlane = planes[5];

  leftPlane.normal.x = m.elements[0 + 3 * 4] + m.elements[0 + 0 * 4];
  leftPlane.normal.y = m.elements[1 + 3 * 4] + m.elements[1 + 0 * 4];
  leftPlane.normal.z = m.elements[2 + 3 * 4] + m.elements[2 + 0 * 4];
  leftPlane.normal.w = m.elements[3 + 3 * 4] + m.elements[3 + 0 * 4];
  leftPlane.normalize();
  leftPlane.distance = leftPlane.normal.w;

  rightPlane.normal.x = m.elements[0 + 3 * 4] - m.elements[0 + 0 * 4];
  rightPlane.normal.y = m.elements[1 + 3 * 4] - m.elements[1 + 0 * 4];
  rightPlane.normal.z = m.elements[2 + 3 * 4] - m.elements[2 + 0 * 4];
  rightPlane.normal.w = m.elements[3 + 3 * 4] - m.elements[3 + 0 * 4];
  rightPlane.normalize();
  rightPlane.distance = rightPlane.normal.w;

  topPlane.normal.x = m.elements[0 + 3 * 4] - m.elements[0 + 1 * 4];
  topPlane.normal.y = m.elements[1 + 3 * 4] - m.elements[1 + 1 * 4];
  topPlane.normal.z = m.elements[2 + 3 * 4] - m.elements[2 + 1 * 4];
  topPlane.normal.w = m.elements[3 + 3 * 4] - m.elements[3 + 1 * 4];
  topPlane.normalize();
  topPlane.distance = topPlane.normal.w;

  bottomPlane.normal.x = m.elements[0 + 3 * 4] + m.elements[0 + 1 * 4];
  bottomPlane.normal.y = m.elements[1 + 3 * 4] + m.elements[1 + 1 * 4];
  bottomPlane.normal.z = m.elements[2 + 3 * 4] + m.elements[2 + 1 * 4];
  bottomPlane.normal.w = m.elements[3 + 3 * 4] + m.elements[3 + 1 * 4];
  bottomPlane.normalize();
  bottomPlane.distance = bottomPlane.normal.w;

  nearPlane.normal.x = m.elements[0 + 2 * 4];
  nearPlane.normal.y = m.elements[1 + 2 * 4];
  nearPlane.normal.z = m.elements[2 + 2 * 4];
  nearPlane.normal.w = m.elements[3 + 2 * 4];
  nearPlane.normalize();
  nearPlane.distance = nearPlane.normal.w;

  farPlane.normal.x = m.elements[0 + 3 * 4] - m.elements[0 + 2 * 4];
  farPlane.normal.y = m.elements[1 + 3 * 4] - m.elements[1 + 2 * 4];
  farPlane.normal.z = m.elements[2 + 3 * 4] - m.elements[2 + 2 * 4];
  farPlane.normal.w = m.elements[3 + 3 * 4] - m.elements[3 + 2 * 4];
  farPlane.normalize();
  farPlane.distance = farPlane.normal.w;
}

// Compute barycentric coordinates (u, v, w) for point p with respect to triangle (a, b, c)
// https://ceng2.ktu.edu.tr/~cakir/files/grafikler/Texture_Mapping.pdf
export function PointInTriangle(a: vec4, b: vec4, c: vec4, p: vec4): boolean {
  const v0 = vec4.sub(b, a);
  const v1 = vec4.sub(c, a);
  const v2 = vec4.sub(p, a);
  const d00 = VectorMath.dotProduct(v0, v0);
  const d01 = VectorMath.dotProduct(v0, v1);
  const d11 = VectorMath.dotProduct(v1, v1);
  const d20 = VectorMath.dotProduct(v2, v0);
  const d21 = VectorMath.dotProduct(v2, v1);
  const denom = d00 * d11 - d01 * d01;
  const v = (d11 * d20 - d01 * d21) / denom;
  const w = (d00 * d21 - d01 * d20) / denom;
  //const u = 1.0 - v - w;
  if (v >= 0.0 && w >= 0.0 && v + w <= 1.0) {
    return true;
  } else {
    return false;
  }
}

//https://www.cubic.org/docs/hermite.htm
export function pointOnHermite(p0: vec4, p1: vec4, r0: vec4, r1: vec4, t: number): vec4 {
  /*const hermite: mat4 = mat4.makeHermite();
  const pos = new vec4(0, 0, 0, 0);
  const xp = new vec4(p0.x, p1.x, r0.x, r1.x);
  const yp = new vec4(p0.y, p1.y, r0.y, r1.y);
  const zp = new vec4(p0.z, p1.z, r0.z, r1.z);
  const tt: vec4 = new vec4(t * t * t, t * t, t, 1.0);
  const hB = new vec4(0, 0, 0, 0);
  transform(tt, hB, hermite);
  pos.x = VectorMath.dotProduct(hB, xp);
  pos.y = VectorMath.dotProduct(hB, yp);
  pos.z = VectorMath.dotProduct(hB, zp);
*/
  const h1 = 2 * t * t * t - 3 * t * t + 1; // calculate basis function 1
  const h2 = -2 * t * t * t + 3 * t * t; // calculate basis function 2
  const h3 = t * t * t - 2 * t * t + t; // calculate basis function 3
  const h4 = t * t * t - t * t; // calculate basis function 4
  const p = new vec4(
    p0.x * h1 + p1.x * h2 + r0.x * h3 + r1.x * h4,
    p0.y * h1 + p1.y * h2 + r0.y * h3 + r1.y * h4,
    p0.z * h1 + p1.z * h2 + r0.z * h3 + r1.z * h4,
    1
  );
  return p;
}

//TEST SPHERE AGAINST AABB intersects
/*
function intersect(sphere, box) {
  // get box closest point to sphere center by clamping
  const x = Math.max(box.minX, Math.min(sphere.x, box.maxX));
  const y = Math.max(box.minY, Math.min(sphere.y, box.maxY));
  const z = Math.max(box.minZ, Math.min(sphere.z, box.maxZ));

  // this is the same as isPointInsideSphere
  const distance = Math.sqrt(
    (x - sphere.x) * (x - sphere.x) +
      (y - sphere.y) * (y - sphere.y) +
      (z - sphere.z) * (z - sphere.z)
  );

  return distance < sphere.radius;
} 
*/
