import { Canvas } from './canvas';
import { MemoryPool, Variable, VARTYPE } from './mallocator';
import { FuncType, Scheduler, IDictionary } from './scheduler';
import { Texture } from './texture';
import { FACE_SIZE, GeometryBuffer } from './geometrybuffer';

export class RenderTarget {
  public width = 0;
  public height = 0;
  public destHeight = 0;
  public newWidth = 0;
  public newHeight = 0;
  public initialClearSize = 0;

  public memory: MemoryPool;

  public rasterDataVar: Variable;
  public rasterData: Uint32Array;
  public zbufferDataVar: Variable;
  public zbufferData: Float32Array;
  public zbuffering = true;

  public binCountVar: Variable;
  public tobinVar: Variable;
  public binList: Uint32Array;

  public occVar: Variable;
  public occlusionBuffer: Float32Array;
  public occluded = 0;

  public binsVar: Variable;
  public binCounts: Uint32Array;
  public bins: Uint32Array;
  public triangleCount = 0;

  public signalData: Int32Array;
  public syncMemory: SharedArrayBuffer;

  public scratchVar: Variable;

  private needResize = false;
  public binWidth = 0;
  public binHeight = 0;
  public binCount = 0;

  public static readonly MAX_PRIMS = 256; // Maximum number of primitives (triangles) per bin.
  public static readonly MAX_TRIS = 100000; // Maximum number of primitives (triangles) in an entire frame.

  public toBinCount = 0;

  public textureStorage = 256 * 256 * 10 * 4; // Currently allocated texture storage on the rendertarget. TODO: make this configurable/dynamic
  public textures: IDictionary<Variable> = {};
  public textureVar: Variable;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private wasmFuncs?: any;

  public gamma = 2.2;
  public igamma = 1.0 / 2.2;
  public cnorm = 1.0 / 255.0;
  public gammaLUT: number[] = [];

  constructor(width: number, height: number, mem: MemoryPool) {
    // We need to round up the input w/h to be multiples of 8 to support 8x8 bins.
    this.width = width.roundUp(8);
    this.height = height.roundUp(8);
    this.destHeight = height;
    //---------------
    this.binWidth = (1920 / 8) | 0;
    this.binHeight = (1080 / 8) | 0;
    this.binCount = this.binWidth * this.binHeight;
    this.memory = mem;
    this.textureVar = this.memory.addVariable('TextureStore', VARTYPE.UINT32, this.textureStorage / 4);
    this.rasterDataVar = this.memory.addVariable('RenderData', VARTYPE.UINT32, 1920 * 1080);
    this.zbufferDataVar = this.memory.addVariable('ZData', VARTYPE.FLOAT, 1920 * 1080);
    this.occVar = this.memory.addVariable('occ', VARTYPE.FLOAT, this.binCount);
    this.binCountVar = this.memory.addVariable('binCount', VARTYPE.UINT32, this.binCount);
    this.binsVar = this.memory.addVariable('bins', VARTYPE.UINT32, this.binCount * RenderTarget.MAX_PRIMS);
    this.tobinVar = this.memory.addVariable('binlist', VARTYPE.UINT32, RenderTarget.MAX_TRIS);
    const buf = this.memory.memory.buffer;
    this.binList = new Uint32Array(buf, this.tobinVar.ptr, this.tobinVar.size / 4);
    this.rasterData = new Uint32Array(buf, this.rasterDataVar.ptr, this.width * height);
    //---------------
    this.zbufferData = new Float32Array(buf, this.zbufferDataVar.ptr, this.zbufferDataVar.size / 4);
    this.binCounts = new Uint32Array(buf, this.binCountVar.ptr, this.binCountVar.size / 4);
    this.occlusionBuffer = new Float32Array(buf, this.occVar.ptr, this.occVar.size / 4);
    this.bins = new Uint32Array(buf, this.binsVar.ptr, this.binsVar.size / 4);
    //---------------
    this.syncMemory = new SharedArrayBuffer(1024);
    Scheduler.setSync('syncMemory', this.syncMemory);
    this.signalData = new Int32Array(this.syncMemory, 0, 64);
    //---------------
    this.scratchVar = this.memory.addVariable('Scratch', VARTYPE.UINT8, 256);
    //---------------
    this.initialClearSize = this.rasterDataVar.size + this.zbufferDataVar.size + this.occVar.size;

    const importObject = {
      imports: {
        mem: this.memory.memory,
      },
    };
    WebAssembly.instantiateStreaming(fetch('wasm/render.wasm'), importObject).then((obj) => {
      this.wasmFuncs = obj.instance.exports;
    });

    // Configure multi-threading stuff
    Scheduler.loadWASMModule('renderWASM', 'wasm/render.wasm', 'appMemory');

    Scheduler.loadCode('render', {
      msg: '',
      workerId: 0,
      maxThreads: 0,
      args: [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      copyClearBuffer: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory'] && wasmModules['renderWASM'].funcs) {
          const srcPtr = self.args[0];
          const destPtr = self.args[1];
          const size = self.args[2];
          const sizePerThread = (size / self.maxThreads) | 0;
          const startOfs = self.workerId * sizePerThread;
          const size2 = self.args[3];
          const sizePerThread2 = (size2 / self.maxThreads) | 0;
          const startOfs2 = self.workerId * sizePerThread2;
          wasmModules['renderWASM'].funcs['copy'](srcPtr + startOfs, destPtr + startOfs, sizePerThread);
          wasmModules['renderWASM'].funcs['clear'](srcPtr + startOfs2, ((sizePerThread2 / 32) | 0) + 1);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearBuffers: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory'] && wasmModules['renderWASM'].funcs) {
          const ptr = self.args[0];
          const size = self.args[1];
          const sizePerThread = (size / self.maxThreads) | 0;
          const startOfs = self.workerId * sizePerThread;
          wasmModules['renderWASM'].funcs['clear'](ptr + startOfs, ((sizePerThread / 32) | 0) + 1);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      gammaProcess: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory'] && wasmModules['renderWASM'].funcs) {
          const screenWidth = self.args[0];
          const screenHeight = self.args[1];
          const sizePerThread = ((screenHeight * screenWidth) / self.maxThreads) | 0;
          const startOfs = self.workerId * sizePerThread;
          wasmModules['renderWASM'].funcs['gamma'](self.args[2] + startOfs * 4, sizePerThread);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fogProcess: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory'] && wasmModules['renderWASM'].funcs) {
          const screenWidth = self.args[0];
          const screenHeight = self.args[1];
          let sizePerThread = (screenHeight / self.maxThreads) | 0;
          const remain = screenHeight % self.maxThreads;
          const startOfs = self.workerId * sizePerThread;
          const rtData = pools['appMemory'].memory.buffer;
          const rasterData = new Uint32Array(rtData, self.args[2], self.args[4]);
          const zbufferData = new Float32Array(rtData, self.args[3], self.args[5]);

          if (self.workerId === self.maxThreads - 1) {
            sizePerThread += remain;
          }
          const ofs = startOfs * screenWidth;
          const binWidth = self.args[6];

          wasmModules['renderWASM'].funcs['fog'](
            ofs * 4,
            startOfs,
            sizePerThread,
            self.args[2],
            self.args[3],
            screenWidth / 8,
            binWidth,
            self.args[7]
          );
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rasterProcess: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory'] && wasmModules['renderWASM'].funcs) {
          const screenWidth = self.args[0];
          const screenHeight = self.args[1];
          let sizePerThread = (screenHeight / 4 / self.maxThreads) | 0;
          const remain = (screenHeight / 4) % self.maxThreads;
          const startOfs = self.workerId * sizePerThread;
          if (self.workerId === self.maxThreads - 1) {
            sizePerThread += remain;
          }
          const y = startOfs * 4;
          wasmModules['renderWASM'].funcs['raster'](self.args[2] + y * screenWidth * 4, screenWidth / 4, screenWidth * 12, sizePerThread);
        }
      },
      generateRasterizerData: (FDataF32: Float32Array, FDataI32: Int32Array, ofs: number, w: number, h: number) => {
        const x1 = FDataF32[ofs + 60];
        const y1 = FDataF32[ofs + 61];
        const x2 = FDataF32[ofs + 62];
        const y2 = FDataF32[ofs + 63];
        const x3 = FDataF32[ofs + 64];
        const y3 = FDataF32[ofs + 65];
        let minx = Math.min(x1, x2, x3);
        let miny = Math.min(y1, y2, y3);
        let maxx = Math.max(x1, x2, x3);
        let maxy = Math.max(y1, y2, y3);

        // Clip to canvas size.
        minx = minx < 0 ? 0 : minx;
        miny = miny < 0 ? 0 : miny;
        maxx = maxx >= w - 1 ? w - 1 : maxx;
        maxy = maxy >= h - 1 ? h - 1 : maxy;

        // Triangle is zero width/height or totally culled.
        const dx = maxx - minx;
        const dy = maxy - miny;
        if (dx <= 0 || dy <= 0) {
          FDataI32[ofs + 12] = 0;
          return false;
        }

        // Get positions in 8x8 bin space.
        minx = (minx >> 3) << 3;
        miny = (miny >> 3) << 3;
        FDataF32[ofs + 47] = minx;
        FDataF32[ofs + 48] = miny;
        FDataF32[ofs + 57] = (maxx >> 3) << 3;
        FDataF32[ofs + 58] = (maxy >> 3) << 3;

        // Convert coordinates to 28.4 fixed point.
        const X1 = (x1 * 16.0) | 0;
        const Y1 = (y1 * 16.0) | 0;
        const X2 = (x2 * 16.0) | 0;
        const Y2 = (y2 * 16.0) | 0;
        const X3 = (x3 * 16.0) | 0;
        const Y3 = (y3 * 16.0) | 0;

        // Calcluate edge deltas.
        const DX12 = X2 - X1;
        const DY12 = Y2 - Y1;
        const DX23 = X3 - X2;
        const DY23 = Y3 - Y2;
        const DX31 = X1 - X3;
        const DY31 = Y1 - Y3;

        const FDX12 = DX12 << 4;
        const FDX23 = DX23 << 4;
        const FDX31 = DX31 << 4;
        const FDY12 = DY12 << 4;
        const FDY23 = DY23 << 4;
        const FDY31 = DY31 << 4;

        FDataI32[ofs + 20] = FDX12;
        FDataI32[ofs + 21] = FDX23;
        FDataI32[ofs + 22] = FDX31;
        FDataI32[ofs + 23] = FDY12;
        FDataI32[ofs + 24] = FDY23;
        FDataI32[ofs + 25] = FDY31;

        // Half-edge constants
        minx = minx << 4;
        miny = miny << 4;
        let CY1 = DX12 * (miny - Y1) - DY12 * (minx - X1); // CY1
        let CY2 = DX23 * (miny - Y2) - DY23 * (minx - X2); // CY2
        let CY3 = DX31 * (miny - Y3) - DY31 * (minx - X3); // CY3

        // Correct for fill convention
        if (DY12 < 0 || (DY12 === 0 && DX12 > 0)) CY1++;
        if (DY23 < 0 || (DY23 === 0 && DX23 > 0)) CY2++;
        if (DY31 < 0 || (DY31 === 0 && DX31 > 0)) CY3++;
        FDataI32[ofs + 26] = CY1;
        FDataI32[ofs + 27] = CY2;
        FDataI32[ofs + 28] = CY3;

        FDataI32[ofs + 29] = FDX12 << 3; //OFDX12
        FDataI32[ofs + 30] = FDX23 << 3; //OFDX23
        FDataI32[ofs + 31] = FDX31 << 3; //OFDX31
        FDataI32[ofs + 32] = FDY12 << 3; //OFDY12
        FDataI32[ofs + 33] = FDY23 << 3; //OFDY23
        FDataI32[ofs + 34] = FDY31 << 3; //OFDY31

        let eo1 = 0;
        let eo2 = 0;
        let eo3 = 0;
        if (FDY12 < 0) eo1 -= FDY12 << 3; //meaning y1 is above y2
        if (FDX12 > 0) eo1 += FDX12 << 3; //meaning x1 is right of x2
        if (FDY23 < 0) eo2 -= FDY23 << 3; //meaning y2 is above y3
        if (FDX23 > 0) eo2 += FDX23 << 3; //meaning x2 is right of x3
        if (FDY31 < 0) eo3 -= FDY31 << 3; //meaning y3 is above y1
        if (FDX31 > 0) eo3 += FDX31 << 3; //meaning x3 is right of x1
        FDataI32[ofs + 35] = eo1;
        FDataI32[ofs + 36] = eo2;
        FDataI32[ofs + 37] = eo3;

        //these are the offsets fo the bottom-right block corner
        FDataI32[ofs + 38] = (DX12 << 7) - (DY12 << 7) - eo1; //block size is 8 ==> shl 3 + 4
        FDataI32[ofs + 39] = (DX23 << 7) - (DY23 << 7) - eo2; //ei1-ei3
        FDataI32[ofs + 40] = (DX31 << 7) - (DY31 << 7) - eo3;

        // Calculate interpolation values at minx,miny
        // Plane equation is: Ax + By + Cz + D = 0
        // We treat the 2d triangle as x/y and use z to represent the value we wish to interpolate
        // from this we derive:
        // z = -A/C * x - B/C * y - D
        // you can see that for every step of x/y the deltas are -A/C and -B/C
        // (A,B,C) is actual the normal of the plane.
        // C is constant regardless of interpolant.
        const C = 1.0 / ((x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1)); // C is constant for all interpolants.

        const z1 = 1.0 / FDataF32[ofs + 66 + 3];
        const z2 = 1.0 / FDataF32[ofs + 70 + 3];
        const z3 = 1.0 / FDataF32[ofs + 74 + 3];
        let A = (z3 - z1) * (y2 - y1) - (z2 - z1) * (y3 - y1);
        let B = (x3 - x1) * (z2 - z1) - (x2 - x1) * (z3 - z1);
        FDataF32[ofs + 41] = -A * C; //dzdx
        FDataF32[ofs + 42] = -B * C; //dzdy

        const u1 = FDataF32[ofs + 90 + 0] * z1;
        const v1 = FDataF32[ofs + 90 + 1] * z1;
        const u2 = FDataF32[ofs + 92 + 0] * z2;
        const v2 = FDataF32[ofs + 92 + 1] * z2;
        const u3 = FDataF32[ofs + 94 + 0] * z3;
        const v3 = FDataF32[ofs + 94 + 1] * z3;
        A = (u3 - u1) * (y2 - y1) - (u2 - u1) * (y3 - y1);
        B = (x3 - x1) * (u2 - u1) - (x2 - x1) * (u3 - u1);
        FDataF32[ofs + 43] = -A * C; //dudx
        FDataF32[ofs + 44] = -B * C; //dudy

        A = (v3 - v1) * (y2 - y1) - (v2 - v1) * (y3 - y1);
        B = (x3 - x1) * (v2 - v1) - (x2 - x1) * (v3 - v1);
        FDataF32[ofs + 45] = -A * C; //dvdx
        FDataF32[ofs + 46] = -B * C; //dvdy

        FDataF32[ofs + 54] = z1;
        FDataF32[ofs + 55] = FDataF32[ofs + 90 + 0] * z1; // U/z and V/z
        FDataF32[ofs + 56] = FDataF32[ofs + 90 + 1] * z1;

        return true;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      binProcess: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory']) {
          const rtData = pools['appMemory'].memory.buffer;
          const faceCount = self.args[2];
          const bins = new Uint32Array(rtData, self.args[3], self.args[4]);
          const binWidth = self.args[5];
          const binHeight = self.args[6];
          const binCounts = new Uint32Array(rtData, self.args[9], self.args[10]);
          const binList = new Uint32Array(rtData, self.args[7], self.args[8]);
          const FDataI32 = new Int32Array(rtData, 0);
          const FDataF32 = new Float32Array(rtData, 0);

          let rowsPerThread = (binHeight / self.maxThreads) | 0;
          const miny = self.workerId * rowsPerThread;

          if (self.workerId === self.maxThreads - 1) {
            const remain = binHeight % self.maxTheads;
            rowsPerThread += remain;
          }
          const maxy = miny + rowsPerThread - 1;

          for (let i = 0; i < faceCount; i++) {
            const ofs = binList[i];

            if (!self.generateRasterizerData(FDataF32, FDataI32, ofs, self.args[0], self.args[1])) continue;

            // Get positions in 8x8 bin space.
            const minxBin = FDataF32[ofs + 47] >> 3;
            let minyBin = FDataF32[ofs + 48] >> 3;
            const maxxBin = FDataF32[ofs + 57] >> 3;
            let maxyBin = FDataF32[ofs + 58] >> 3;

            // Clip to this threads bounds.
            if (maxyBin < miny) continue;
            if (minyBin > maxy) continue;
            if (minyBin < miny) minyBin = miny;
            if (maxyBin > maxy) maxyBin = maxy;

            const pos = ofs * 4;

            const OFDX12 = FDataI32[ofs + 29];
            const OFDX23 = FDataI32[ofs + 30];
            const OFDX31 = FDataI32[ofs + 31];
            const OFDY12 = FDataI32[ofs + 32];
            const OFDY23 = FDataI32[ofs + 33];
            const OFDY31 = FDataI32[ofs + 34];

            let CY1 = FDataI32[ofs + 26];
            let CY2 = FDataI32[ofs + 27];
            let CY3 = FDataI32[ofs + 28];
            const binCYDelta = minyBin - (FDataF32[ofs + 48] >> 3);
            CY1 += OFDX12 * binCYDelta;
            CY2 += OFDX23 * binCYDelta;
            CY3 += OFDX31 * binCYDelta;

            const eo1 = FDataI32[ofs + 35];
            const eo2 = FDataI32[ofs + 36];
            const eo3 = FDataI32[ofs + 37];
            const ei1 = FDataI32[ofs + 38];
            const ei2 = FDataI32[ofs + 39];
            const ei3 = FDataI32[ofs + 40];

            for (let y = minyBin; y <= maxyBin; y++) {
              let filledFlag = false;
              let CX1 = CY1 + eo1;
              let CX2 = CY2 + eo2;
              let CX3 = CY3 + eo3;
              let CX4 = CY1 + ei1;
              let CX5 = CY2 + ei2;
              let CX6 = CY3 + ei3;
              for (let x = minxBin; x <= maxxBin; x++) {
                //empty block
                if ((CX1 | CX2 | CX3) < 0) {
                  if (filledFlag) break;
                }
                //in block
                else if ((CX4 | CX5 | CX6) >= 0) {
                  const ofs = x + y * binWidth;
                  filledFlag = true;
                  bins[ofs * 256 + (binCounts[ofs] & 255)] = pos;
                  binCounts[ofs]++;
                }
                // partial block
                else {
                  const ofs = x + y * binWidth;
                  filledFlag = true;
                  bins[ofs * 256 + (binCounts[ofs] & 255)] = pos + 1; // +1 to flag partial works because we know the pos(ofs) is always even.
                  binCounts[ofs]++;
                }
                CX1 -= OFDY12;
                CX2 -= OFDY23;
                CX3 -= OFDY31;
                CX4 -= OFDY12;
                CX5 -= OFDY23;
                CX6 -= OFDY31;
              }
              CY1 += OFDX12;
              CY2 += OFDX23;
              CY3 += OFDX31;
            }
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      threadRender: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory'] && wasmModules['renderWASM'].funcs) {
          const rtData = pools['appMemory'].memory.buffer;
          const triF32Data = new Float32Array(rtData, 0, rtData.byteLength / 4);
          const triI32Data = new Int32Array(rtData, 0, rtData.byteLength / 4);
          const triU32Data = new Uint32Array(rtData, 0, rtData.byteLength / 4);
          const binCounts = new Uint32Array(rtData, self.args[8], self.args[4]);
          const bins = new Uint32Array(rtData, self.args[11], self.args[12]);
          const occlusionBuffer = new Float32Array(rtData, self.args[13], self.args[14]);

          const binWidth = self.args[2];
          const binCount = self.args[4];
          const screenWidth = self.args[7];

          let u1 = 0,
            v1 = 0,
            dudx = 0,
            dvdx = 0,
            dudy = 0,
            dvdy = 0,
            TL_U = 0,
            TL_V = 0,
            TR_U = 0,
            TR_V = 0,
            BL_U = 0,
            BL_V = 0;
          let dudx_linear = 0,
            dvdx_linear = 0,
            dudy_linear = 0,
            dvdy_linear = 0;
          let uTLLinear = 0,
            vTLLinear = 0,
            uTRLinear = 0,
            vTRLinear = 0,
            uBLLinear = 0,
            vBLLinear = 0;

          let BLOCK = 0;
          for (;;) {
            // Threaded bin startup
            const nextBin = self.workerId + BLOCK * self.maxThreads;
            BLOCK++;
            if (nextBin >= binCount) {
              break;
            }
            const binY = ((nextBin / binWidth) | 0) << 3;
            const binX = nextBin % binWidth << 3;
            //
            const count = binCounts[nextBin];
            const binofs = nextBin * self.args[15];
            for (let j = 0; j < count; j++) {
              // Get the offset of the triangle data, and the flag to indicate if, in respect to this bin, its a full or partial tile.
              const partialFlag = bins[binofs + j] & 1;
              const primOfs = (bins[binofs + j] & ~1) >> 2;
              // Get the triangle's final diffuse color from flat shading.
              const col = triU32Data[primOfs + 13];
              const colA = (col >> 24) & 0xff;

              // Texture Ptr
              const textureWidth = triI32Data[primOfs + 51];
              const textureHeight = triI32Data[primOfs + 52];
              const texturePtr = triI32Data[primOfs + 53] >> 2;

              // Calculate 1/z at the top left corner of the BIN.
              const dzdx = triF32Data[primOfs + 41];
              const dzdy = triF32Data[primOfs + 42];
              const z1 = triF32Data[primOfs + 54]; // the 1/z value of triangle.vertex1
              const x1 = triF32Data[primOfs + 60]; // the 2d screen coordinate of triangle.vertex1
              const y1 = triF32Data[primOfs + 61]; //
              const TL_Z = z1 + dzdx * (binX - x1) + dzdy * (binY - y1);
              const TR_Z = TL_Z + dzdx * 8;
              const BL_Z = TL_Z + dzdy * 8;
              const BR_Z = BL_Z + dzdx * 8;

              //if the closest z of the incoming triangle bin patch is further away than the occ.buffer and it's a full covered tile
              if (colA === 0xff) {
                const minz = Math.min(TL_Z, TR_Z, BL_Z, BR_Z);
                const maxz = Math.max(TL_Z, TR_Z, BL_Z, BR_Z);
                if (maxz <= occlusionBuffer[nextBin]) {
                  continue;
                }
                if (partialFlag === 0) {
                  occlusionBuffer[nextBin] = minz;
                }
              }

              // Calculate perspective correct U,V value at TL, TR and BL.
              if (texturePtr !== 0) {
                dudx = triF32Data[primOfs + 43];
                dudy = triF32Data[primOfs + 44];
                dvdx = triF32Data[primOfs + 45];
                dvdy = triF32Data[primOfs + 46];
                u1 = triF32Data[primOfs + 55];
                v1 = triF32Data[primOfs + 56];
                TL_U = u1 + dudx * (binX - x1) + dudy * (binY - y1);
                TL_V = v1 + dvdx * (binX - x1) + dvdy * (binY - y1);
                TR_U = TL_U + dudx * 8;
                TR_V = TL_V + dvdx * 8;
                BL_U = TL_U + dudy * 8;
                BL_V = TL_V + dvdy * 8;
                // Use the perspective correct values to derive linear values + interpolants.
                uTLLinear = ((TL_U / TL_Z) * 65535 * (textureWidth - 1)) | 0;
                vTLLinear = ((TL_V / TL_Z) * 65535 * (textureHeight - 1)) | 0;
                uTRLinear = ((TR_U / TR_Z) * 65535 * (textureWidth - 1)) | 0;
                vTRLinear = ((TR_V / TR_Z) * 65535 * (textureHeight - 1)) | 0;
                uBLLinear = ((BL_U / BL_Z) * 65535 * (textureWidth - 1)) | 0;
                vBLLinear = ((BL_V / BL_Z) * 65535 * (textureHeight - 1)) | 0;
                dudx_linear = (uTRLinear - uTLLinear) / 8;
                dvdx_linear = (vTRLinear - vTLLinear) / 8;
                dudy_linear = (uBLLinear - uTLLinear) / 8;
                dvdy_linear = (vBLLinear - vTLLinear) / 8;
              }

              // Full tile
              if (partialFlag === 0) {
                if (texturePtr !== 0) {
                  const screenofs = binX + binY * screenWidth;
                  const zofs = nextBin << 6;
                  wasmModules['renderWASM'].funcs['rasterizer_full_texture'](
                    (screenofs << 2) + self.args[5],
                    screenWidth << 2,
                    col,
                    uTLLinear,
                    vTLLinear,
                    dudx_linear,
                    dvdx_linear,
                    dudy_linear,
                    dvdy_linear,
                    texturePtr << 2,
                    textureWidth << 2,
                    (zofs << 2) + self.args[9],
                    TL_Z,
                    dzdx,
                    dzdy
                  );
                } else {
                  const screenofs = binX + binY * screenWidth;
                  const zofs = nextBin << 6;
                  let funcStr = 'rasterizer_full_flat';
                  if (colA !== 0xff) {
                    funcStr = 'rasterizer_full_flat_alpha';
                  }
                  wasmModules['renderWASM'].funcs[funcStr](
                    (screenofs << 2) + self.args[5],
                    (zofs << 2) + self.args[9],
                    screenWidth << 2,
                    TL_Z,
                    col,
                    dzdx,
                    dzdy
                  );
                }
              }
              // Partial tile
              else {
                // Fetch constants from the primitive necessary to check for coverage.
                const FDX12 = triI32Data[primOfs + 20];
                const FDX23 = triI32Data[primOfs + 21];
                const FDX31 = triI32Data[primOfs + 22];
                const FDY12 = triI32Data[primOfs + 23];
                const FDY23 = triI32Data[primOfs + 24];
                const FDY31 = triI32Data[primOfs + 25];

                let CY1 = triI32Data[primOfs + 26];
                let CY2 = triI32Data[primOfs + 27];
                let CY3 = triI32Data[primOfs + 28];
                const minx = triF32Data[primOfs + 47]; // The top-left corner of the triangle bounding box that was originally used to calculate CY1-3.
                const miny = triF32Data[primOfs + 48];
                const dx = binX - minx;
                const dy = binY - miny;
                CY1 = CY1 - dx * FDY12 + dy * FDX12;
                CY2 = CY2 - dx * FDY23 + dy * FDX23;
                CY3 = CY3 - dx * FDY31 + dy * FDX31;
                //
                if (texturePtr !== 0) {
                  const screenofs = binX + binY * screenWidth;
                  const zofs = nextBin << 6;
                  wasmModules['renderWASM'].funcs['rasterizer_partial_texture'](
                    (screenofs << 2) + self.args[5],
                    screenWidth << 2,
                    col,
                    uTLLinear,
                    vTLLinear,
                    dudx_linear,
                    dvdx_linear,
                    dudy_linear,
                    dvdy_linear,
                    texturePtr << 2,
                    textureWidth << 2,
                    (zofs << 2) + self.args[9],
                    TL_Z,
                    dzdx,
                    dzdy,
                    CY1,
                    CY2,
                    CY3,
                    FDY12,
                    FDY23,
                    FDY31,
                    FDX12,
                    FDX23,
                    FDX31
                  );
                } else {
                  const screenofs = binX + binY * screenWidth;
                  const zofs = nextBin << 6;
                  let funcStr = 'rasterizer_partial_flat';
                  if (colA !== 0xff) {
                    funcStr = 'rasterizer_partial_flat_alpha';
                  }
                  wasmModules['renderWASM'].funcs[funcStr](
                    (screenofs << 2) + self.args[5],
                    (zofs << 2) + self.args[9],
                    screenWidth << 2,
                    TL_Z,
                    col,
                    dzdx,
                    dzdy,
                    CY1,
                    CY2,
                    CY3,
                    FDY12,
                    FDY23,
                    FDY31,
                    FDX12,
                    FDX23,
                    FDX31
                  );
                }
              }
            } // end of bin primitive loop.
            binCounts[nextBin] = 0;
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      threadRenderNOZ: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory'] && wasmModules['renderWASM'].funcs) {
          const rtData = pools['appMemory'].memory.buffer;
          if (!self.textureData) {
            self.textureData = new Uint32Array(rtData, 0, rtData.byteLength / 4);
            self.triF32Data = new Float32Array(rtData, 0, rtData.byteLength / 4);
            self.triI32Data = new Int32Array(rtData, 0, rtData.byteLength / 4);
          }
          const triF32Data = self.triF32Data;
          const triI32Data = self.triI32Data;
          const triU32Data = self.textureData;
          const binCounts = new Uint32Array(rtData, self.args[8], self.args[4]);
          const bins = new Uint32Array(rtData, self.args[11], self.args[12]);
          const binWidth = self.args[2];
          const binCount = self.args[4];
          const screenWidth = self.args[7];

          let u1 = 0,
            v1 = 0,
            dudx = 0,
            dvdx = 0,
            dudy = 0,
            dvdy = 0,
            TL_U = 0,
            TL_V = 0,
            TR_U = 0,
            TR_V = 0,
            BL_U = 0,
            BL_V = 0;
          let dudx_linear = 0,
            dvdx_linear = 0,
            dudy_linear = 0,
            dvdy_linear = 0;
          let uTLLinear = 0,
            vTLLinear = 0,
            uTRLinear = 0,
            vTRLinear = 0,
            uBLLinear = 0,
            vBLLinear = 0;

          let BLOCK = 0;
          for (;;) {
            // Threaded bin startup
            const nextBin = self.workerId + BLOCK * self.maxThreads;
            BLOCK++;
            if (nextBin >= binCount) {
              break;
            }
            const i = nextBin;
            const binY = ((nextBin / binWidth) | 0) << 3;
            const binX = nextBin % binWidth << 3;
            //
            const count = binCounts[i];
            const binofs = i * self.args[15];
            for (let j = 0; j < count; j++) {
              // Get the offset of the triangle data, and the flag to indicate if, in respect to this bin, its a full or partial tile.
              const partialFlag = bins[binofs + j] & 1;
              const primOfs = (bins[binofs + j] & ~1) >> 2;
              // Get the triangle's final diffuse color from flat shading.
              const col = triU32Data[primOfs + 13];
              const colR = ((col >> 16) & 0xff) / 255.0;
              const colG = ((col >> 8) & 0xff) / 255.0;
              const colB = (col & 0xff) / 255.0;

              // Calculate 1/z at the top left corner of the BIN.
              const x1 = triF32Data[primOfs + 60]; // the 2d screen coordinate of triangle.vertex1
              const y1 = triF32Data[primOfs + 61]; //
              const dzdx = triF32Data[primOfs + 41];
              const dzdy = triF32Data[primOfs + 42];
              const z1 = triF32Data[primOfs + 54]; // the 1/z value of triangle.vertex1
              const TL_Z = z1 + dzdx * (binX - x1) + dzdy * (binY - y1);
              const TR_Z = TL_Z + dzdx * 8;
              const BL_Z = TL_Z + dzdy * 8;

              // Texture Ptr
              const textureWidth = triI32Data[primOfs + 51];
              const textureHeight = triI32Data[primOfs + 52];
              const texturePtr = triI32Data[primOfs + 53] >> 2;

              // Calculate perspective correct U,V value at TL, TR and BL.
              if (texturePtr !== 0) {
                u1 = triF32Data[primOfs + 55];
                v1 = triF32Data[primOfs + 56];
                dudx = triF32Data[primOfs + 43];
                dvdx = triF32Data[primOfs + 45];
                dudy = triF32Data[primOfs + 44];
                dvdy = triF32Data[primOfs + 46];
                TL_U = u1 + dudx * (binX - x1) + dudy * (binY - y1);
                TL_V = v1 + dvdx * (binX - x1) + dvdy * (binY - y1);
                TR_U = TL_U + dudx * 8;
                TR_V = TL_V + dvdx * 8;
                BL_U = TL_U + dudy * 8;
                BL_V = TL_V + dvdy * 8;
                // Use the perspective correct values to derive linear values + interpolants.
                uTLLinear = ((TL_U / TL_Z) * 65536 * (textureWidth - 1)) | 0;
                vTLLinear = ((TL_V / TL_Z) * 65536 * (textureHeight - 1)) | 0;
                uTRLinear = ((TR_U / TR_Z) * 65536 * (textureWidth - 1)) | 0;
                vTRLinear = ((TR_V / TR_Z) * 65536 * (textureHeight - 1)) | 0;
                uBLLinear = ((BL_U / BL_Z) * 65536 * (textureWidth - 1)) | 0;
                vBLLinear = ((BL_V / BL_Z) * 65536 * (textureHeight - 1)) | 0;
                dudx_linear = (uTRLinear - uTLLinear) >> 3;
                dvdx_linear = (vTRLinear - vTLLinear) >> 3;
                dudy_linear = (uBLLinear - uTLLinear) >> 3;
                dvdy_linear = (vBLLinear - vTLLinear) >> 3;
              }

              // Full tile
              if (partialFlag === 0) {
                if (texturePtr !== 0) {
                  const screenofs = binX + binY * screenWidth;
                  wasmModules['renderWASM'].funcs['rasterizer_full_texture_noz'](
                    (screenofs << 2) + self.args[5],
                    screenWidth << 2,
                    col,
                    uTLLinear,
                    vTLLinear,
                    dudx_linear,
                    dvdx_linear,
                    dudy_linear,
                    dvdy_linear,
                    texturePtr << 2,
                    textureWidth << 2
                  );
                } else {
                  const screenofs = binX + binY * screenWidth;
                  wasmModules['renderWASM'].funcs['rasterizer_full_flat_noz']((screenofs << 2) + self.args[5], screenWidth << 2, col);
                }
              }
              // Partial tile
              else {
                //
                // Fetch constants from the primitive necessary to check for coverage.
                const FDX12 = triI32Data[primOfs + 20];
                const FDX23 = triI32Data[primOfs + 21];
                const FDX31 = triI32Data[primOfs + 22];
                const FDY12 = triI32Data[primOfs + 23];
                const FDY23 = triI32Data[primOfs + 24];
                const FDY31 = triI32Data[primOfs + 25];

                let CY1 = triI32Data[primOfs + 26];
                let CY2 = triI32Data[primOfs + 27];
                let CY3 = triI32Data[primOfs + 28];
                const minx = triF32Data[primOfs + 47]; // The top-left corner of the triangle bounding box that was originally used to calculate CY1-3.
                const miny = triF32Data[primOfs + 48];
                const dx = binX - minx;
                const dy = binY - miny;
                CY1 = CY1 - dx * FDY12 + dy * FDX12;
                CY2 = CY2 - dx * FDY23 + dy * FDX23;
                CY3 = CY3 - dx * FDY31 + dy * FDX31;
                //
                if (texturePtr !== 0) {
                  const screenofs = binX + binY * screenWidth;
                  wasmModules['renderWASM'].funcs['rasterizer_partial_texture_noz'](
                    (screenofs << 2) + self.args[5],
                    screenWidth << 2,
                    col,
                    uTLLinear,
                    vTLLinear,
                    dudx_linear,
                    dvdx_linear,
                    dudy_linear,
                    dvdy_linear,
                    texturePtr << 2,
                    textureWidth << 2,
                    CY1,
                    CY2,
                    CY3,
                    FDY12,
                    FDY23,
                    FDY31,
                    FDX12,
                    FDX23,
                    FDX31
                  );
                } else {
                  const screenofs = binX + binY * screenWidth;
                  wasmModules['renderWASM'].funcs['rasterizer_partial_flat_noz'](
                    (screenofs << 2) + self.args[5],
                    screenWidth << 2,
                    col,
                    CY1,
                    CY2,
                    CY3,
                    FDY12,
                    FDY23,
                    FDY31,
                    FDX12,
                    FDX23,
                    FDX31
                  );
                }
              }
            } // end of bin primitive loop.
            binCounts[i] = 0;
          }
        }
      },
    });
  }

  public buildBins(): void {
    this.binWidth = (this.width / 8) | 0;
    this.binHeight = (this.height / 8) | 0;
    this.binCount = this.binWidth * this.binHeight;

    const buf = this.memory.memory.buffer;
    this.memory.changeVariableSize('binCount', this.binCount);
    this.memory.changeVariableSize('bins', this.binCount * RenderTarget.MAX_PRIMS);
    this.memory.changeVariableSize('occ', this.binCount);

    this.binCounts = new Uint32Array(buf, this.binCountVar.ptr, this.binCountVar.size / 4);
    this.bins = new Uint32Array(buf, this.binsVar.ptr, this.binsVar.size / 4);
    this.occlusionBuffer = new Float32Array(buf, this.occVar.ptr, this.occVar.size / 4);

    for (let i = 0; i < this.binCount; i++) {
      this.binCounts[i] = 0;
      this.occlusionBuffer[i] = 0;
    }
  }

  public bindTexture(tex: Texture): Variable {
    this.textures[tex.name] = this.memory.addVariable(tex.name, VARTYPE.UINT32, tex.normalWidth * (tex.normalHeight + 4));
    const size = tex.normalWidth * tex.normalHeight;
    const textureData = new Uint32Array(
      this.memory.memory.buffer,
      this.textures[tex.name].ptr + tex.normalWidth * 4 * 2,
      this.textures[tex.name].size / 4
    );
    for (let i = 0; i < size; i++) {
      textureData[i] = tex.data![i];
    }
    this.textures[tex.name].ptr += tex.normalWidth * 4 * 2;
    tex.ptr = this.textures[tex.name].ptr;
    return this.textures[tex.name];
  }

  public async applyGamma(): Promise<void> {
    Scheduler.invoke(FuncType.SCRIPT, 'render', 'gammaProcess', [this.width, this.height, this.rasterDataVar.ptr]);
    await Scheduler.waitAllAsync();
  }

  public async applyFog(): Promise<void> {
    Scheduler.invoke(FuncType.SCRIPT, 'render', 'fogProcess', [
      this.width,
      this.height,
      this.rasterDataVar.ptr,
      this.zbufferDataVar.ptr,
      this.rasterDataVar.size / 4,
      this.zbufferDataVar.size / 4,
      this.binWidth,
      this.scratchVar.ptr,
    ]);
    await Scheduler.waitAllAsync();
  }

  public async processBins(): Promise<void> {
    Scheduler.invoke(FuncType.SCRIPT, 'render', 'binProcess', [
      this.width, //0
      this.height, // 1
      this.toBinCount, // 2
      this.binsVar.ptr, // 3
      this.binsVar.size / 4, // 4
      this.binWidth, // 5
      this.binHeight, // 6
      this.tobinVar.ptr, // 7
      this.tobinVar.size / 4, // 8
      this.binCountVar.ptr, // 9
      this.binCountVar.size / 4, // 10
    ]);
    await Scheduler.waitAllAsync();
  }

  public async applyRasterEffect(): Promise<void> {
    Scheduler.invoke(FuncType.SCRIPT, 'render', 'rasterProcess', [this.width, this.height, this.rasterDataVar.ptr, this.rasterDataVar.size / 4]);
    await Scheduler.waitAllAsync();
  }

  public async flushBinsThreaded(doRasterize: boolean): Promise<void> {
    if (doRasterize) {
      let funcName = 'threadRender';
      if (!this.zbuffering) {
        funcName = 'threadRenderNOZ';
      }
      Scheduler.invoke(FuncType.SCRIPT, 'render', funcName, [
        0, //0
        1, //1
        this.binWidth, //2
        this.binHeight, //3
        this.binCount, //4
        this.rasterDataVar.ptr, //5
        this.width * this.height, //6
        this.width, //7
        this.binCountVar.ptr, //8
        this.zbufferDataVar.ptr, //9
        this.zbufferDataVar.size / 4, //10
        this.binsVar.ptr, // 11
        this.binsVar.size / 4, // 12
        this.occVar.ptr, // 13
        this.occVar.size / 4, // 14
        RenderTarget.MAX_PRIMS, // 15
      ]);
      await Scheduler.waitAllAsync();
    }
    // If we're not rasterizing the bins, just reset the binCounts.
    else {
      for (let i = 0; i < this.binCounts.length; i++) {
        this.binCounts[i] = 0;
      }
    }
  }

  public async generateRasterizerData(geoBuffer: GeometryBuffer): Promise<void> {
    const FDataF32 = geoBuffer.facesDataF32;
    const FDataI32 = geoBuffer.facesDataI32;

    for (let i = 0; i < geoBuffer.faceCount + geoBuffer.auxCount; i++) {
      const ofs = i * FACE_SIZE;
      if (FDataI32[ofs + 12] === 0) {
        // || FDataI32[ofs + 96] === 3) {
        continue; // No need to generate rasterization data, triangle already invisible.
      }

      const x1 = FDataF32[ofs + 60];
      const y1 = FDataF32[ofs + 61];
      const x2 = FDataF32[ofs + 62];
      const y2 = FDataF32[ofs + 63];
      const x3 = FDataF32[ofs + 64];
      const y3 = FDataF32[ofs + 65];
      let minx = Math.min(x1, x2, x3);
      let miny = Math.min(y1, y2, y3);
      let maxx = Math.max(x1, x2, x3);
      let maxy = Math.max(y1, y2, y3);

      // Clip to canvas size.
      minx = minx < 0 ? 0 : minx;
      miny = miny < 0 ? 0 : miny;
      maxx = maxx >= this.width - 1 ? this.width - 1 : maxx;
      maxy = maxy >= this.height - 1 ? this.height - 1 : maxy;

      // Triangle is zero width/height or totally culled.
      const dx = maxx - minx;
      const dy = maxy - miny;
      if (dx <= 0 || dy <= 0) {
        FDataI32[ofs + 12] = 0;
        continue;
      }

      // Get positions in 8x8 bin space.
      minx = (minx >> 3) << 3;
      miny = (miny >> 3) << 3;
      FDataF32[ofs + 47] = minx;
      FDataF32[ofs + 48] = miny;
      FDataF32[ofs + 57] = (maxx >> 3) << 3;
      FDataF32[ofs + 58] = (maxy >> 3) << 3;

      // Convert coordinates to 28.4 fixed point.
      const X1 = (x1 * 16.0) | 0;
      const Y1 = (y1 * 16.0) | 0;
      const X2 = (x2 * 16.0) | 0;
      const Y2 = (y2 * 16.0) | 0;
      const X3 = (x3 * 16.0) | 0;
      const Y3 = (y3 * 16.0) | 0;

      // Calcluate edge deltas.
      const DX12 = X2 - X1;
      const DY12 = Y2 - Y1;
      const DX23 = X3 - X2;
      const DY23 = Y3 - Y2;
      const DX31 = X1 - X3;
      const DY31 = Y1 - Y3;

      const FDX12 = DX12 << 4;
      const FDX23 = DX23 << 4;
      const FDX31 = DX31 << 4;
      const FDY12 = DY12 << 4;
      const FDY23 = DY23 << 4;
      const FDY31 = DY31 << 4;

      FDataI32[ofs + 20] = FDX12;
      FDataI32[ofs + 21] = FDX23;
      FDataI32[ofs + 22] = FDX31;
      FDataI32[ofs + 23] = FDY12;
      FDataI32[ofs + 24] = FDY23;
      FDataI32[ofs + 25] = FDY31;

      // Half-edge constants
      minx = minx << 4;
      miny = miny << 4;
      let CY1 = DX12 * (miny - Y1) - DY12 * (minx - X1); // CY1
      let CY2 = DX23 * (miny - Y2) - DY23 * (minx - X2); // CY2
      let CY3 = DX31 * (miny - Y3) - DY31 * (minx - X3); // CY3

      // Correct for fill convention
      if (DY12 < 0 || (DY12 === 0 && DX12 > 0)) CY1++;
      if (DY23 < 0 || (DY23 === 0 && DX23 > 0)) CY2++;
      if (DY31 < 0 || (DY31 === 0 && DX31 > 0)) CY3++;
      FDataI32[ofs + 26] = CY1;
      FDataI32[ofs + 27] = CY2;
      FDataI32[ofs + 28] = CY3;

      FDataI32[ofs + 29] = FDX12 << 3; //OFDX12
      FDataI32[ofs + 30] = FDX23 << 3; //OFDX23
      FDataI32[ofs + 31] = FDX31 << 3; //OFDX31
      FDataI32[ofs + 32] = FDY12 << 3; //OFDY12
      FDataI32[ofs + 33] = FDY23 << 3; //OFDY23
      FDataI32[ofs + 34] = FDY31 << 3; //OFDY31

      let eo1 = 0;
      let eo2 = 0;
      let eo3 = 0;
      if (FDY12 < 0) eo1 -= FDY12 << 3; //meaning y1 is above y2
      if (FDX12 > 0) eo1 += FDX12 << 3; //meaning x1 is right of x2
      if (FDY23 < 0) eo2 -= FDY23 << 3; //meaning y2 is above y3
      if (FDX23 > 0) eo2 += FDX23 << 3; //meaning x2 is right of x3
      if (FDY31 < 0) eo3 -= FDY31 << 3; //meaning y3 is above y1
      if (FDX31 > 0) eo3 += FDX31 << 3; //meaning x3 is right of x1
      FDataI32[ofs + 35] = eo1;
      FDataI32[ofs + 36] = eo2;
      FDataI32[ofs + 37] = eo3;

      //these are the offsets fo the bottom-right block corner
      FDataI32[ofs + 38] = (DX12 << 7) - (DY12 << 7) - eo1; //block size is 8 ==> shl 3 + 4
      FDataI32[ofs + 39] = (DX23 << 7) - (DY23 << 7) - eo2; //ei1-ei3
      FDataI32[ofs + 40] = (DX31 << 7) - (DY31 << 7) - eo3;

      // Calculate interpolation values at minx,miny
      // Plane equation is: Ax + By + Cz + D = 0
      // We treat the 2d triangle as x/y and use z to represent the value we wish to interpolate
      // from this we derive:
      // z = -A/C * x - B/C * y - D
      // you can see that for every step of x/y the deltas are -A/C and -B/C
      // (A,B,C) is actual the normal of the plane.
      // C is constant regardless of interpolant.
      const C = 1.0 / ((x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1)); // C is constant for all interpolants.

      const z1 = 1.0 / FDataF32[ofs + 66 + 2];
      const z2 = 1.0 / FDataF32[ofs + 70 + 2];
      const z3 = 1.0 / FDataF32[ofs + 74 + 2];
      let A = (z3 - z1) * (y2 - y1) - (z2 - z1) * (y3 - y1);
      let B = (x3 - x1) * (z2 - z1) - (x2 - x1) * (z3 - z1);
      FDataF32[ofs + 41] = -A * C; //dzdx
      FDataF32[ofs + 42] = -B * C; //dzdy

      const u1 = FDataF32[ofs + 90 + 0] * z1;
      const v1 = FDataF32[ofs + 90 + 1] * z1;
      const u2 = FDataF32[ofs + 92 + 0] * z2;
      const v2 = FDataF32[ofs + 92 + 1] * z2;
      const u3 = FDataF32[ofs + 94 + 0] * z3;
      const v3 = FDataF32[ofs + 94 + 1] * z3;
      A = (u3 - u1) * (y2 - y1) - (u2 - u1) * (y3 - y1);
      B = (x3 - x1) * (u2 - u1) - (x2 - x1) * (u3 - u1);
      FDataF32[ofs + 43] = -A * C; //dudx
      FDataF32[ofs + 44] = -B * C; //dudy

      A = (v3 - v1) * (y2 - y1) - (v2 - v1) * (y3 - y1);
      B = (x3 - x1) * (v2 - v1) - (x2 - x1) * (v3 - v1);
      FDataF32[ofs + 45] = -A * C; //dvdx
      FDataF32[ofs + 46] = -B * C; //dvdy

      FDataF32[ofs + 54] = z1;
      FDataF32[ofs + 55] = FDataF32[ofs + 90 + 0] * z1; // U/z and V/z
      FDataF32[ofs + 56] = FDataF32[ofs + 90 + 1] * z1;
    }
  }

  public binTriangle(geoBuffer: GeometryBuffer, faceIdx: number): void {
    const base = geoBuffer.facesVar.ptr / 4;
    const ofs = (base | 0) + faceIdx * FACE_SIZE;
    this.binList[this.toBinCount++] = ofs;
  }

  public binClippedTriangle(geoBuffer: GeometryBuffer, faceIdx: number): void {
    const FDataI32 = geoBuffer.facesDataI32;
    const ofs = faceIdx * FACE_SIZE;
    const FDataF32 = geoBuffer.facesDataF32;
    const pos = geoBuffer.facesVar.ptr + faceIdx * FACE_SIZE * 4;

    const bins = this.bins;
    const binCounts = this.binCounts;
    const binWidth = this.binWidth;

    let CY1 = FDataI32[ofs + 26];
    let CY2 = FDataI32[ofs + 27];
    let CY3 = FDataI32[ofs + 28];

    const OFDX12 = FDataI32[ofs + 29];
    const OFDX23 = FDataI32[ofs + 30];
    const OFDX31 = FDataI32[ofs + 31];
    const OFDY12 = FDataI32[ofs + 32];
    const OFDY23 = FDataI32[ofs + 33];
    const OFDY31 = FDataI32[ofs + 34];
    const eo1 = FDataI32[ofs + 35];
    const eo2 = FDataI32[ofs + 36];
    const eo3 = FDataI32[ofs + 37];
    const ei1 = FDataI32[ofs + 38];
    const ei2 = FDataI32[ofs + 39];
    const ei3 = FDataI32[ofs + 40];

    // Get positions in 8x8 bin space.
    const minxBin = FDataF32[ofs + 47] >> 3;
    const minyBin = FDataF32[ofs + 48] >> 3;
    const maxxBin = FDataF32[ofs + 57] >> 3;
    const maxyBin = FDataF32[ofs + 58] >> 3;

    for (let y = minyBin; y <= maxyBin; ++y) {
      let filledFlag = false;
      let CX1 = CY1 + eo1;
      let CX2 = CY2 + eo2;
      let CX3 = CY3 + eo3;
      let CX4 = CY1 + ei1;
      let CX5 = CY2 + ei2;
      let CX6 = CY3 + ei3;
      for (let x = minxBin; x <= maxxBin; ++x) {
        //empty block
        if ((CX1 | CX2 | CX3) < 0) {
          if (filledFlag) break;
        }
        //in block
        else if ((CX4 | CX5 | CX6) >= 0) {
          const ofs = x + y * binWidth;
          filledFlag = true;
          bins[ofs * RenderTarget.MAX_PRIMS + (binCounts[ofs] & (RenderTarget.MAX_PRIMS - 1))] = pos;
          binCounts[ofs]++;
        }
        // partial block
        else {
          const ofs = x + y * binWidth;
          filledFlag = true;
          bins[ofs * RenderTarget.MAX_PRIMS + (binCounts[ofs] & (RenderTarget.MAX_PRIMS - 1))] = pos + 1; // +1 to flag partial works because we know the pos(ofs) is always even.
          binCounts[ofs]++;
        }
        CX1 -= OFDY12;
        CX2 -= OFDY23;
        CX3 -= OFDY31;
        CX4 -= OFDY12;
        CX5 -= OFDY23;
        CX6 -= OFDY31;
      }
      CY1 += OFDX12;
      CY2 += OFDX23;
      CY3 += OFDX31;
    }
  }

  public renderBinDensity(scale: number): void {
    let binOfs = 0;
    for (let y = 0; y < this.height; y += 8) {
      for (let x = 0; x < this.width; x += 8) {
        const cnt = this.binCounts[binOfs];
        const col = 0xff000000 | Math.min(cnt * scale, 255);
        for (let iy = 0; iy < 8; iy++) {
          for (let ix = 0; ix < 8; ix++) {
            const screenOfs = x + ix + (y + iy) * this.width;
            this.rasterData[screenOfs] = col;
          }
        }
        binOfs++;
      }
    }
  }

  public resize(width: number, height: number): void {
    this.newWidth = width.roundUp(8);
    this.newHeight = height.roundUp(8);
    this.destHeight = height;
    this.needResize = true;
  }

  public async clear(onlyZbuffer: boolean): Promise<void> {
    // This function assumes the buffers are fixed in position, so even if we shrink the "used" sizes, we still
    // need to account for the full buffer sizes to clear across multiple buffers in a single pass, hence using the initialClearSize.
    if (onlyZbuffer) {
      Scheduler.invoke(FuncType.SCRIPT, 'render', 'clearBuffers', [this.zbufferDataVar.ptr, this.initialClearSize - this.rasterDataVar.size]);
    } else {
      Scheduler.invoke(FuncType.SCRIPT, 'render', 'clearBuffers', [this.rasterDataVar.ptr, this.initialClearSize]);
    }
    await Scheduler.waitAllAsync();
  }

  public reset(): void {
    // We handle the resize "event" when it arrives, but defer the actual resizing to here.
    // We know clear is synchronous, single-threaded and will happen after any workers may
    // have accessed the render target buffers.
    if (this.needResize) {
      this.width = this.newWidth;
      this.height = this.newHeight;
      this.memory.changeVariableSize('RenderData', this.width * this.height); // NB: The initial buffer is always the maximum size,
      this.memory.changeVariableSize('ZData', this.width * this.height); // so that we don't have to defrag or move other vars on the heap
      const buf = this.memory.memory.buffer;
      this.rasterData = new Uint32Array(buf, this.rasterDataVar.ptr, this.width * this.height); //this.rasterDataVar.size / 4);
      this.zbufferData = new Float32Array(buf, this.zbufferDataVar.ptr, this.zbufferDataVar.size / 4);
      this.buildBins();
      this.needResize = false;
    }
    this.toBinCount = 0;
    this.occluded = 0;
    this.triangleCount = 0;
    this.signalData[0] = 0; // Set atomically incremented pointer back to 0.
  }

  public transferToCanvas(c: Canvas): void {
    if (this.width === c.width) {
      try {
        c.pixelData!.set(this.rasterData, 0);
      } catch (err) {
        console.log(err);
        // we expect a single failure here on a resize, as the canvas buffer size changes
        // before the rasteru82 buffer is resized to match.
      }
      return;
    }
  }
}
