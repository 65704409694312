import { EnjinImage } from './image';

export class Texture {
  public image?: EnjinImage;
  public normalWidth = 0;
  public normalHeight = 0;
  public mipCount = 0;
  public data?: Uint32Array;
  public ptr = 0;

  constructor(public name: string) {}

  public static async fromURL(name: string, url: string, degamma: boolean) {
    const texture = new Texture(name);
    console.log(`Loading texture ${url} into [${name}]...`);
    texture.image = await EnjinImage.fromURL(url);
    texture.normalWidth = texture.image.width;
    texture.normalHeight = texture.image.height;
    texture.mipCount = 0;
    texture.data = texture.image.data!;
    if (degamma) {
      for (let i = 0; i < texture.data.length; i++) {
        const texel = texture.data[i];
        const a = (texel >> 24) & 0xff;
        let r = ((texel >> 16) & 0xff) * (1.0 / 255.0);
        let g = ((texel >> 8) & 0xff) * (1.0 / 255.0);
        let b = ((texel >> 0) & 0xff) * (1.0 / 255.0);
        r = (Math.pow(r, 2.2) * 255).clamp(0, 255) | 0;
        g = (Math.pow(g, 2.2) * 255).clamp(0, 255) | 0;
        b = (Math.pow(b, 2.2) * 255).clamp(0, 255) | 0;
        texture.data[i] = (a << 24) | (r << 16) | (g << 8) | b;
      }
    }
    return texture;
  }
}
