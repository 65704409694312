import { Texture } from './texture';
import { vec4 } from './types';

export class Material {
  public diffuseColor: vec4 = new vec4(0, 0, 0, 0);
  public specularColor: vec4 = new vec4(0, 0, 0, 0);
  public shininess = 0.0;
  public texture?: Texture | null;
  //public normalMap;
  //public roughness;
  constructor(public name: string) {}

  public setTexture(t: Texture): void {
    this.texture = t;
  }
}
