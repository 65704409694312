import { boundingSphere } from './boundingSphere';
import { Face } from './face';
import { Light } from './light';
import { extractPlanesToArray, MatrixMath, transform } from './math3d';
import { mat4, vec2, vec4 } from './types';
import { RenderTarget } from './renderTarget';
import { plane } from './plane';
import { Material } from './material';
import { FACE_SIZE, GeometryBuffer } from './geometrybuffer';
import { Camera } from './camera';

export class Mesh {
  public geometryBuffer: GeometryBuffer;
  public modelToWorldMatrix: mat4;
  public inverseModelMatrix: mat4;
  public bounds: boundingSphere | null = null;
  public culled = false;
  public position: vec4;
  public orientation: vec4;
  public scale: vec4;
  private planes: plane[] = [];
  public defaultMaterial: Material;

  constructor(public url: string, public name: string) {
    this.geometryBuffer = new GeometryBuffer(`mesh-${name}`);
    this.position = new vec4(0, 0, 0, 1);
    this.orientation = new vec4(0, 0, 0, 0);
    this.modelToWorldMatrix = mat4.makeIdentity();
    this.inverseModelMatrix = mat4.makeIdentity();
    this.defaultMaterial = new Material('default material');
    this.defaultMaterial.diffuseColor = new vec4(1, 1, 1, 1);
    this.scale = new vec4(1, 1, 1, 1);
    for (let i = 0; i < 6; i++) {
      this.planes[i] = new plane(new vec4(0, 0, 0, 0), 0);
    }
    this.geometryBuffer.name = this.name;
  }

  public setPosition(x: number, y: number, z: number): void {
    this.position.x = x;
    this.position.y = y;
    this.position.z = z;
    this.buildMatrix();
  }

  public setScale(x: number, y: number, z: number): void {
    this.scale.x = x;
    this.scale.y = y;
    this.scale.z = z;
    this.buildMatrix();
    this.bounds!.radius *= x; // TODO: hack, this assumes x/y/z are ==
  }

  public setOrientation(x: number, y: number, z: number): void {
    this.orientation.x = x;
    this.orientation.y = y;
    this.orientation.z = z;
    this.buildMatrix();
  }

  public buildMatrix() {
    const translationMatrix = mat4.makeTranslation(this.position.x, this.position.y, this.position.z);
    const rotationmatrix = MatrixMath.Multiply(
      MatrixMath.Multiply(mat4.makeRotationX(this.orientation.x), mat4.makeRotationY(this.orientation.y)),
      mat4.makeRotationZ(this.orientation.z)
    );
    const scalematrix = mat4.makeScale(this.scale.x, this.scale.y, this.scale.z);
    this.modelToWorldMatrix = MatrixMath.Multiply(scalematrix, MatrixMath.Multiply(rotationmatrix, translationMatrix));
    MatrixMath.Inverse2(this.modelToWorldMatrix, this.inverseModelMatrix);
  }

  public static async loadOBJ(url: string, material: Material, name: string): Promise<Mesh> {
    const m = new Mesh(url, name);
    m.defaultMaterial = material;
    const response = await fetch(url);
    const textData = await response.text();
    const lines: string[] = textData.split('\n');

    for (let i = 0; i < lines.length; i++) {
      const elements = lines[i].split(' ');
      if (elements[0] === 'v') {
        m.geometryBuffer.addVertex(new vec4(+elements[1], +elements[2], +elements[3], 1));
      } else if (elements[0] === 'f') {
        const part1 = elements[1].split('/');
        const part2 = elements[2].split('/');
        const part3 = elements[3].split('/');
        const f = new Face(
          [+part3[0] - 1, +part2[0] - 1, +part1[0] - 1], // vertex indices
          [+part3[2] - 1, +part2[2] - 1, +part1[2] - 1], // normal indices
          [+part3[1] - 1, +part2[1] - 1, +part1[1] - 1], // texture uv indices
          m.geometryBuffer
        );
        m.geometryBuffer.addFace(f, m.defaultMaterial);
      } else if (elements[0] === 'vn') {
        m.geometryBuffer.addNormal(new vec4(+elements[1], +elements[2], +elements[3], 0));
      } else if (elements[0] === 'vt') {
        const uv = new vec2(+elements[1], 1.0 - +elements[2]);
        uv.x *= 0.998;
        uv.y *= 0.998;
        uv.x = Math.min(uv.x, 1);
        uv.y = Math.min(uv.y, 1);
        m.geometryBuffer.addUV(uv);
      }
    }

    // Build the normals once we know all faces/vertices are loaded.
    for (let i = 0; i < m.geometryBuffer.faces.length; i++) {
      m.geometryBuffer.faces[i].makeNormal();
    }

    m.bounds = boundingSphere.createFromVertices_Ritter(m.geometryBuffer.vertices);
    return m;
  }

  public static async loadJSON(url: string, material: Material, name: string): Promise<Mesh> {
    const m = new Mesh(url, name);
    m.defaultMaterial = material;
    const response = await fetch(url);
    const jsonData = await response.json();

    const vertexCount = jsonData.vertices[0].values.length / 3;
    const faceCount = jsonData.connectivity[0].indices.length / 3;

    if (jsonData.vertices[0].name !== 'position_buffer') {
      throw new Error('JSON 3d mesh first entry in vertices array is not the position buffer!');
    }
    if (jsonData.vertices[0].size !== 3) {
      throw new Error('JSON 3d mesh position buffer data is not size 3 elements!');
    }
    if (jsonData.vertices[0].type !== 'float32') {
      throw new Error('JSON 3d mesh position buffer data not float32!');
    }

    if (jsonData.vertices[1].name !== 'normal_buffer') {
      throw new Error('JSON 3d mesh first entry in vertices array is not the position buffer!');
    }
    if (jsonData.vertices[1].size !== 3) {
      throw new Error('JSON 3d mesh position buffer data is not size 3 elements!');
    }
    if (jsonData.vertices[1].type !== 'float32') {
      throw new Error('JSON 3d mesh position buffer data not float32!');
    }
    if (jsonData.vertices[2].name !== 'texcoord_buffer') {
      throw new Error('JSON 3d mesh 3rd entry in vertices array is not the texcoord buffer!');
    }
    if (jsonData.vertices[2].size !== 2) {
      throw new Error('JSON 3d mesh texcoord buffer data is not size 2 elements!');
    }

    if (
      jsonData.connectivity[0].mode !== 'triangles_list' ||
      jsonData.connectivity[0].indexed !== true ||
      jsonData.connectivity[0].indexType !== 'uint32'
    ) {
      throw new Error('JSON 3d mesh triangle list invalid!');
    }

    let idx = 0;
    let uvidx = 0;
    for (let i = 0; i < vertexCount; i++) {
      const vert = new vec4(jsonData.vertices[0].values[idx + 0], jsonData.vertices[0].values[idx + 1], jsonData.vertices[0].values[idx + 2], 1.0);
      m.geometryBuffer.addVertex(vert);
      const normal = new vec4(0, 0, 0, 0);
      normal.x = jsonData.vertices[1].values[idx + 0];
      normal.y = jsonData.vertices[1].values[idx + 1];
      normal.z = jsonData.vertices[1].values[idx + 2];
      normal.w = 0.0;
      normal.normalize();
      m.geometryBuffer.addNormal(normal);
      if (jsonData.vertices[2]) {
        const uv = new vec2(0, 0);
        uv.x = jsonData.vertices[2].values[uvidx + 0] / 64;
        uv.y = jsonData.vertices[2].values[uvidx + 1] / 64;
        m.geometryBuffer.addUV(uv);
      }
      idx += 3;
      uvidx += 2;
    }

    idx = 0;
    const tris = jsonData.connectivity[0].indices;
    for (let i = 0; i < faceCount; i++) {
      const f = new Face([tris[idx + 2], tris[idx + 1], tris[idx + 0]], [tris[idx + 2], tris[idx + 1], tris[idx + 0]], [0, 1, 2], m.geometryBuffer);
      f.makeNormal();
      m.geometryBuffer.addFace(f, m.defaultMaterial);
      idx += 3;
    }
    m.bounds = boundingSphere.createFromVertices_Ritter(m.geometryBuffer.vertices);
    return m;
  }

  public centerAndScale(scale: number): void {
    // Find barycenter
    const bary = new vec4(0, 0, 0, 1);
    for (let i = 0; i < this.geometryBuffer.vertices.length; i++) {
      bary.x += this.geometryBuffer.vertices[i].x;
      bary.y += this.geometryBuffer.vertices[i].y;
      bary.z += this.geometryBuffer.vertices[i].z;
    }
    bary.x /= this.geometryBuffer.vertices.length;
    bary.y /= this.geometryBuffer.vertices.length;
    bary.z /= this.geometryBuffer.vertices.length;
    for (let i = 0; i < this.geometryBuffer.vertices.length; i++) {
      this.geometryBuffer.vertices[i].x -= bary.x;
      this.geometryBuffer.vertices[i].y -= bary.y;
      this.geometryBuffer.vertices[i].z -= bary.z;
    }
    for (let i = 0; i < this.geometryBuffer.vertices.length; i++) {
      this.geometryBuffer.vertices[i].x *= scale;
      this.geometryBuffer.vertices[i].y *= scale;
      this.geometryBuffer.vertices[i].z *= scale;
    }
    this.bounds = boundingSphere.createFromVertices(this.geometryBuffer.vertices, new vec4(0, 0, 0, 1));
  }

  public scaleGeometry(scale: number): void {
    for (let i = 0; i < this.geometryBuffer.vertices.length; i++) {
      this.geometryBuffer.vertices[i].x *= scale;
      this.geometryBuffer.vertices[i].y *= scale;
      this.geometryBuffer.vertices[i].z *= scale;
    }
    this.bounds = boundingSphere.createFromVertices(this.geometryBuffer.vertices, new vec4(0, 0, 0, 1));
  }

  public setAllFacesVisible(): void {
    for (let i = 0; i < this.geometryBuffer.faceCount; i++) {
      this.geometryBuffer.facesDataI32[i * FACE_SIZE + 12] = 1;
    }
  }

  public static async makeCube(name: string): Promise<Mesh> {
    const m = new Mesh('none', name);
    const mat = new Material('temp');

    m.geometryBuffer.addUV(new vec2(0.001, 0.001));
    m.geometryBuffer.addUV(new vec2(0.999, 0.001));
    m.geometryBuffer.addUV(new vec2(0.999, 0.999));
    m.geometryBuffer.addUV(new vec2(0.001, 0.999));

    m.geometryBuffer.addNormal(new vec4(0, 0, -1, 0)); // front
    m.geometryBuffer.addNormal(new vec4(1, 0, 0, 0)); // right
    m.geometryBuffer.addNormal(new vec4(0, 0, 1, 0)); // back
    m.geometryBuffer.addNormal(new vec4(-1, 0, 0, 0)); // left
    m.geometryBuffer.addNormal(new vec4(0, -1, 0, 0)); // top
    m.geometryBuffer.addNormal(new vec4(0, 1, 0, 0)); // bottom

    m.geometryBuffer.addVertex(new vec4(-1.0, -1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, -1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(-1.0, 1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, 1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(-1.0, -1.0, 1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, -1.0, 1.0, 1));
    m.geometryBuffer.addVertex(new vec4(-1.0, 1.0, 1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, 1.0, 1.0, 1));

    // Vertices are ordered CW, looking from the outside
    const g = m.geometryBuffer;
    g.addFace(new Face([0, 1, 3], [0, 0, 0], [0, 1, 2], g), mat); // front
    g.addFace(new Face([0, 3, 2], [0, 0, 0], [0, 2, 3], g), mat);
    g.addFace(new Face([1, 5, 7], [1, 1, 1], [0, 1, 2], g), mat); // right
    g.addFace(new Face([1, 7, 3], [1, 1, 1], [0, 2, 3], g), mat);
    g.addFace(new Face([5, 4, 6], [2, 2, 2], [0, 1, 2], g), mat); // back
    g.addFace(new Face([5, 6, 7], [2, 2, 2], [0, 2, 3], g), mat);
    g.addFace(new Face([4, 0, 2], [3, 3, 3], [0, 1, 2], g), mat); // left
    g.addFace(new Face([4, 2, 6], [3, 3, 3], [0, 2, 3], g), mat);
    g.addFace(new Face([4, 5, 1], [4, 4, 4], [0, 1, 2], g), mat); // top
    g.addFace(new Face([4, 1, 0], [4, 4, 4], [0, 2, 3], g), mat);
    g.addFace(new Face([2, 3, 7], [5, 5, 5], [0, 1, 2], g), mat); // bottom
    g.addFace(new Face([2, 7, 6], [5, 5, 5], [0, 2, 3], g), mat);

    // Build the normals once we know all faces/vertices are loaded.
    for (let i = 0; i < m.geometryBuffer.faces.length; i++) {
      m.geometryBuffer.faces[i].makeNormal();
    }

    m.bounds = boundingSphere.createFromVertices_Ritter(m.geometryBuffer.vertices);
    return m;
  }

  public static async makeInsideCube(name: string): Promise<Mesh> {
    const m = new Mesh('none', name);
    const mat = new Material('temp');

    m.geometryBuffer.addUV(new vec2(0.999, 0.0));
    m.geometryBuffer.addUV(new vec2(0.999, 0.999));
    m.geometryBuffer.addUV(new vec2(0.0, 0.999));
    m.geometryBuffer.addUV(new vec2(0.0, 0.0));

    m.geometryBuffer.addNormal(new vec4(0, 0, 1, 0)); // front
    m.geometryBuffer.addNormal(new vec4(-1, 0, 0, 0)); // right
    m.geometryBuffer.addNormal(new vec4(0, 0, -1, 0)); // back
    m.geometryBuffer.addNormal(new vec4(1, 0, 0, 0)); // left
    m.geometryBuffer.addNormal(new vec4(0, 1, 0, 0)); // top
    m.geometryBuffer.addNormal(new vec4(0, -1, 0, 0)); // bottom

    m.geometryBuffer.addVertex(new vec4(-1.0, -1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, -1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(-1.0, 1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, 1.0, -1.0, 1));
    m.geometryBuffer.addVertex(new vec4(-1.0, -1.0, 1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, -1.0, 1.0, 1));
    m.geometryBuffer.addVertex(new vec4(-1.0, 1.0, 1.0, 1));
    m.geometryBuffer.addVertex(new vec4(1.0, 1.0, 1.0, 1));

    // Vertices are ordered CW, looking from the inside
    const g = m.geometryBuffer;
    g.addFace(new Face([0, 2, 3], [0, 0, 0], [0, 1, 2], g), mat); // front
    g.addFace(new Face([0, 3, 1], [0, 0, 0], [0, 2, 3], g), mat);
    g.addFace(new Face([1, 3, 7], [1, 1, 1], [0, 1, 2], g), mat); // right
    g.addFace(new Face([1, 7, 5], [1, 1, 1], [0, 2, 3], g), mat);
    g.addFace(new Face([5, 7, 6], [2, 2, 2], [0, 1, 2], g), mat); // back
    g.addFace(new Face([5, 6, 4], [2, 2, 2], [0, 2, 3], g), mat);
    g.addFace(new Face([4, 6, 2], [3, 3, 3], [0, 1, 2], g), mat); // left
    g.addFace(new Face([4, 2, 0], [3, 3, 3], [0, 2, 3], g), mat);
    g.addFace(new Face([1, 5, 4], [4, 4, 4], [0, 1, 2], g), mat); // top
    g.addFace(new Face([1, 4, 0], [4, 4, 4], [0, 2, 3], g), mat);
    g.addFace(new Face([7, 3, 2], [5, 5, 5], [0, 1, 2], g), mat); // bottom
    g.addFace(new Face([7, 2, 6], [5, 5, 5], [0, 2, 3], g), mat);

    // Build the normals once we know all faces/vertices are loaded.
    for (let i = 0; i < m.geometryBuffer.faces.length; i++) {
      m.geometryBuffer.faces[i].makeNormal();
    }

    m.bounds = boundingSphere.createFromVertices_Ritter(m.geometryBuffer.vertices);
    return m;
  }

  public transform(
    viewMatrix: mat4,
    persMatrix: mat4,
    lights: Light[],
    ambient: vec4,
    viewWidth: number,
    viewHeight: number,
    camera: Camera,
    skipFarPlane: boolean
  ): void {
    //
    // Finalize transform matrices.
    const modelToCameraMatrix = MatrixMath.Multiply(this.modelToWorldMatrix, viewMatrix);
    const mvMatrix = MatrixMath.Multiply(modelToCameraMatrix, persMatrix);
    //
    // Extract the frustum planes in object space to cull the object against.
    this.culled = false;
    const planes = this.planes;
    extractPlanesToArray(mvMatrix, planes);
    for (let i = 0; i < planes.length; i++) {
      const dist = planes[i].signedDistanceToPoint(this.bounds!.position);
      if (dist < -this.bounds!.radius) {
        this.culled = true;
        return;
      }
    }
    //
    // Transform the bounding spheres position into world space for collision tests.
    transform(this.bounds!.position, this.bounds!.positionT, this.modelToWorldMatrix);
    //
    // Transform all the vertices into homogenous clip space.
    //this.geometryBuffer.transformVertices(mvMatrix, 0, this.geometryBuffer.vertexCount);
    this.geometryBuffer.setMatrix(mvMatrix);
    this.geometryBuffer.transformVerticesWASM(0, this.geometryBuffer.vertexCount);
    //
    // Perform lighting in object space pre clipping
    if (this.geometryBuffer.hasLight) {
      for (let i = 0; i < lights.length; i++) {
        transform(lights[i].positiont, lights[i].positionObjSpace, this.inverseModelMatrix);
      }
    }
    //
    // Perform Object Space Back face Culling and Triangle Culling.
    // -> lighting, and 3d clipping
    const halfWidth = viewWidth / 2;
    const halfHeight = viewHeight / 2;
    this.geometryBuffer.resetClipping();
    const faceData = this.geometryBuffer.facesDataI32;
    transform(camera.position, camera.positionObjSpace, this.inverseModelMatrix);
    for (let i = 0; i < this.geometryBuffer.faceCount; i++) {
      if (this.geometryBuffer.triangleOut(i, skipFarPlane)) {
        faceData[i * FACE_SIZE + 12] = 0;
        continue;
      } else {
        faceData[i * FACE_SIZE + 12] = 1;
        this.geometryBuffer.faces[i].hsrObjectSpace(i, camera.positionObjSpace);
      }
      if (faceData[i * FACE_SIZE + 12] === 1 || !this.geometryBuffer.useHSR) {
        if (this.geometryBuffer.hasLight) {
          this.geometryBuffer.faces[i].light(i, ambient, lights);
        }
        this.geometryBuffer.faces[i].clipNear(i);
      }
    }
    //
    // Perform 2d projection and HSR (including additional aux faces created during clipping)
    for (let i = 0; i < this.geometryBuffer.faceCount + this.geometryBuffer.auxCount; i++) {
      // Only bother with triangles that are NOT culled.
      if (faceData[i * FACE_SIZE + 12] === 1) {
        Face.project(this.geometryBuffer, i, halfWidth, halfHeight);
      } else if (!this.geometryBuffer.useHSR) {
        faceData[i * FACE_SIZE + 12] = 1;
        Face.projectRev(this.geometryBuffer, i, halfWidth, halfHeight);
      }
    }
  }

  public render(r: RenderTarget): void {
    if (!this.culled) {
      const gb = this.geometryBuffer;
      const faceCount = gb.faceCount;
      const faces = gb.faces;
      let numAux = 0;
      for (let i = 0; i < faceCount; i++) {
        const face = faces[i];
        if (gb.facesDataI32[i * FACE_SIZE + 12] === 1) {
          if (face.clipCode <= 2) {
            r.binTriangle(gb, i);
          }
          if (face.clipCode === 2) {
            r.binTriangle(gb, faceCount + numAux++);
          }
        } else if (face.clipCode === 2) {
          numAux++;
        }
      }
    }
  }
}
