import { Canvas } from './canvas';
import { EnjinControl } from './controls';

export type ViewRenderFunc = (_ctx: CanvasRenderingContext2D, _canvas: HTMLCanvasElement, dT: number) => void;
export type ViewActivateFunc = () => void;

export class View {
  render: ViewRenderFunc | null = null;
  postRender: ViewRenderFunc | null = null;
  activate: ViewActivateFunc | null = null;
  controls: EnjinControl[] = [];
  lastCallTime: number;

  constructor(public name: string, public isVisible: boolean) {
    this.lastCallTime = 0.0;
  }

  public async baseRender(_ctx: CanvasRenderingContext2D, _canvas: HTMLCanvasElement, c: Canvas) {
    let dT = performance.now() - this.lastCallTime;
    if (this.lastCallTime === 0.0) {
      dT = 0;
    }
    this.lastCallTime = performance.now();

    if (this.render !== null) {
      await this.render!(_ctx, _canvas, dT);
    }

    for (let i = 0; i < this.controls.length; i++) {
      this.controls[i].update(c);
      this.controls[i].render(_ctx, _canvas);
    }

    if (this.postRender !== null) {
      this.postRender!(_ctx, _canvas, dT);
    }
  }

  public setRender(f: ViewRenderFunc) {
    this.render = f;
  }
  public setPostRender(f: ViewRenderFunc) {
    this.postRender = f;
  }

  public setActivate(f: ViewActivateFunc) {
    this.activate = f;
  }

  public addControl(c: EnjinControl) {
    this.controls.push(c);
  }
}

export class ViewManager {
  public static views: View[] = [];
  public static activeIdx = -1;

  public static addView(v: View, makeActive: boolean) {
    ViewManager.views.push(v);
    if (makeActive) {
      ViewManager.setView(ViewManager.views.length - 1);
    }
  }

  public static removeView(idx: number) {
    ViewManager.views.splice(idx, 1);
  }

  public static setView(idx: number) {
    for (let i = 0; i < ViewManager.views.length; i++) {
      ViewManager.views[i].isVisible = false;
    }
    if (ViewManager.views[idx].activate !== null) {
      ViewManager.views[idx].activate!();
    }
    ViewManager.views[idx].isVisible = true;
    ViewManager.activeIdx = idx;
  }

  public static async renderActiveView(_ctx: CanvasRenderingContext2D, _canvas: HTMLCanvasElement, c: Canvas) {
    if (ViewManager.activeIdx !== -1) {
      await ViewManager.views[ViewManager.activeIdx].baseRender(_ctx, _canvas, c);
    }
  }
}
