import { MatrixMath, transform } from './math3d';
import { Motion } from './motion';
import { mat4, vec4 } from './types';

export enum LightType {
  POINT,
  SPOT,
}

export class Light {
  public positiont: vec4 = new vec4(0, 0, 0, 1);
  public positionObjSpace: vec4 = new vec4(0, 0, 0, 1);
  public motion: Motion | null = null;
  public modelToWorldMatrix: mat4 = new mat4();
  public direction: vec4 = new vec4(0, 0, 1, 0);
  public dummyPos: vec4 = new vec4(0, 0, 0, 1);

  constructor(public type: LightType, public position: vec4, public color: vec4, public strength: number) {
    this.color.x *= strength;
    this.color.y *= strength;
    this.color.z *= strength;
  }

  public addMotion(m: Motion): void {
    this.motion = m;
    this.buildMatrix();
  }

  public update(dT: number): void {
    this.buildMatrix();
    transform(this.dummyPos, this.positiont, this.modelToWorldMatrix);
  }

  private buildMatrix(): void {
    const translationMatrix = mat4.makeTranslation(this.position.x, this.position.y, this.position.z);
    if (this.motion) {
      this.modelToWorldMatrix = MatrixMath.Multiply(this.motion.getTranslationMatrix(), translationMatrix);
    } else {
      this.modelToWorldMatrix = translationMatrix;
    }
  }
}
