import { MemoryPool } from './mallocator';
import JSONfn from 'json-fn';

export const MAX_THREADS = window.navigator.hardwareConcurrency;

export enum FuncType {
  WASM,
  SCRIPT,
}

export type ThreadMessage = {
  type: string;
  auxname: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

export type WasmBundle = {
  name: string;
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  funcs: any;
  pool: string;
  loaded: boolean;
};

export type MemoryInfo = {
  name: string;
  mem: MemoryPool; //WebAssembly.Memory;
};

export type ThreadObject = string;

export interface IDictionary<T> {
  [index: string]: T;
}

export class Scheduler {
  public static threads: Worker[] = [];
  public static threadCount: number;
  private static promises: Promise<boolean>[] = [];
  private static channels: MessageChannel[] = [];

  public static initialize(): void {
    //if (MAX_THREADS > 16) MAX_THREADS = 16;
    for (let i = 0; i < MAX_THREADS; i++) {
      console.log(`Initializing thread ${i + 1} of ${MAX_THREADS}...`);
      this.channels[i] = new MessageChannel();
      const worker = new Worker('schedulerworker.js?v=' + new Date().getTime(), { type: 'module' });
      this.threads[i] = worker;
      this.threadCount++;
      worker.addEventListener('message', (event: MessageEvent) => {
        const eventData: ThreadMessage = <ThreadMessage>event.data;
        if (eventData.type === 'startupResponse') {
          console.log(eventData.data);
        }
        if (eventData.type === 'wasmResponse') {
          console.log(eventData.data);
        }
        if (eventData.type === 'memResponse') {
          console.log(eventData.data);
        }
        if (eventData.type === 'loadcodeResponse') {
          console.log(eventData.data);
        }
        if (eventData.type === 'memDeleteResponse') {
          console.log(eventData.data);
        }
      });

      const threadStartData: ThreadMessage = { type: 'startup', auxname: '', data: i };
      worker.postMessage(threadStartData, [this.channels[i].port2]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static makeThreadObject(threadObj: any): ThreadObject {
    return <ThreadObject>JSONfn.stringify(threadObj);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static loadCode(objname: string, threadObj: any): void {
    for (let i = 0; i < MAX_THREADS; i++) {
      threadObj.workerId = i;
      threadObj.maxThreads = MAX_THREADS;
      const threadcode = Scheduler.makeThreadObject(threadObj);
      const threadCodeData: ThreadMessage = {
        type: 'codedata',
        data: threadcode,
        auxname: objname,
      };
      this.threads[i].postMessage(threadCodeData);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static invoke(type: FuncType, objname: string, name: string, args: any[]) {
    const threadCodeData: ThreadMessage = {
      type: 'coderun',
      auxname: objname,
      data: { type: type, objname: objname, name: name, args: args },
    };
    for (let i = 0; i < MAX_THREADS; i++) {
      Scheduler.promises[i] = new Promise((resolve) => {
        this.channels[i].port1.onmessage = () => {
          resolve(true);
        };
        this.threads[i].postMessage(threadCodeData);
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static waitAll(cb: any) {
    Promise.all(Scheduler.promises).then(cb);
  }

  public static async waitAllAsync(): Promise<void> {
    await Promise.all(Scheduler.promises);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static invokeAtomic(type: FuncType, objname: string, name: string, args: any[]) {
    const threadCodeData: ThreadMessage = {
      type: 'coderun',
      auxname: objname,
      data: { type: type, objname: objname, name: name, args: args },
    };
    for (let i = 0; i < MAX_THREADS; i++) {
      this.threads[i].postMessage(threadCodeData);
    }
  }

  public static removeMemoryPool(name: string) {
    for (let i = 0; i < MAX_THREADS; i++) {
      console.log(`Deleting Shared Data [${name}] from thread ${i + 1}...`);
      const threadMemData: ThreadMessage = {
        type: 'deletemem',
        auxname: '',
        data: { name: name },
      };
      this.threads[i].postMessage(threadMemData);
    }
  }

  public static terminateThread(idx: number) {
    Scheduler.threads[idx].terminate();
  }

  public static setMemoryPool(name: string, pool: MemoryPool) {
    for (let i = 0; i < MAX_THREADS; i++) {
      console.log(`Assigning Shared Data [${name}] to thread ${i + 1}...`);
      const threadMemData: ThreadMessage = {
        type: 'sharemem',
        auxname: '',
        data: <MemoryInfo>{ name: name, mem: pool },
      };
      console.log(threadMemData);
      try {
        this.threads[i].postMessage(threadMemData);
      } catch (err) {
        console.log(err);
      }
    }
  }

  public static setSync(name: string, pool: SharedArrayBuffer) {
    for (let i = 0; i < MAX_THREADS; i++) {
      console.log(`Assigning Sync Shared Data [${name}] to thread ${i + 1}...`);
      const threadMemData: ThreadMessage = {
        type: 'sharesync',
        auxname: '',
        data: pool,
      };
      console.log(threadMemData);
      try {
        this.threads[i].postMessage({ pool });
      } catch (err) {
        console.log(err);
      }
    }
  }

  public static loadWASMModule(name: string, moduleURL: string, poolName: string) {
    for (let i = 0; i < MAX_THREADS; i++) {
      console.log(`Loading WASM module [${moduleURL},${name}] to thread ${i + 1}...`);
      const threadWasmData: ThreadMessage = {
        type: 'wasmload',
        auxname: '',
        data: <WasmBundle>{ name: name, url: moduleURL, funcs: null, pool: poolName, loaded: false },
      };
      this.threads[i].postMessage(threadWasmData);
    }
  }
}
