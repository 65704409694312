export class EnjinImage {
  public width: number;
  public height: number;
  public data?: Uint32Array;
  public image: HTMLImageElement;
  public aspect: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.image = new Image();
    this.aspect = this.height / this.width;
  }

  public static async fromURL(url: string): Promise<EnjinImage> {
    const img = new EnjinImage(0, 0);
    img.image.src = url;
    await img.image.decode();
    img.width = img.image.width;
    img.height = img.image.height;
    img.aspect = img.height / img.width;
    img.buildARGB();
    return img;
  }

  private buildARGB(): void {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(this.image, 0, 0);
      const srcData = context.getImageData(0, 0, this.width, this.height).data;
      this.data = new Uint32Array(srcData.buffer);
    }
  }
}
