import _ from 'lodash';
import { Canvas, registerEnjinCanvas } from './enjin/canvas';
import { Scheduler } from './enjin/scheduler';
import { InputManager } from './enjin/input';
import { AudioManager } from './enjin/audio';
import { BuildGameView } from './game/GameView';
import { ViewManager } from './enjin/view';
import { BuildHomeView } from './game/HomeView';
import { BuildAboutView } from './game/AboutView';
import { MemoryManager, MemoryPool } from './enjin/mallocator';
import { GeometryBuffer } from './enjin/geometrybuffer';

// We need to ensure the custom element is registered with the browser before use.
// We do not rely on the side-effect of the above import to execute this registration and
// rather make it explicit. Multiple registrations that would generate errors are trapped in this function.
registerEnjinCanvas();
Scheduler.initialize();
InputManager.initialize(false);
AudioManager.initialize();

let GlobalMemory: MemoryPool | null = null;

// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
// Application Entry Point Function.
// ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
async function init() {
  //
  // Create a global memory heap for the application with initial size 10mb.
  GlobalMemory = MemoryManager.CreatePool(1024 * 1024 * 10, 'appMemory');
  Scheduler.setMemoryPool('appMemory', GlobalMemory);
  //
  // Configure the scheduler with the necessary functions/buffers to process GeometryBuffer operations concurrently.
  GeometryBuffer.initialize(GlobalMemory);

  if (GlobalMemory) {
    //const appViews = [BuildGameView(GlobalMemory), BuildHomeView()];
    //await Promise.all(appViews);
    await BuildAboutView(GlobalMemory);
    ViewManager.setView(0);

    const c: Canvas = document.getElementById('maincanvas')! as Canvas;
    c.setMemory(GlobalMemory);
    c.setRenderer(async (_ctx: CanvasRenderingContext2D, _canvas: HTMLCanvasElement) => {
      await ViewManager.renderActiveView(_ctx, _canvas, c);
    });
  }
}

// Kick off the magic once the DOM has loaded and parsed.
document.addEventListener('DOMContentLoaded', async () => {
  init();
});
