export {};
declare global {
  export interface Number {
    roundUp: (align: number) => number;
    clamp: (min: number, max: number) => number;
    clampf: (min: number, max: number) => number;
    clamphf: (max: number) => number;
  }
}

Number.prototype.roundUp = function (this: number, align: number): number {
  return (((this | 0) - 1) | (align - 1)) + 1;
};

Number.prototype.clamp = function (this: number, min: number, max: number): number {
  if (this < min) return min;
  if (this > max) return max;
  return this | 0;
};

Number.prototype.clampf = function (this: number, min: number, max: number): number {
  if (this < min) return min;
  if (this > max) return max;
  return this;
};

Number.prototype.clamphf = function (this: number, max: number): number {
  if (this > max) return max;
  return this;
};

export interface CopyableArray {
  [index: number]: number;
}

export function CopyElements<T>(srcArr: T, src: number, destArr: T, dst: number, count: number): void {
  for (let i = 0; i < count; ++i) {
    (<CopyableArray>destArr)[dst++] = (<CopyableArray>srcArr)[src++];
  }
}

export function CopyF32Elements(
  srcArr: Float32Array,
  src: number,
  destArr: Float32Array,
  dst: number,
  count: number
): void {
  for (let i = 0; i < count; ++i) {
    destArr[dst++] = srcArr[src++];
  }
}

export function CopyI32Elements(
  srcArr: Int32Array,
  src: number,
  destArr: Int32Array,
  dst: number,
  count: number
): void {
  for (let i = 0; i < count; i++) {
    destArr[dst + i] = srcArr[src + i];
  }
}
