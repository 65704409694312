import { vec4 } from './types';

export class boundingSphere {
  public positionT: vec4;
  public position: vec4;
  public radius = 0;
  public radiusSqr = 0;

  constructor() {
    this.position = new vec4(0, 0, 0, 1);
    this.positionT = new vec4(0, 0, 0, 1);
  }

  public static createFromVertices(vertices: vec4[], cpt: vec4): boundingSphere {
    const b = new boundingSphere();
    b.position.w = 1;
    let maxDist = 0;

    const mean = cpt;
    b.position.copyFrom(mean);

    const dist = new vec4(0, 0, 0, 0);

    for (let i = 0; i < vertices.length; i++) {
      const v = vertices[i];
      dist.x = v.x - mean.x;
      dist.y = v.y - mean.y;
      dist.z = v.z - mean.z;
      if (dist.length() > maxDist) {
        maxDist = dist.length();
      }
    }
    b.radius = maxDist;
    b.radiusSqr = maxDist * maxDist;
    return b;
  }

  public static createFromVertices_Ritter(vertices: vec4[]): boundingSphere {
    const b = new boundingSphere();
    b.position.w = 1;

    let xmin = new vec4(0, 0, 0, 1);
    let xmax = new vec4(0, 0, 0, 1);
    let ymin = new vec4(0, 0, 0, 1);
    let ymax = new vec4(0, 0, 0, 1);
    let zmin = new vec4(0, 0, 0, 1);
    let zmax = new vec4(0, 0, 0, 1);
    xmin.x = 10000000;
    ymin.y = 10000000;
    zmin.z = 10000000;
    xmax.x = -10000000;
    ymax.y = -10000000;
    zmax.z = -10000000;

    for (let i = 0; i < vertices.length; i++) {
      const v = vertices[i];
      if (v.x < xmin.x) xmin = v;
      if (v.y < ymin.y) ymin = v;
      if (v.z < zmin.z) zmin = v;
      if (v.x > xmax.x) xmax = v;
      if (v.y > ymax.y) ymax = v;
      if (v.z > zmax.z) zmax = v;
    }
    let dx = xmax.x - xmin.x;
    let dy = xmax.y - xmin.y;
    let dz = xmax.z - xmin.z;
    const xspan = dx * dx + dy * dy + dz * dz;

    dx = ymax.x - ymin.x;
    dy = ymax.y - ymin.y;
    dz = ymax.z - ymin.z;
    const yspan = dx * dx + dy * dy + dz * dz;

    dx = zmax.x - zmin.x;
    dy = zmax.y - zmin.y;
    dz = zmax.z - zmin.z;
    const zspan = dx * dx + dy * dy + dz * dz;

    // Set points dia1 & dia2 to the maximally separated pair
    // assume xspan biggest
    let dia1 = xmin;
    let dia2 = xmax;
    let maxspan = xspan;
    if (yspan > maxspan) {
      maxspan = yspan;
      dia1 = ymin;
      dia2 = ymax;
    }
    if (zspan > maxspan) {
      dia1 = zmin;
      dia2 = zmax;
    }

    // dia1, dia2 is a diameter of initial sphere
    // calc initial center
    b.position.x = (dia1.x + dia2.x) / 2.0;
    b.position.y = (dia1.y + dia2.y) / 2.0;
    b.position.z = (dia1.z + dia2.z) / 2.0;

    // calculate initial radius**2 and radius
    dx = dia2.x - b.position.x; /* x component of radius vector */
    dy = dia2.y - b.position.y; /* y component of radius vector */
    dz = dia2.z - b.position.z; /* z component of radius vector */
    b.radiusSqr = dx * dx + dy * dy + dz * dz;
    b.radius = Math.sqrt(b.radiusSqr);

    for (let i = 0; i < vertices.length; i++) {
      const v = vertices[i];
      dx = v.x - b.position.x;
      dy = v.y - b.position.y;
      dz = v.z - b.position.z;
      const old_to_p_sqr = dx * dx + dy * dy + dz * dz;
      if (old_to_p_sqr > b.radiusSqr) {
        const old_to_p = Math.sqrt(old_to_p_sqr);
        /* calc radius of new sphere */
        b.radius = (b.radius + old_to_p) / 2.0;
        b.radiusSqr = b.radius * b.radius; /* for next r**2 compare */
        const old_to_new = old_to_p - b.radius;
        /* calc center of new sphere */
        b.position.x = (b.radius * b.position.x + old_to_new * v.x) / old_to_p;
        b.position.y = (b.radius * b.position.y + old_to_new * v.y) / old_to_p;
        b.position.z = (b.radius * b.position.z + old_to_new * v.z) / old_to_p;
      }
    }

    return b;
  }

  public static createFromVerticesSubset(vertices: vec4[], ofs: number, cnt: number): boundingSphere {
    const b = new boundingSphere();
    b.position.w = 1;
    let maxDist = 0;

    const mean = new vec4(0, 0, 0, 1);
    b.position.copyFrom(mean);

    const dist = new vec4(0, 0, 0, 0);

    for (let i = ofs; i < ofs + cnt; i++) {
      const v = vertices[i];
      dist.x = v.x - mean.x;
      dist.y = v.y - mean.y;
      dist.z = v.z - mean.z;
      if (dist.length() > maxDist) {
        maxDist = dist.length();
      }
    }
    b.radius = maxDist;
    b.radiusSqr = maxDist * maxDist;
    return b;
  }

  public static createFromVerticesSubset_Ritter(vertices: vec4[], ofs: number, cnt: number): boundingSphere {
    const b = new boundingSphere();
    b.position.w = 1;

    let xmin = new vec4(0, 0, 0, 1);
    let xmax = new vec4(0, 0, 0, 1);
    let ymin = new vec4(0, 0, 0, 1);
    let ymax = new vec4(0, 0, 0, 1);
    let zmin = new vec4(0, 0, 0, 1);
    let zmax = new vec4(0, 0, 0, 1);
    xmin.x = 10000000;
    ymin.y = 10000000;
    zmin.z = 10000000;
    xmax.x = -10000000;
    ymax.y = -10000000;
    zmax.z = -10000000;

    for (let i = ofs; i < ofs + cnt; i++) {
      const v = vertices[i];
      if (v.x < xmin.x) xmin = v;
      if (v.y < ymin.y) ymin = v;
      if (v.z < zmin.z) zmin = v;
      if (v.x > xmax.x) xmax = v;
      if (v.y > ymax.y) ymax = v;
      if (v.z > zmax.z) zmax = v;
    }
    let dx = xmax.x - xmin.x;
    let dy = xmax.y - xmin.y;
    let dz = xmax.z - xmin.z;
    const xspan = dx * dx + dy * dy + dz * dz;

    dx = ymax.x - ymin.x;
    dy = ymax.y - ymin.y;
    dz = ymax.z - ymin.z;
    const yspan = dx * dx + dy * dy + dz * dz;

    dx = zmax.x - zmin.x;
    dy = zmax.y - zmin.y;
    dz = zmax.z - zmin.z;
    const zspan = dx * dx + dy * dy + dz * dz;

    // Set points dia1 & dia2 to the maximally separated pair
    // assume xspan biggest
    let dia1 = xmin;
    let dia2 = xmax;
    let maxspan = xspan;
    if (yspan > maxspan) {
      maxspan = yspan;
      dia1 = ymin;
      dia2 = ymax;
    }
    if (zspan > maxspan) {
      dia1 = zmin;
      dia2 = zmax;
    }

    // dia1, dia2 is a diameter of initial sphere
    // calc initial center
    b.position.x = (dia1.x + dia2.x) / 2.0;
    b.position.y = (dia1.y + dia2.y) / 2.0;
    b.position.z = (dia1.z + dia2.z) / 2.0;

    // calculate initial radius**2 and radius
    dx = dia2.x - b.position.x; /* x component of radius vector */
    dy = dia2.y - b.position.y; /* y component of radius vector */
    dz = dia2.z - b.position.z; /* z component of radius vector */
    b.radiusSqr = dx * dx + dy * dy + dz * dz;
    b.radius = Math.sqrt(b.radiusSqr);

    for (let i = ofs; i < ofs + cnt; i++) {
      const v = vertices[i];
      dx = v.x - b.position.x;
      dy = v.y - b.position.y;
      dz = v.z - b.position.z;
      const old_to_p_sqr = dx * dx + dy * dy + dz * dz;
      if (old_to_p_sqr > b.radiusSqr) {
        const old_to_p = Math.sqrt(old_to_p_sqr);
        /* calc radius of new sphere */
        b.radius = (b.radius + old_to_p) / 2.0;
        b.radiusSqr = b.radius * b.radius; /* for next r**2 compare */
        const old_to_new = old_to_p - b.radius;
        /* calc center of new sphere */
        b.position.x = (b.radius * b.position.x + old_to_new * v.x) / old_to_p;
        b.position.y = (b.radius * b.position.y + old_to_new * v.y) / old_to_p;
        b.position.z = (b.radius * b.position.z + old_to_new * v.z) / old_to_p;
      }
    }
    return b;
  }
}
