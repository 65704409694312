import { Face } from './face';
import { vec4, vec2, mat4 } from './types';
import { MemoryPool, Variable, VARTYPE } from './mallocator';
import { Scheduler } from './scheduler';
import { Material } from './material';

// This value must remain even to allow us to use the low bit to indicate the primitive(triangle) is to be binned as partial not full.
export const FACE_SIZE = 98;

export class GeometryBuffer {
  public vertices: vec4[] = [];
  public colors: vec4[] = [];
  public normals: vec4[] = [];
  public uvs: vec2[] = [];
  public faces: Face[] = [];

  public faceCount = 0;
  public vertexCount = 0;
  public normalCount = 0;
  public uvCount = 0;
  public auxCount = 0;

  public memory: MemoryPool;
  public verticesVar!: Variable;
  public verticestVar!: Variable;
  public vertices2dVar!: Variable;
  public normalsVar!: Variable;
  public uvVar!: Variable;
  public facesVar!: Variable;
  public vertexData!: Float32Array;
  public vertextData!: Float32Array;
  public vertex2dData!: Float32Array;
  public uvData!: Float32Array;
  public normalData!: Float32Array;
  public facesDataF32!: Float32Array;
  public facesDataI32!: Int32Array;

  public useHSR = true;
  public hasLight = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static wasmFuncs?: any;
  public static appMem?: MemoryPool;

  public mtxVar: Variable;
  public mtxData: Float32Array;

  constructor(public name: string) {
    this.memory = GeometryBuffer.appMem!;
    this.mtxVar = this.memory.addVariable('mtx', VARTYPE.FLOAT, 16);
    const buf = this.memory.memory.buffer;
    this.mtxData = new Float32Array(buf, this.mtxVar.ptr, this.mtxVar.size / 4);
  }

  public static initialize(mem: MemoryPool): void {
    GeometryBuffer.appMem = mem;
    Scheduler.loadCode('geometry', {
      msg: '',
      workerId: 0,
      maxThreads: 0,
      args: [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transform: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory']) {
          const data = pools['appMemory'].memory.buffer;
          const me = self.args; // The first 16 arguments in the array are the transform matrix elements.
          const vData = new Float32Array(data, self.args[16], self.args[17] / 4);
          const vtData = new Float32Array(data, self.args[18], self.args[19] / 4);
          const vertexCount = self.args[20];
          let verticesPerCore = (vertexCount / self.maxThreads) | 0;
          const remain = vertexCount % self.maxThreads;
          let o = self.workerId * verticesPerCore * 4;
          if (self.workerId === self.maxThreads - 1) {
            verticesPerCore += remain;
          }
          for (let i = 0; i < verticesPerCore; i++) {
            vtData[o + 0] = vData[o] * me[0] + vData[o + 1] * me[1] + vData[o + 2] * me[2] + vData[o + 3] * me[3];
            vtData[o + 1] = vData[o] * me[4] + vData[o + 1] * me[5] + vData[o + 2] * me[6] + vData[o + 3] * me[7];
            vtData[o + 2] = vData[o] * me[8] + vData[o + 1] * me[9] + vData[o + 2] * me[10] + vData[o + 3] * me[11];
            vtData[o + 3] = vData[o] * me[12] + vData[o + 1] * me[13] + vData[o + 2] * me[14] + vData[o + 3] * me[15];
            o += 4;
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      hsr: (self: any, pools: any, wasmModules: any) => {
        if (pools['appMemory']) {
          const data = pools['appMemory'].memory.buffer;
          const faceCount = self.args[0];
          const fDataI32 = new Int32Array(data, self.args[1], self.args[2] / 4);
          const vData = new Float32Array(data, self.args[3], self.args[4] / 4);
          const facesPerCore = (faceCount / self.maxThreads) | 0;
          let o = self.workerId * facesPerCore * self.args[5];
          for (let i = 0; i < facesPerCore; i++) {
            fDataI32[o + 12] = 1;
            const vidx1 = fDataI32[o + 0] * 2;
            const vidx2 = fDataI32[o + 1] * 2;
            const vidx3 = fDataI32[o + 2] * 2;
            const v1x = vData[vidx1 + 0];
            const v1y = vData[vidx1 + 1];
            const v2x = vData[vidx2 + 0];
            const v2y = vData[vidx2 + 1];
            const v3x = vData[vidx3 + 0];
            const v3y = vData[vidx3 + 1];
            //| x1-x0 y1-y0 |
            //| x2-x0 y2-y0 |
            const d1x = v3x - v1x;
            const d1y = v3y - v1y;
            const d2x = v3x - v2x;
            const d2y = v3y - v2y;
            if (d1x * d2y - d1y * d2x < 0) {
              fDataI32[o + 12] = 0;
            }
            o += self.args[5];
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      lighting: (self: any, pools: any, wasmModules: any, vec4: any, VectorMath: any) => {
        const data = pools['appMemory'].memory.buffer;
        const faceCount = self.args[0];
        const fDataI32 = new Int32Array(data, self.args[1], self.args[2] / 4);
        const fDataF32 = new Float32Array(data, self.args[1], self.args[2] / 4);
        const vertices = new Float32Array(data, self.args[3], self.args[4] / 4);
        const facesPerCore = (faceCount / self.maxThreads) | 0;
        let o = self.workerId * facesPerCore * self.args[5];
        const ambientR = self.args[6];
        const ambientG = self.args[7];
        const ambientB = self.args[8];
        const lightCount = self.args[9];
        const diffuseAcc = new vec4(0, 0, 0, 0);
        const lightVec = new vec4(0, 0, 0, 0);
        const normal = new vec4(0, 0, 0, 0);
        for (let j = 0; j < facesPerCore; j++) {
          if (fDataI32[o + 12] === 1) {
            const vertexX = vertices[fDataI32[o + 0] * 4 + 0];
            const vertexY = vertices[fDataI32[o + 0] * 4 + 1];
            const vertexZ = vertices[fDataI32[o + 0] * 4 + 2];
            normal.x = fDataF32[o + 14];
            normal.y = fDataF32[o + 15];
            normal.z = fDataF32[o + 16];
            diffuseAcc.x = 0;
            diffuseAcc.y = 0;
            diffuseAcc.z = 0;
            const diffuseR = fDataF32[o + 17];
            const diffuseG = fDataF32[o + 18];
            const diffuseB = fDataF32[o + 19];
            for (let i = 0; i < lightCount; i++) {
              const lightX = self.args[10 + i * 6 + 0];
              const lightY = self.args[10 + i * 6 + 1];
              const lightZ = self.args[10 + i * 6 + 2];
              const lightR = self.args[10 + i * 6 + 3];
              const lightG = self.args[10 + i * 6 + 4];
              const lightB = self.args[10 + i * 6 + 5];
              // Get a light to face vertex vector in object space.
              lightVec.x = lightX - vertexX;
              lightVec.y = lightY - vertexY;
              lightVec.z = lightZ - vertexZ;
              lightVec.normalize();
              // Calculate dot product between light and face normal.
              const dp = VectorMath.dotProduct(lightVec, normal).clampf(0, 1);
              diffuseAcc.x += dp * lightR;
              diffuseAcc.y += dp * lightG;
              diffuseAcc.z += dp * lightB;
            }
            const finalR = ((diffuseR * (ambientR + diffuseAcc.x)).clampf(0, 1) * 255) | 0;
            const finalG = ((diffuseG * (ambientG + diffuseAcc.y)).clampf(0, 1) * 255) | 0;
            const finalB = ((diffuseB * (ambientB + diffuseAcc.z)).clampf(0, 1) * 255) | 0;
            fDataI32[o + 13] = 0xff000000 | (finalB << 16) | (finalG << 8) | finalR;
          }
          o += self.args[5];
        }
      },
    });

    const importObject = {
      imports: {
        mem: mem.memory,
      },
    };

    WebAssembly.instantiateStreaming(fetch('wasm/geometry.wasm'), importObject).then((obj) => {
      GeometryBuffer.wasmFuncs = obj.instance.exports;
    });
  }

  // Set the curent active transformation matrix to be used by low-level/wasm functions against this buffer.
  // We transpose the matrix to make the SIMD operations more efficient.
  public setMatrix(m: mat4): void {
    for (let j = 0; j < 4; j++) {
      for (let i = 0; i < 4; i++) {
        this.mtxData[i + j * 4] = m.elements[j + i * 4];
      }
    }
  }

  public resetClipping(): void {
    this.auxCount = 0;
  }

  public generateClipCode(vidx: number, skipFarPlane: boolean): number {
    let code = 0;
    vidx *= 4;
    const vx = this.vertextData[vidx + 0];
    const vy = this.vertextData[vidx + 1];
    const vz = this.vertextData[vidx + 2];
    const vw = this.vertextData[vidx + 3];
    if (-vw >= vx) code |= 0x01;
    if (vw <= vx) code |= 0x02;
    if (-vw >= vy) code |= 0x04;
    if (vw <= vy) code |= 0x08;
    if (0.0 >= vz) code |= 0x10;
    if (vw <= vz && !skipFarPlane) code |= 0x20;
    return code;
  }

  public triangleOut(i: number, skipFarPlane: boolean): boolean {
    const v1 = this.faces[i].vertexIdx[0];
    const v2 = this.faces[i].vertexIdx[1];
    const v3 = this.faces[i].vertexIdx[2];
    const c1 = this.generateClipCode(v1, skipFarPlane);
    const c2 = this.generateClipCode(v2, skipFarPlane);
    const c3 = this.generateClipCode(v3, skipFarPlane);
    const r = c1 & c2 & c3;
    if (r !== 0) {
      return true;
    }
    return false;
  }

  // Create the native memory buffers from the JS/TS source arrays.
  public buildNativeBuffers(): void {
    const mem = this.memory;

    if (this.uvs.length === 0) {
      this.addUV(new vec2(0, 0));
    }

    this.verticesVar = mem.addVariable(`${this.name}-vertices`, VARTYPE.FLOAT, this.vertices.length * 4);
    this.vertexData = new Float32Array(mem.memory.buffer, this.verticesVar.ptr, this.verticesVar.size / 4);
    this.verticestVar = mem.addVariable(`${this.name}-verticest`, VARTYPE.FLOAT, this.vertices.length * 4);
    this.vertextData = new Float32Array(mem.memory.buffer, this.verticestVar.ptr, this.verticestVar.size / 4);
    this.vertices2dVar = mem.addVariable(`${this.name}-vertices2d`, VARTYPE.FLOAT, this.vertices.length * 2);
    this.vertex2dData = new Float32Array(mem.memory.buffer, this.vertices2dVar.ptr, this.vertices2dVar.size / 4);
    this.uvVar = mem.addVariable(`${this.name}-uv`, VARTYPE.FLOAT, this.uvs.length * 2);
    this.uvData = new Float32Array(mem.memory.buffer, this.uvVar.ptr, this.uvVar.size / 4);
    this.normalsVar = mem.addVariable(`${this.name}-normals`, VARTYPE.FLOAT, this.normals.length * 4);
    this.normalData = new Float32Array(mem.memory.buffer, this.normalsVar.ptr, this.normalsVar.size / 4);
    this.facesVar = mem.addVariable(`${this.name}-faces`, VARTYPE.FLOAT, this.faces.length * 2 * FACE_SIZE);
    this.facesDataF32 = new Float32Array(mem.memory.buffer, this.facesVar.ptr, this.facesVar.size / 4);
    this.facesDataI32 = new Int32Array(mem.memory.buffer, this.facesVar.ptr, this.facesVar.size / 4);

    for (let i = 0; i < this.vertices.length; i++) {
      this.vertexData[i * 4 + 0] = this.vertices[i].x;
      this.vertexData[i * 4 + 1] = this.vertices[i].y;
      this.vertexData[i * 4 + 2] = this.vertices[i].z;
      this.vertexData[i * 4 + 3] = this.vertices[i].w;
    }

    for (let i = 0; i < this.uvs.length; i++) {
      this.uvData[i * 2 + 0] = this.uvs[i].x;
      this.uvData[i * 2 + 1] = this.uvs[i].y;
    }

    for (let i = 0; i < this.normals.length; i++) {
      this.normalData[i * 4 + 0] = this.normals[i].x;
      this.normalData[i * 4 + 1] = this.normals[i].y;
      this.normalData[i * 4 + 2] = this.normals[i].z;
      this.normalData[i * 4 + 3] = this.normals[i].w;
    }

    for (let i = 0; i < this.faces.length; i++) {
      const ofs = i * FACE_SIZE;
      this.facesDataI32[ofs + 0] = this.faces[i].vertexIdx[0];
      this.facesDataI32[ofs + 1] = this.faces[i].vertexIdx[1];
      this.facesDataI32[ofs + 2] = this.faces[i].vertexIdx[2];
      this.facesDataI32[ofs + 3] = this.faces[i].normalIdx[0];
      this.facesDataI32[ofs + 4] = this.faces[i].normalIdx[1];
      this.facesDataI32[ofs + 5] = this.faces[i].normalIdx[2];
      this.facesDataI32[ofs + 6] = this.faces[i].uvIdx[0];
      this.facesDataI32[ofs + 7] = this.faces[i].uvIdx[1];
      this.facesDataI32[ofs + 8] = this.faces[i].uvIdx[2];
      this.facesDataI32[ofs + 9] = this.verticesVar.ptr;
      this.facesDataI32[ofs + 10] = this.verticestVar.ptr;
      this.facesDataI32[ofs + 11] = this.vertices2dVar.ptr;
      this.facesDataI32[ofs + 12] = 1; // visible flag
      this.facesDataI32[ofs + 13] = this.faces[i].material!.diffuseColor.asARGB();
      this.facesDataF32[ofs + 14] = this.faces[i].normal.x;
      this.facesDataF32[ofs + 15] = this.faces[i].normal.y;
      this.facesDataF32[ofs + 16] = this.faces[i].normal.z;
      this.facesDataF32[ofs + 17] = this.faces[i].material!.diffuseColor.x;
      this.facesDataF32[ofs + 18] = this.faces[i].material!.diffuseColor.y;
      this.facesDataF32[ofs + 19] = this.faces[i].material!.diffuseColor.z;
      if (this.faces[i].material && this.faces[i].material!.texture) {
        this.facesDataI32[ofs + 51] = this.faces[i].material!.texture!.normalWidth;
        this.facesDataI32[ofs + 52] = this.faces[i].material!.texture!.normalHeight;
        this.facesDataI32[ofs + 53] = this.faces[i].material!.texture!.ptr;
      } else {
        this.facesDataI32[ofs + 51] = 0;
        this.facesDataI32[ofs + 52] = 0;
        this.facesDataI32[ofs + 53] = 0;
      }
    }
  }

  public copyFace(src: number, dst: number): void {
    const srcofs = src;
    const dstofs = dst * FACE_SIZE;
    this.facesDataI32[dstofs + 0] = this.faces[srcofs].vertexIdx[0];
    this.facesDataI32[dstofs + 1] = this.faces[srcofs].vertexIdx[1];
    this.facesDataI32[dstofs + 2] = this.faces[srcofs].vertexIdx[2];
    this.facesDataI32[dstofs + 3] = this.faces[srcofs].normalIdx[0];
    this.facesDataI32[dstofs + 4] = this.faces[srcofs].normalIdx[1];
    this.facesDataI32[dstofs + 5] = this.faces[srcofs].normalIdx[2];
    this.facesDataI32[dstofs + 6] = this.faces[srcofs].uvIdx[0];
    this.facesDataI32[dstofs + 7] = this.faces[srcofs].uvIdx[1];
    this.facesDataI32[dstofs + 8] = this.faces[srcofs].uvIdx[2];
    this.facesDataI32[dstofs + 9] = this.verticesVar.ptr;
    this.facesDataI32[dstofs + 10] = this.verticestVar.ptr;
    this.facesDataI32[dstofs + 11] = this.vertices2dVar.ptr;
    this.facesDataI32[dstofs + 12] = 1; // visible flag
    this.facesDataI32[dstofs + 13] = 0xffffffff;
    this.facesDataF32[dstofs + 14] = this.faces[srcofs].normal.x;
    this.facesDataF32[dstofs + 15] = this.faces[srcofs].normal.y;
    this.facesDataF32[dstofs + 16] = this.faces[srcofs].normal.z;
    this.facesDataF32[dstofs + 17] = this.faces[srcofs].material!.diffuseColor.x;
    this.facesDataF32[dstofs + 18] = this.faces[srcofs].material!.diffuseColor.y;
    this.facesDataF32[dstofs + 19] = this.faces[srcofs].material!.diffuseColor.z;
    if (this.faces[srcofs].material && this.faces[srcofs].material!.texture) {
      this.facesDataI32[dstofs + 51] = this.faces[srcofs].material!.texture!.normalWidth;
      this.facesDataI32[dstofs + 52] = this.faces[srcofs].material!.texture!.normalHeight;
      this.facesDataI32[dstofs + 53] = this.faces[srcofs].material!.texture!.ptr;
    } else {
      this.facesDataI32[dstofs + 51] = 0;
      this.facesDataI32[dstofs + 52] = 0;
      this.facesDataI32[dstofs + 53] = 0;
    }
  }

  public addFace(f: Face, m: Material): void {
    f.material = m;
    f.geometryBuffer = this;
    this.faces.push(f);
    this.faceCount++;
  }

  public addVertex(v: vec4): void {
    this.vertices.push(v);
    this.vertexCount++;
  }

  public addUV(uv: vec2): void {
    this.uvs.push(uv);
    this.uvCount++;
  }

  public addNormal(n: vec4): void {
    this.normals.push(n);
    this.normalCount++;
  }

  public transformVertices(m: mat4, startOfs: number, cnt: number): void {
    const me = m.elements;
    const v = this.vertexData;
    const vt = this.vertextData;
    let ofs = startOfs * 4;
    for (let i = 0; i < cnt; i++) {
      vt[ofs + 0] = v[ofs + 0] * me[0] + v[ofs + 1] * me[1] + v[ofs + 2] * me[2] + v[ofs + 3] * me[3];
      vt[ofs + 1] = v[ofs + 0] * me[4] + v[ofs + 1] * me[5] + v[ofs + 2] * me[6] + v[ofs + 3] * me[7];
      vt[ofs + 2] = v[ofs + 0] * me[8] + v[ofs + 1] * me[9] + v[ofs + 2] * me[10] + v[ofs + 3] * me[11];
      vt[ofs + 3] = v[ofs + 0] * me[12] + v[ofs + 1] * me[13] + v[ofs + 2] * me[14] + v[ofs + 3] * me[15];
      ofs += 4;
    }
  }

  public transformVerticesWASM(startOfs: number, cnt: number): void {
    GeometryBuffer.wasmFuncs.transformTransposed(
      this.verticesVar.ptr + startOfs * 16,
      this.verticestVar.ptr + startOfs * 16,
      this.mtxVar.ptr,
      cnt
    );
  }
}
