// This class makes no attempt yet to prevent or limit fragmentation of the memory block.
// It also doesn't offer a defrag method .. yet.. care would have to be taken to make this thread-safe.
// It also doesn't provide anyway to allocate and reference complex types, only primitives of size 1+
// Only UINT32 and FLOAT are fully implemented.

import { Scheduler } from './scheduler';

interface IDictionary<T> {
  [index: string]: T;
}

export enum VARTYPE {
  UINT8,
  UINT16,
  UINT32,
  INT8,
  INT16,
  INT32,
  FLOAT,
}

export type Variable = {
  ptr: number;
  size: number;
  maxSize: number;
  elementSize: number;
};

export class MemoryPool {
  // These two are measured in bytes.
  public freeSize = 0;
  public usedSize = 0;
  // This is measured in pages (64kb).
  public pageSize = 0;
  public memory!: WebAssembly.Memory;

  public variables: IDictionary<Variable> = {};
  private basePtr = 0;

  constructor(public startSize: number, public name: string) {
    this.freeSize = this.startSize; // Free, used and total size are measured in bytes.
    this.usedSize = 0;
    this.pageSize = (this.startSize / 65536 + 1) | 0; // Page size is multiples of 64kb as used by Web Assembly.

    console.log(`creating memory pool with size ${this.pageSize} - ${this.startSize}`);

    this.memory = new WebAssembly.Memory({
      initial: this.pageSize,
      maximum: (1024 * 1024 * 1024) / 65536,
      shared: true,
    });
  }

  addVariable(name: string, varType: VARTYPE, count: number): Variable {
    if (count < 1) {
      throw new Error(`Cannot allocate memory for 0 instances of ${varType}`);
    }

    // Determine number of bytes per varType.
    let varSize = 0;
    switch (varType) {
      case VARTYPE.INT8:
      case VARTYPE.UINT8:
        varSize = 1;
        break;
      case VARTYPE.UINT16:
      case VARTYPE.INT16:
        varSize = 2;
        break;
      case VARTYPE.UINT32:
      case VARTYPE.INT32:
      case VARTYPE.FLOAT:
        varSize = 4;
        break;
    }
    if (varSize === 0) {
      throw new Error('Unknown size for memory allocation!');
    }

    const varTotalSize = (varSize * count).roundUp(16);

    // If we don't have enough space in the heap, try grow it.
    if (varTotalSize >= this.freeSize) {
      const delta = (((varTotalSize - this.freeSize) / 65536) | 0) + 1;
      try {
        this.memory.grow(delta);
        this.freeSize += delta * 65536;
        //Scheduler.setMemoryPool('appMemory', this);
      } catch (err) {
        console.error(`Unable to increase heap [${this.name}] size by [${delta}] pages!`);
      }
    }

    this.variables[name] = <Variable>{
      ptr: this.basePtr,
      size: varSize * count,
      maxSize: varSize * count,
      elementSize: varSize,
    };
    this.basePtr += varTotalSize;
    this.usedSize += varTotalSize;
    this.freeSize -= varTotalSize;

    return this.variables[name];
  }

  addVariableOverlay(name: string, varType: VARTYPE, count: number, ptr: number): Variable {
    if (count < 1) {
      throw new Error(`Cannot allocated memory for 0 instances of ${varType}`);
    }

    // Determine number of bytes per varType.
    let varSize = 0;
    switch (varType) {
      case VARTYPE.INT8:
      case VARTYPE.UINT8:
        varSize = 1;
        break;
      case VARTYPE.UINT16:
      case VARTYPE.INT16:
        varSize = 2;
        break;
      case VARTYPE.UINT32:
      case VARTYPE.INT32:
      case VARTYPE.FLOAT:
        varSize = 4;
        break;
    }
    if (varSize === 0) {
      throw new Error('Unknown size for memory allocation!');
    }

    this.variables[name] = <Variable>{
      ptr: ptr,
      size: varSize * count,
      maxSize: varSize * count,
      elementSize: varSize,
    };

    return this.variables[name];
  }

  removeVariable(name: string): void {
    const varSize = this.variables[name].size;
    delete this.variables[name];
    this.freeSize += varSize;
    this.usedSize -= varSize;
    this.basePtr -= varSize;
  }

  // This can only be used after a variable has been created and to set it to a lower size.
  // The original max size memory is reserved still.
  changeVariableSize(name: string, newSize: number): void {
    const attemptSize = (newSize * this.variables[name].elementSize).roundUp(16);
    if (attemptSize <= this.variables[name].maxSize) {
      this.variables[name].size = attemptSize;
    } else {
      console.error(`Attempt to resize variable ${name} to greater than max!`);
    }
  }

  getVariable(name: string): Variable {
    return this.variables[name];
  }
}

export class MemoryManager {
  static CreatePool(initialSize: number, name: string) {
    return new MemoryPool(initialSize, name);
  }
}
