import { Camera } from '../enjin/camera';
import { Light } from '../enjin/light';
import { MemoryPool } from '../enjin/mallocator';
import { Material } from '../enjin/material';
import { VectorMath } from '../enjin/math3d';
import { Mesh } from '../enjin/mesh';
import { RenderTarget } from '../enjin/renderTarget';
import { Texture } from '../enjin/texture';
import { mat4, vec4 } from '../enjin/types';

export class SkyBox {
  public textureLeft!: Texture;
  public textureRight!: Texture;
  public textureFront!: Texture;
  public textureBack!: Texture;
  public textureTop!: Texture;
  public textureBottom!: Texture;
  public mesh!: Mesh;
  public materialLeft: Material = new Material('skydomeMaterialLeft');
  public materialRight: Material = new Material('skydomeMaterialLeft');
  public materialFront: Material = new Material('skydomeMaterialLeft');
  public materialBack: Material = new Material('skydomeMaterialLeft');
  public materialTop: Material = new Material('skydomeMaterialLeft');
  public materialBottom: Material = new Material('skydomeMaterialLeft');
  public noMaterial: Material = new Material('no-material');

  public static async create(rt: RenderTarget): Promise<SkyBox> {
    const box = new SkyBox();

    box.textureLeft = await Texture.fromURL('skyTextureLeft', 'textures/sky2_left_512.png', false);
    box.textureRight = await Texture.fromURL('skyTextureRight', 'textures/sky2_right_512.png', false);
    box.textureFront = await Texture.fromURL('skyTextureFront', 'textures/sky2_back_512.png', false);
    box.textureBack = await Texture.fromURL('skyTextureBack', 'textures/sky2_front_512.png', false);
    box.textureTop = await Texture.fromURL('skyTextureTop', 'textures/sky2_top_512.png', false);
    box.textureBottom = await Texture.fromURL('skyTextureBottom', 'textures/sky2_bottom_512.png', false);
    rt.bindTexture(box.textureLeft!);
    rt.bindTexture(box.textureRight!);
    rt.bindTexture(box.textureFront!);
    rt.bindTexture(box.textureBack!);
    rt.bindTexture(box.textureTop!);
    rt.bindTexture(box.textureBottom!);
    box.materialFront.diffuseColor.set(1, 1, 1, 1);
    box.materialBack.diffuseColor.set(1, 1, 1, 1);
    box.materialLeft.diffuseColor.set(1, 1, 1, 1);
    box.materialRight.diffuseColor.set(1, 1, 1, 1);
    box.materialTop.diffuseColor.set(1, 1, 1, 1);
    box.materialBottom.diffuseColor.set(1, 1, 1, 1);
    box.noMaterial.diffuseColor.set(1, 1, 1, 1);
    box.materialLeft.setTexture(box.textureLeft);
    box.materialRight.setTexture(box.textureRight);
    box.materialFront.setTexture(box.textureFront);
    box.materialBack.setTexture(box.textureBack);
    box.materialTop.setTexture(box.textureTop);
    box.materialBottom.setTexture(box.textureBottom);

    box.mesh = await Mesh.loadOBJ('models/skybox.obj', box.noMaterial, 'skybox-mesh');

    const up = new vec4(0, -1, 0, 0);
    const down = new vec4(0, 1, 0, 0);
    const left = new vec4(-1, 0, 0, 0);
    const right = new vec4(1, 0, 0, 0);
    const front = new vec4(0, 0, -1, 0);
    const back = new vec4(0, 0, 1, 0);

    for (let i = 0; i < box.mesh.geometryBuffer.faceCount; i++) {
      if (VectorMath.dotProduct(box.mesh.geometryBuffer.faces[i].normal, up) === 1) {
        box.mesh.geometryBuffer.faces[i].material = box.materialBottom;
      } else if (VectorMath.dotProduct(box.mesh.geometryBuffer.faces[i].normal, left) === 1) {
        box.mesh.geometryBuffer.faces[i].material = box.materialLeft;
      } else if (VectorMath.dotProduct(box.mesh.geometryBuffer.faces[i].normal, right) === 1) {
        box.mesh.geometryBuffer.faces[i].material = box.materialRight;
      } else if (VectorMath.dotProduct(box.mesh.geometryBuffer.faces[i].normal, down) === 1) {
        box.mesh.geometryBuffer.faces[i].material = box.materialTop;
      } else if (VectorMath.dotProduct(box.mesh.geometryBuffer.faces[i].normal, front) === 1) {
        box.mesh.geometryBuffer.faces[i].material = box.materialFront;
      } else if (VectorMath.dotProduct(box.mesh.geometryBuffer.faces[i].normal, back) === 1) {
        box.mesh.geometryBuffer.faces[i].material = box.materialBack;
      }
    }
    box.mesh.geometryBuffer.useHSR = true;
    box.mesh.geometryBuffer.hasLight = false;
    box.mesh.centerAndScale(4000.7);
    box.mesh.setOrientation(Math.PI / 2, 0, 0);
    box.mesh.setPosition(0.0, 0.0, 0.0);

    box.mesh.geometryBuffer.buildNativeBuffers();
    return box;
  }

  public update(
    cam: Camera,
    projMatrix: mat4,
    lights: Light[],
    ambient: vec4,
    width: number,
    height: number,
    camera: Camera
  ): void {
    this.mesh.setAllFacesVisible();
    this.mesh.transform(cam.camMatrix, projMatrix, lights, ambient, width, height, camera, true);
  }

  public render(r: RenderTarget): void {
    this.mesh.render(r);
  }
}
