import { vec4 } from './types';
import { VectorMath } from './math3d';

// Standard plane equation is: ax + by + cz + d = 0
// (a,b,c,0) is the normal of the plane, d is distance from origin.
// see reference -> plane extraction and also
// https://fgiesen.wordpress.com/2012/08/31/frustum-planes-from-the-projection-matrix/

export class plane {
  public normal: vec4;
  public point!: vec4;
  public normal2!: vec4;

  constructor(normal: vec4, public distance: number) {
    this.normal = new vec4(normal.x, normal.y, normal.z, distance);
    this.normalize();
    this.point = new vec4(0, 0, 0, 1);
    this.normal2 = new vec4(0, 0, 0, 0);
  }

  public setPoint(pt: vec4): void {
    this.point.copyFrom(pt);
  }

  public setNormal(n: vec4): void {
    this.normal2.copyFrom(n);
  }

  public normalize(): void {
    const mag =
      1.0 / Math.sqrt(this.normal.x * this.normal.x + this.normal.y * this.normal.y + this.normal.z * this.normal.z);
    this.normal.x *= mag;
    this.normal.y *= mag;
    this.normal.z *= mag;
    this.normal.w *= mag;
  }

  public signedDistanceToPoint(pt: vec4): number {
    return VectorMath.dotProduct(this.normal, pt) + this.normal.w;
  }

  public signedDistanceToPoint2(pt: vec4): number {
    return VectorMath.dotProduct(this.normal2, vec4.sub(pt, this.point));
  }
}
